import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { PDFExport } from "@progress/kendo-react-pdf";
// import CloseIcon from "@mui/icons-material/Close";
import PopUp, { CommonPopup } from "../../commonComponents/popUp/PopUp";
import { BiRupee } from "react-icons/bi";
// sendEmailPopup
import emailPopupImg from "../../../images/popups/sendEmailPopup.svg";
import warningPopupImg from "../../../images/popups/warning.svg";
import ehsLogo from "../../../images/icons/logoEHS.jpeg";
import CloseIcon from "@mui/icons-material/Close";

import "./pdf.css";
import { useEffect } from "react";
const PdfMaker = ({ sendData, quoteNumber, setToggle }) => {
  console.log(sendData, "sendData");
  let address = useSelector((state) => state.user.userDetail);
  console.log(
    "🚀 ~ file: AddressItem.jsx ~ line 27 ~ AddressItem ~ data",
    address
  );
  const cartInState = useSelector((state) => state.cart?.cart);
  // const [cartData, setCartData] = useState([]);
  const [cartTotal, setCartTotal] = useState();
  const [cartTotalDiscount, setCartTotalDiscount] = useState();
  const [cartTotalAfterDiscount, setcartTotalAfterDiscount] = useState();
  console.log(cartInState, "state=>>>>>>");
  // const [cart, setCart] = useState(sendData.cart);
  const [editPopup, setEditPopup] = useState(false);
  const [incompletePopup, setIncompletePopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const pdfExportComponent = useRef(null);
  // const [emailSend, setEmailSend] = useState(false);
  const [editBtn, setEditBtn] = useState(true);
  const [clientDetail, setClientDetails] = useState({});
  const closePopup = () => {
    setToggle(false);
    window.reload();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // const sendEmailhandler = () => {
  //   if (
  //     !clientDetail.clientName ||
  //     !clientDetail.companyName ||
  //     !clientDetail.companyAdd ||
  //     !clientDetail.companyContact
  //   ) {
  //     setIncompletePopup(true);
  //   } else {
  //     setSuccessPopup(true);
  //     setEmailSend(true);
  //   }
  // };
  const closeIncompletePopup = () => {
    setIncompletePopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    let sum = 0;
    for (let i = 0; i < cartInState.length; i++) {
      sum += Number(cartInState[i].total);
      setCartTotal(sum);
      // let discount = Math.floor((sum * 5) / 100);
      let discount = Math.floor((sum * 5) / 100);
      setCartTotalDiscount(discount);
      let finalTotal = sum - discount;
      setcartTotalAfterDiscount(finalTotal);
    }
  }, [cartInState]);
  return (
    <>
      <div className="pdf-popup">
        <div className="pdf-popupbox">
          <div className="pdf-div">
            <PDFExport
              paperSize="A3"
              margin="1cm"
              className="pdf-padding"
              ref={pdfExportComponent}
              fileName="Quotation"
            >
              <div className="pdf">
                <div className="pdf-top">
                  <div className="logo-div">
                    <img
                      src={ehsLogo}
                      className="logo w-[8rem] h-[3rem]"
                      alt="Logo"
                    />
                    <h5 className="heading">Quotation</h5>
                  </div>
                  <div className="detail-div">
                    <p className="comp-name">EHS Prints</p>
                    <p>+91 6265246491 </p>
                    <p>hello@ehsprints.com</p>
                    <p>
                      45, Old Agarwal Nagar, Indore,
                      <br />
                      Madhya Pradesh-452001
                    </p>
                  </div>
                  <div className="pdf-line"></div>
                </div>
                <div className="pdf-mid">
                  <div className="mid-left">
                    <div className="user-detail">
                      <p> Quotation to</p>
                      <p>
                        {clientDetail.clientName
                          ? clientDetail.clientName
                          : address.address
                          ? address.name
                          : "Client Name"}
                      </p>
                      <p>
                        {clientDetail.companyName
                          ? clientDetail.companyName
                          : "Add Company Name"}
                      </p>
                      <p>
                        {clientDetail.companyAdd
                          ? clientDetail.companyAdd
                          : "Add Company Address"}
                      </p>
                      <p>
                        {clientDetail.companyContact
                          ? clientDetail.companyContact
                          : address.address[0]?.phone
                          ? address.address[0]?.phone
                          : address.phonenumber
                          ? address.phonenumber
                          : "Contact Details"}
                      </p>
                    </div>
                    {editBtn && (
                      <button
                        className="edit-btn"
                        onClick={() => setEditPopup(true)}
                      >
                        {" "}
                        Edit
                      </button>
                    )}
                  </div>
                  <div className="mid-right">
                    <div className="flex items-center mb-[10px]">
                      <p className="name ">Quotation number:</p>
                      <p className="mt-0">0{quoteNumber + 1}</p>
                    </div>
                    <div className="flex items-center">
                      <p className="name ">Date:</p>
                      <p className="mt-0">
                        {sendData.created_at?.slice(8, 10)}
                        {sendData.created_at?.slice(4, 8)}
                        {sendData.created_at?.slice(0, 4)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pdf-line"></div>
                <div className="pdf-item">
                  <table>
                    <thead>
                      <tr className="item-head">
                        <th className="item1">
                          <p>Item Description</p>
                        </th>
                        <th className="item2">
                          <p>Quantity</p>
                        </th>
                        <th className="item2">
                          <p>Unit Price</p>
                        </th>
                        <th className="item2">
                          <p>Amount</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/*  dynamic data  */}
                      {cartInState.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="item1">
                              <div>
                                <p className="mb-1">
                                  Name:{" "}
                                  {item.poster_details?.name?.split("|")[2]}
                                </p>
                                <p className="mb-1">
                                  Material:{" "}
                                  {item.materialDimension?.material_title}
                                </p>
                                <p className="mt-0">
                                  Dimension:{" "}
                                  {item.materialDimension?.dimension_title}
                                </p>
                              </div>
                            </td>
                            <td className="item2">
                              <p>{item.quantity}</p>
                            </td>
                            <td className="item2">
                              <p>{item.total}</p>
                            </td>
                            <td className="item2">
                              <p>
                                <BiRupee />
                                {item.total -
                                  Math.floor((item.total * 5) / 100)}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="total">
                  <div className="t-right">
                    <div>
                      <p>Subtotal</p>
                      <p>
                        {" "}
                        <BiRupee />
                        {cartTotal}
                      </p>
                    </div>
                    <div className="discount">
                      <p>Discount(5%)</p>
                      <p>
                        {" "}
                        -<BiRupee />
                        {cartTotalDiscount}
                      </p>
                    </div>
                    {/* <div>
                      <p>Tax:Vat(15%)</p>
                      <p>
                        {" "}
                        <BiRupee />
                        99,999,999.00
                      </p>
                    </div> */}
                    <div className="t-total">
                      <p>Total Due</p>
                      <p>
                        {" "}
                        <BiRupee />
                        {cartTotalAfterDiscount}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="terms">
                  <h6 className=" font-['Roboto']">
                    Terms & Conditions / Notes
                  </h6>
                  <div className=" text-xs font-normal text-[#000000]">
                    <p>
                      All the prices are inclusive of GST. This quotation is
                      valid upto 30 days. Prices for our products are subject to
                      change without notice. We reserve the right at any time to
                      modify or discontinue the Service (or any part or content
                      thereof) without notice at any time. We shall not be
                      liable to you or to any third-party for any modification,
                      price change, suspension or discontinuance of the Service
                    </p>
                  </div>
                </div>
              </div>
            </PDFExport>
            <div className="example-config">
              <button className="pdf-btn" onClick={closePopup}>
                Back
              </button>

              <button
                className="pdf-btn"
                onClick={() => {
                  if (
                    !clientDetail.clientName ||
                    !clientDetail.companyName ||
                    !clientDetail.companyAdd ||
                    !clientDetail.companyContact
                  ) {
                    setIncompletePopup(true);
                  } else {
                    setSuccessPopup(true);
                    setEditBtn(false);
                    if (pdfExportComponent.current) {
                      pdfExportComponent.current.save();
                    }
                    // setEmailSend(true);
                  }
                }}
              >
                Download
              </button>
              {/* <button className="pdf-btn" onClick={sendEmailhandler}>
                Send to Email
              </button> */}
            </div>
          </div>
        </div>
        <EditPopup
          setClientDetails={setClientDetails}
          setEditPopup={setEditPopup}
          editPopup={editPopup}
        />
      </div>
      <CommonPopup
        heading="Congrats!"
        desc="Your quote has been successfully downloaded."
        image={emailPopupImg}
        setMyPopup={setSuccessPopup}
        popup={successPopup}
        secondBtn={true}
      />
      <PopUp popup={incompletePopup}>
        <div className="materialPopup">
          <img
            src={warningPopupImg}
            alt="select configuration"
            style={{
              width: "75px",
              height: "75px",
            }}
          />
          <div
            className=""
            style={{
              color: "#898989",
              fontSize: "19px",
              textAlign: "center",
              margin: "15px",
            }}
          >
            Please enter company’s detail before proceeding further.
          </div>
          <CloseIcon className="closePopup" onClick={closeIncompletePopup} />
        </div>
      </PopUp>
    </>
  );
};
export default PdfMaker;

const EditPopup = ({ editPopup, setEditPopup, setClientDetails }) => {
  const [userData, setUserData] = useState({
    clientName: "",
    companyName: "",
    companyAdd: "",
    companyContact: "",
  });
  const handleChnage = (e) => {
    const { name, value } = e.target;
    setUserData((pVal) => {
      return { ...pVal, [name]: value };
    });
  };
  const formSubmit = () => {
    setEditPopup(false);
    setClientDetails(userData);
    console.log(userData);
  };
  return (
    <>
      {editPopup !== true ? null : (
        <div className="edit-popup">
          <div className="edit-popupBox">
            <div className="edit-box">
              <p className="heading text-center mb-2">Edit Details</p>
              <div className="form items-center">
                <div>
                  <p>Client Name</p>
                  <input
                    autoComplete="off"
                    type="text"
                    name="clientName"
                    onChange={handleChnage}
                    value={userData.clientName}
                  />
                </div>
                <div>
                  <p>Company Name</p>
                  <input
                    type="text"
                    autoComplete="off"
                    name="companyName"
                    onChange={handleChnage}
                    value={userData.companyName}
                  />
                </div>
                <div>
                  <p>Company Post Address</p>
                  {/* <input type="text" name="companyName" autoComplete="off"  id="address"/> */}
                  <textarea
                    name="companyAdd"
                    autoComplete="off"
                    cols="30"
                    rows="4"
                    onChange={handleChnage}
                    value={userData.companyAdd}
                  ></textarea>
                </div>
                <div>
                  <p>Contact Number</p>
                  <input
                    type="number"
                    name="companyContact"
                    autoComplete="off"
                    onChange={handleChnage}
                    value={userData.companyContact}
                  />
                </div>
                <button
                  className="pdf-btn flex justify-center"
                  onClick={formSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
