// import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { getAllforms } from '../../redux/actions/designerFormAction'

// import Masonry from '@mui/lab/Masonry';
// import Typography from '@mui/joy/Typography';
// import AddIcon from '@mui/icons-material/Add';
// import { useNavigate } from 'react-router-dom';
// import InstructionsPopUp from '../../components/doityourself/src/components/DoItYourSelf/Canvas/header/tools/popups/InstructionsPopUp';
// import { Avatar, Divider } from '@mui/joy';
// import CloseRounded from '@mui/icons-material/CloseRounded';
// import ShowImgmodel from './ShowImgmodel';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';
// import { getSingle_User, getUser } from '../../redux/actions/userAction';
// import Form from './Form';
// import FilterForm from './FilterForm';
// import DesignerForm from './DesignerForm';
// import DesignerFilterForm from './DesignerFilterForm';
// import Tooltip from '@mui/material/Tooltip'
// import { ToastContainer } from 'react-toastify';
// import axios from 'axios';
// import { API } from '../../backend';
// import FormSkaliton from './FormSkaliton';
// import TeamForm from './TeamForm';
// import { Puff } from 'react-loader-spinner';


// export default function MyForms() {
//   const businessId = JSON.parse(localStorage.getItem('businessId'))
//   const navigate = useNavigate()
//   const [teamMail, setTeamMail] = useState("All")
//   const [changeShowProject, setChangeShowProject] = useState('My Projects')
//   const [isShowTeamProjects, setIsShowTeamProjects] = useState(false)
//   const whoseProject = [
//     'My Projects',
//     'Team Projects'
//   ]
//   const dispatch = useDispatch()
//   const [showArchived, setShowArchived] = useState(false)
//   const [adminName, setAdminName] = useState(null)
//   const [selectedAdmin, setSelectedAdmin] = useState({
//     id: '',
//     name: ''
//   })
//   const [workState, setWorkState] = useState('All')


//   const jobState = [
//     'All',
//     'In progress',
//     'Await my actions'
//   ]
//   const [designerName, setDesignerName] = useState(null)
//   const [selectedDesigner, setSelectedDesigner] = useState({
//     id: '',
//     name: ''
//   })
//   const handleChange = (e) => {
//     setSelectedAdmin({ ...selectedAdmin, id: e.target.value })
//   };

//   const handleChangeDesigner = (e) => {
//     setSelectedDesigner({ ...selectedDesigner, id: e.target.value })
//   };
//   const [urlArray, setUrlArray] = useState([])
//   console.log('url array is', urlArray)
//   const [imgArray, setImgArray] = useState([
//     {
//       width: null,
//       height: null
//     }
//   ])
//   const [open, setOpen] = useState(false);
//   const [imgSrc, setImgSrc] = useState(null)
//   const [teamPro, setTeamPro] = useState("My Projects")
//   const singleUser = useSelector((state) => state.user.singleUser)
//   const userDetails = useSelector((state) => state.user.userDetail);
//   const getState = useSelector((state) => state);

   

//   useEffect(() => {

//     if (userDetails?._id) {
//       dispatch(getSingle_User(userDetails?._id))
//     }

//   }, [])

//   //details from business id only
//   const [currentDetails, setCurrentDetails] = useState(null)

//   useEffect(() => {
//     const getSingleUser = async () => {

//       if (businessId) {
//         await axios.get(`${API}auth/getsingleUser/${businessId}`)
//           .then(({ data }) => setCurrentDetails(data))
//       }
//     }

//     getSingleUser()
//   }, [])

//   const [instructionOpen, setInstructionPopup] = useState(false)
//   const [inputSize, setInputSize] = useState(
//     {
//       wight: '',
//       height: ''
//     }
//   )

//   const [size, setSize] = useState({
//     wight: '',
//     height: ''
//   })
//   const [formData, setFormData] = useState(
//     {
//       title: '',
//       templateId: '',
//       multiPage: false,
//       purpose: '',
//       canvasSize: {
//         width: '',
//         height: ''
//       },
//       // graphicsSize:'',
//       placestoDecorate: {
//         where: '',
//         color: ''
//       },
//       files: [],
//       position: '',
//       projectDesc: '',
//       demoImgUrl: '',
//       isMulti: false,
//       multiImgUrl: null,
//       multiimgSize: [],
//       designerId: '',
//       myId: `${userDetails?._id}`
//     }
//   )

//   const fs = useSelector(state => state.form.form)
//   const [forms, setForms] = useState([])
//   // useEffect(() => {
//   //   dispatch(getAllforms())
//   // }, [])
//   const [loading, setLoading] = useState(false)
//   const [teamloading, setTeamLoading] = useState(false)
//   //getting diff ops as per selected team members

//   const getForms = async () => {
//     setTeamLoading(true)
//     if (teamMail === "All") {
//       await axios.get(`${API}form/getforms`)
//         .then(({ data }) => {
//           setTeamLoading(false)
//           setForms(data)
//         })
//     }
//     else {
//       setTeamLoading(true)
//       await axios.get(`${API}form/getformsByteamMail/${teamMail}`)
//         .then(({ data }) => {
//           setTeamLoading(false)
//           setForms(data)
//         })
//     }

//   }

//   useEffect(() => {
//     getForms()
//   }, [teamMail])

//   //for admin/client
//   const myForms = fs?.filter((f) => f?.myId?._id === userDetails?._id)

//   //showing team projects to team member for mombers
//   const teamForms = fs?.filter((f) => f?.myId?._id === businessId ||
//     f?.team?.find((t) => t === userDetails?.emailid)
//   )

//   console.log('team forms are', teamForms)

//   // 


//   //for designer
//   const designerForms = fs?.filter((f) => f?.designerId?._id === userDetails?._id)


//   //finding (for designer) those forms where selectedadminid is present
//   const filteredForms = designerForms?.filter((f) => f?.myId?._id === selectedAdmin?.id)

//   //finding (for admin) those forms where selecteddesigners is present
//   const filteredFormsForAdmin = myForms?.filter((f) => f?.designerId?._id === selectedDesigner?.id)

//   //when on designers are selected
//   const getMultiimgData = (id) => {
//     setImgArray(myForms[id]?.multiimgSize)
//     setUrlArray(myForms[id]?.multiImgUrl)
//   }

//   //when designers are selected
//   const getFilterMultiimgData = (id) => {
//     setImgArray(filteredFormsForAdmin[id]?.multiimgSize)
//     setUrlArray(filteredFormsForAdmin[id]?.multiImgUrl)


//   }
//   useEffect(() => {
//     setTimeout(() => {
//       setLoading(false)
//     }, 2500);
//   })

//   useEffect(() => {
//     const fetchData = async () => {
//       if ((localStorage.getItem("myuser")) && !userDetails) {
//         // alert("getUser dispatched from in app js")
//         await dispatch(getUser());
//       }
//     };

//     fetchData();
//   }, [dispatch, userDetails]);



//   const [allUsers, setAllUsers] = React.useState([]);
//   //getting all users
//   const getUsers = async () => {
//     axios
//       .get(`https://dev.ehsprints.com:8000/auth/getUsersAll`,

//       )
//       .then(({ data }) => {
//         setAllUsers(data);
//       });
//   };


//   return (
//     <>
//       {
//         loading ?
//           <div>

//             <FormSkaliton />
//           </div> :
//           <div className=' py-2 px-4'>
//             {/* <ToastContainer/> */}
//             {/* to show img model to designer only */}
//             <ShowImgmodel
//               open={open} setOpen={setOpen}
//               imgSrc={imgSrc}
//             />

//             {/* for client/admin only */}
//             <InstructionsPopUp
//               urlArray={urlArray} setUrlArray={setUrlArray}
//               imgArray={imgArray} setImgArray={setImgArray}
//               size={size}
//               setSize={setSize}
//               inputSize={inputSize}
//               setInputSize={setInputSize}
//               formData={formData} setFormData={setFormData} open={instructionOpen} setOpen={setInstructionPopup}
//               allUsers={allUsers}
//             />

//             {/* to show the forms which ar created by client and users */}

//             {
//               userDetails?.Role !== 'designer' &&
//               <div className=' flex flex-col gap-2 mt-1'>

//                 {
//                   (userDetails?.Role === "corporate_admin" || businessId) &&
//                   <div className=' flex justify-end flex-wrap gap-2 mt-2 my-auto lg:ml-[360px] pl-2.5 '>

//                     {/* for admin to show the team projects */}
//                     {userDetails?.Role === "corporate_admin" && !businessId &&
//                       <div className=' flex gap-2 items-center px-2 py-1 bg-zinc-700 rounded-3xl'>
//                         {whoseProject?.map((item, id) => (
//                           <button
//                             onClick={() => {
//                               // setSelectedDesigner({
//                               //   id:'',
//                               //   name:''
//                               // })
//                               // setWorkState('All')
//                               if (item === "My Projects") {
//                                 setIsShowTeamProjects(false)
//                               }
//                               else {
//                                 setIsShowTeamProjects(true)
//                               }
//                               setTeamPro(item)
//                               // setDesignerName(null)
//                               // setShowArchived(false)
//                               // setChangeShowProject(item)

//                             }}
//                             className={item === teamPro ? ' rounded-2xl bg-slate-50 text-black px-3 py-1 m-1 ' : ' text-white px-2 py-1 m-1 '}>
//                             {item}
//                           </button>
//                         ))}
//                       </div>
//                     }
//                     {/* <p className=' font-semibold my-auto text-2xl'>{ (designerName && !showArchived) ?`${"Instructions for "+designerName}`: (!showArchived && !designerName) ?"All Instructions":"Previous Instructions"}</p> */}
//                     <div className=' z-[40] flex gap-2 py-1 px-2 text-white items-center rounded-3xl my-auto bg-zinc-700 '>
//                       {jobState?.map((item, id) => (
//                         <button
//                           disabled={(item === 'Await my actions' && userDetails?.Role !== "corporate_admin" && changeShowProject !== 'My Projects') && true}
//                           onClick={() => {
//                             setSelectedDesigner({
//                               id: '',
//                               name: ''
//                             })
//                             setDesignerName(null)
//                             setShowArchived(false)
//                             setTeamPro("My Projects")
//                             setIsShowTeamProjects(false)
//                             setWorkState(item)
//                           }}
//                           className={item === workState ? ' rounded-2xl bg-slate-50 text-black px-3 py-1 m-1 ' : ' text-white px-2 py-1 m-1 '}>
//                           {item}
//                         </button>
//                       ))}
//                     </div>

//                     <div
//                       className=' flex gap-2 my-auto'>
//                       {
//                         businessId &&
//                         <div className=' z-[40] flex gap-2 py-1 px-2 text-white items-center rounded-3xl my-auto bg-zinc-700 '>
//                           {whoseProject?.map((item, id) => (
//                             <button
//                               onClick={() => {
//                                 setSelectedDesigner({
//                                   id: '',
//                                   name: ''
//                                 })
//                                 setWorkState('All')
//                                 setDesignerName(null)
//                                 setShowArchived(false)
//                                 setChangeShowProject(item)
//                               }}
//                               className={item === changeShowProject ? ' rounded-2xl bg-slate-50 text-black px-3 py-1 m-1 ' : ' text-white px-2 py-1 m-1 '}>
//                               {item}
//                             </button>
//                           ))}
//                         </div>}
//                       <Tooltip
//                         title={
//                           <React.Fragment>
//                             <Typography color="inherit">See Instructions</Typography>
//                             <Typography sx={{ fontSize: '15px' }} color="inherit">of your approved designs</Typography>
//                           </React.Fragment>

//                         }
//                       >


//                         <button
//                           style={{
//                             zIndex: 1
//                           }}
//                           className=' bg-zinc-800 md:text-base text-base text-white border-[1px] border-zinc-500 px-4 py-2.5 rounded-md hover:bg-indigo-800 transition-all duration-200'
//                           onClick={() => {
//                             setDesignerName(null)
//                             setSelectedDesigner({
//                               id: '',
//                               name: ''
//                             })
//                             setShowArchived(true)
//                             setIsShowTeamProjects(false)
//                           }}
//                         >Archived</button>

//                       </Tooltip>
//                     </div>
//                   </div>}

//                 {
//                   (userDetails?.Role === "corporate_admin" || businessId) &&
//                   <>


//                     {/* <p className=' font-semibold my-auto ml-2.5 text-2xl'>{ (designerName && !showArchived) ?`${"Instructions for "+designerName}`: (!showArchived && !designerName && workState ==='All') ?"All Projects":(workState ==='In progress' && !showArchived)?"Ongoing Projects":(workState ==='Await my actions' && !showArchived)?"Approve pending Projects":"Previous Projucts"}</p> */}

//                     {/* {selectedDesigner?.id && filteredFormsForAdmin?.length === 0 &&
//   <div className=' h-[60vh] flex justify-center items-center w-full'>
//    <p className=' font-bold text-2xl text-black'>No instructions yet</p>
//   </div>
//   } */}
//                     {/* {!selectedDesigner?.id && myForms?.length === 0 &&
//   <div className=' h-[60vh] flex justify-center items-center w-full'>
//    <p className=' font-bold text-2xl text-black'>No Instructions are available</p>
//   </div>
//   } */}
//                   </>}


//                 {
//                   (changeShowProject === 'My Projects' && userDetails?.Role !== 'designer') ?
//                     <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
//                       sx={{
//                         ml: 0.5
//                       }}
//                       spacing={2}>

//                       {
//                         userDetails?.Role !== 'designer' &&
//                         <div
//                           className=' flex flex-col justify-center items-center'>
//                           <p className=' font-bold lg:text-4xl text-xl lg:ml-3 lg:mt-[35px] z-50 p-2 text-[#74cf29]'>Create something Amazing Today</p>
//                           {
//                             userDetails?.Role !== 'designer' &&
//                             <Tooltip followCursor title={
//                               <React.Fragment>
//                                 <Typography color="inherit">Create New Project</Typography>
//                               </React.Fragment>
//                             }>
//                               <div className=' flex gap-2 justify-between items-center h-[200px]'>
//                                 <p className=' xl:flex hidden font-bold mt-[-20px] text-xl w-[180px] '>{singleUser?.name}</p>
//                                 <Divider
//                                   className='xl:flex hidden'
//                                   sx={{
//                                     color: 'black',
//                                     background: 'black',
//                                     width: '3px',
//                                     mt: '-5px',
//                                     height: '170px'
//                                   }} orientation="vertical"></Divider>
//                                 <img
//                                   onClick={() => {
//                                        getUsers()
//                                     setFormData({
//                                       ...formData,
//                                       title: '',
//                                       id: '',
//                                       isMulti: false,
//                                       templateId: '',
//                                       myId: `${userDetails?._id}`,
//                                       designerId: '',
//                                       team: [],
//                                       purpose: '',
//                                       Material: '',
//                                       demoImgUrl: '',
//                                       projectDesc: '',
//                                       placestoDecorate: {
//                                         where: '',
//                                         color: '#fffff'
//                                       },
//                                       canvasSize: {
//                                         width: '',
//                                         height: ''
//                                       }

//                                     })
//                                     setUrlArray([])
//                                     setImgArray([])
//                                     setSelectedDesigner({
//                                       id: '',
//                                       name: ''
//                                     })
//                                     setDesignerName(null)
//                                     setInstructionPopup(true)

//                                   }
//                                   }

//                                   className=' border-[1px] mt-[-40px] max-h-[200px] w-[200px] hover:border-indigo-700 transition-all duration-200 cursor-pointer border-indigo-300 rounded-xl' src="https://ssl.gstatic.com/docs/templates/thumbnails/forms-blank-googlecolors.png" alt="" />
//                               </div>
//                             </Tooltip>}

//                         </div>}

//                       {
//                         myForms?.length === 0 && userDetails?.Role !== 'designer' &&
//                         [...Array(4)]?.map((item, idx) => (
//                           <div
//                             onClick={() => {
//                               setFormData({
//                                 ...formData,
//                                 title: '',
//                                 id: '',
//                                 isMulti: false,
//                                 templateId: '',
//                                 myId: `${userDetails?._id}`,
//                                 designerId: '',
//                                 purpose: '',
//                                 Material: '',
//                                 demoImgUrl: '',
//                                 projectDesc: '',
//                                 placestoDecorate: {
//                                   where: '',
//                                   color: '#fffff'
//                                 },
//                                 canvasSize: {
//                                   width: '',
//                                   height: ''
//                                 }

//                               })
//                               setUrlArray([])
//                               setImgArray([])
//                               setSelectedDesigner({
//                                 id: '',
//                                 name: ''
//                               })
//                               setDesignerName(null)
//                               setInstructionPopup(true)

//                             }
//                             }
//                             className=' bg-indigo-200 hover:translate-y-[-2px] cursor-pointer transition-all duration-200 text-xl font-bold rounded-xl w-full flex justify-center items-center h-[270px]'>
//                             Fill with your imagination
//                           </div>
//                         ))
//                       }

//                       {myForms?.map((f, idx) => (
//                         <Form
//                           isShowTeamProjects={isShowTeamProjects}
//                           changeShowProject={changeShowProject}
//                           workState={workState}
//                           setWorkState={setWorkState}
//                           showArchived={showArchived}
//                           myForms={myForms} f={f} idx={idx} userDetails={userDetails} getMultiimgData={getMultiimgData} formData={formData} setFormData={setFormData} setInstructionPopup={setInstructionPopup} />
//                       ))}

//                       {
//                         !businessId && singleUser?.Role === "corporate_admin" && isShowTeamProjects &&
//                         <>
//                           {
//                             forms?.length > 0 ?
//                               <>
//                                 {forms?.map((f, idx) => (
//                                   <TeamForm
//                                     setInstructionPopup={setInstructionPopup}
//                                     formData={formData}
//                                     setFormData={setFormData}
//                                     f={f} id={idx} singleUser={singleUser} />
//                                 ))}
//                               </>
//                               :
//                               <div className=' relative w-full flex justify-center items-center mx-auto'>
//                                 <div
//                                   // style={{
//                                   //   transform:'translate(-50%,-50%)'
//                                   // }}
//                                   className=' mt-16 flex items-center justify-center'>
//                                   <Puff
//                                     visible={true}
//                                     height="90"
//                                     width="90"
//                                     color="#14366e"
//                                     ariaLabel="puff-loading"
//                                     wrapperStyle={{}}
//                                     wrapperClass=""
//                                   />

//                                 </div>

//                               </div>
//                           }
//                         </>
//                       }


//                     </Masonry> :
//                     <Masonry columns={{ xs: 1, md: 2, lg: 4 }}
//                       sx={{
//                         ml: 0.5
//                       }}
//                       spacing={2}>

//                       {
//                         userDetails?.Role !== 'designer' &&
//                         teamForms?.map((f, idx) => (
//                           <Form
//                             isShowTeamProjects={isShowTeamProjects}
//                             changeShowProject={changeShowProject}
//                             workState={workState}
//                             setWorkState={setWorkState}
//                             showArchived={showArchived}
//                             myForms={myForms} f={f} idx={idx} userDetails={userDetails} getMultiimgData={getMultiimgData} formData={formData} setFormData={setFormData} setInstructionPopup={setInstructionPopup} />
//                         ))}

//                     </Masonry>
//                 }


//               </div>}



//             {/* to show the form of clients to the designer */}
//             {userDetails?.Role === 'designer' &&
//               <div className=' flex flex-col gap-2'>
//                 <div className=' flex justify-between my-auto pl-2.5 '>
//                   <p className=' font-semibold my-auto text-2xl'>{(adminName && !showArchived) ? `${"Instructions from " + adminName}` : (!showArchived && !adminName) ? "All Instructions" : "Previous Instructions"}</p>

//                   <div className=' flex gap-2 my-auto'>
//                     <Tooltip title={
//                       <React.Fragment>
//                         <Typography color="inherit">See Instructions</Typography>
//                         <Typography sx={{ fontSize: '15px' }} color="inherit">of approved designs</Typography>
//                       </React.Fragment>

//                     }>
//                       <button
//                         className=' bg-indigo-100 border-[1px] border-zinc-500 px-4 rounded-[30px] hover:bg-indigo-200 transition-all duration-200'
//                         onClick={() => {
//                           setAdminName(null)
//                           setSelectedAdmin({
//                             id: '',
//                             name: ''
//                           })
//                           setShowArchived(true)
//                         }}
//                       >Archived</button>
//                     </Tooltip>

//                     <FormControl sx={{ minWidth: 200, mr: 1 }} className=" min-w-[300px]">

//                       <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
//                       <Select
//                         onMouseEnter={() => dispatch(getSingle_User(userDetails?._id))}
//                         endAdornment={selectedAdmin?.id && <CloseRounded
//                           onClick={() => {

//                             setSelectedAdmin({
//                               id: '',
//                               name: ''
//                             })
//                             setAdminName(null)
//                           }}
//                           className=' mr-4 cursor-pointer text-rose-500' />}
//                         sx={{
//                           height: '55px',
//                           width: '250px',
//                         }}
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={selectedAdmin?.id}
//                         label="Select Admin"
//                         onChange={handleChange}
//                       >
//                         {singleUser?.adminIds?.map((admin) => (
//                           <MenuItem
//                             onClick={() => {
//                               setShowArchived(false)
//                               setAdminName(admin?.name)
//                               setSelectedAdmin({
//                                 id: admin?._id,
//                                 name: admin?.name
//                               })
//                             }
//                             }
//                             value={admin?._id}>
//                             <div className=" flex gap-3 my-auto">
//                               <Avatar src={admin?.name} alt={admin?.name} />
//                               <p className=" my-auto">
//                                 {admin?.name}
//                               </p>

//                             </div>
//                           </MenuItem>

//                         ))}

//                       </Select>
//                     </FormControl>
//                   </div>
//                 </div>
//                 {selectedAdmin?.id && filteredForms?.length === 0 &&
//                   <div className=' h-[60vh] flex justify-center items-center w-full'>
//                     <p className=' font-bold text-2xl text-black'>No Instructions are available from this client</p>
//                   </div>
//                 }
//                 {!selectedAdmin?.id && designerForms?.length === 0 &&
//                   <div className=' h-[60vh] flex justify-center items-center w-full'>
//                     <p className=' font-bold text-2xl text-black'>No Instructions are available from any client</p>
//                   </div>
//                 }
//                 {(!selectedAdmin?._id && !adminName) ?

//                   <Masonry columns={{ xs: 1, md: 2, lg: 4 }}
//                     sx={{
//                       ml: 0.5
//                     }}
//                     spacing={2}>
//                     {designerForms?.map((f, idx) => (
//                       <DesignerForm showArchived={showArchived} setOpen={setOpen} imgSrc={imgSrc} setImgSrc={setImgSrc} f={f} userDetails={userDetails} idx={idx} formData={formData} setFormData={setFormData} setInstructionPopup={setInstructionPopup} />
//                     ))}
//                   </Masonry> :
//                   <Masonry columns={{ xs: 1, md: 2, lg: 4 }}
//                     sx={{
//                       ml: 0.5
//                     }}
//                     spacing={2}>
//                     {filteredForms?.map((f, idx) => (
//                       <DesignerFilterForm setOpen={setOpen} imgSrc={imgSrc} setImgSrc={setImgSrc} f={f} userDetails={userDetails} idx={idx} formData={formData} setFormData={setFormData} setInstructionPopup={setInstructionPopup} />
//                     ))}
//                   </Masonry>
//                 }
//               </div>}


//           </div>}
//     </>
//   )
// }
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllforms } from '../../redux/actions/designerFormAction'

import Masonry from '@mui/lab/Masonry';
import Typography from '@mui/joy/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import InstructionsPopUp from '../../components/doityourself/src/components/DoItYourSelf/Canvas/header/tools/popups/InstructionsPopUp';
import { Avatar, Divider } from '@mui/joy';
import CloseRounded from '@mui/icons-material/CloseRounded';
import ShowImgmodel from './ShowImgmodel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { getSingle_User, getUser } from '../../redux/actions/userAction';
import Form from './Form';
import FilterForm from './FilterForm';
import DesignerForm from './DesignerForm';
import DesignerFilterForm from './DesignerFilterForm';
import Tooltip from '@mui/material/Tooltip'
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { API } from '../../backend';
import FormSkaliton from './FormSkaliton';
import TeamForm from './TeamForm';
import { Puff } from 'react-loader-spinner';


export default function MyForms() {
  const businessId = JSON.parse(localStorage.getItem('businessId'))
  const navigate = useNavigate()
  const [teamMail, setTeamMail] = useState("All")
  const [changeShowProject, setChangeShowProject] = useState('My Projects')
  const [isShowTeamProjects, setIsShowTeamProjects] = useState(false)
  const whoseProject = [
    'My Projects',
    'Team Projects'
  ]
  const dispatch = useDispatch()
  const [showArchived, setShowArchived] = useState(false)
  const [adminName, setAdminName] = useState(null)
  const [selectedAdmin, setSelectedAdmin] = useState({
    id: '',
    name: ''
  })
  const [workState, setWorkState] = useState('All')


  const jobState = [
    'All',
    'In progress',
    'Await my actions'
  ]
  const [designerName, setDesignerName] = useState(null)
  const [selectedDesigner, setSelectedDesigner] = useState({
    id: '',
    name: ''
  })
  const handleChange = (e) => {
    setSelectedAdmin({ ...selectedAdmin, id: e.target.value })
  };

  const handleChangeDesigner = (e) => {
    setSelectedDesigner({ ...selectedDesigner, id: e.target.value })
  };
  const [urlArray, setUrlArray] = useState([])
  console.log('url array is', urlArray)
  const [imgArray, setImgArray] = useState([
    {
      width: null,
      height: null
    }
  ])
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null)
  const [teamPro, setTeamPro] = useState("My Projects")
  const singleUser = useSelector((state) => state.user.singleUser)
  const userDetails = useSelector((state) => state.user.userDetail);


  const [allUsers, setAllUsers] = React.useState([]);
  //getting all users
  const getUsers = async () => {
    axios
      .get(`https://dev.ehsprints.com:8000/auth/getUsersAll`,

      )
      .then(({ data }) => {
        setAllUsers(data);
      });
  };

  useEffect(() => {

    if (userDetails?._id) {
      dispatch(getSingle_User(userDetails?._id))
    }

  }, [])

  //details from business id only
  const [currentDetails, setCurrentDetails] = useState(null)

  useEffect(() => {
    const getSingleUser = async () => {

      if (businessId) {
        await axios.get(`${API}auth/getsingleUser/${businessId}`)
          .then(({ data }) => setCurrentDetails(data))
      }
    }

    getSingleUser()
  }, [])

 const myadminid= JSON.parse(localStorage.getItem("myAdminId")) 
  const[adminNamebyid,setadminNamebyId]=useState();
  useEffect(() => {
    const getSingleUser = async () => {
      const adminid= JSON.parse(localStorage.getItem("myAdminId")) 
      console.log(adminid,"dddddddddd----------")

      if (adminid) {
           const  data=  await axios.get(`${API}auth/getsingleUser/${adminid}`)
           console.log('dataadminid',data.data);
           setadminNamebyId(data?.data?.name)
          
      }
    }

    getSingleUser()
  }, [])

  const [instructionOpen, setInstructionPopup] = useState(false)
  const [inputSize, setInputSize] = useState(
    {
      wight: '',
      height: ''
    }
  )

  const [size, setSize] = useState({
    wight: '',
    height: ''
  })
  const [formData, setFormData] = useState(
    {
      title: '',
      templateId: '',
      multiPage: false,
      purpose: '',
      canvasSize: {
        width: '',
        height: ''
      },
      // graphicsSize:'',
      placestoDecorate: {
        where: '',
        color: ''
      },
      files: [],
      position: '',
      projectDesc: '',
      demoImgUrl: '',
      isMulti: false,
      multiImgUrl: null,
      multiimgSize: [],
      designerId: '',
      myId: `${userDetails?._id}`
    }
  )

  const fs = useSelector(state => state.form.form)
  const [forms, setForms] = useState([])
  // useEffect(() => {
  //   dispatch(getAllforms())
  // }, [])
  const [loading, setLoading] = useState(false)
  const [teamloading, setTeamLoading] = useState(false)
  //getting diff ops as per selected team members

  const getForms = async () => {
    setTeamLoading(true)
    if (teamMail === "All") {
      await axios.get(`${API}form/getforms`)
        .then(({ data }) => {
          setTeamLoading(false)
          setForms(data)
        })
    }
    else {
      setTeamLoading(true)
      await axios.get(`${API}form/getformsByteamMail/${teamMail}`)
        .then(({ data }) => {
          setTeamLoading(false)
          setForms(data)
        })
    }

  }

  useEffect(() => {
    getForms()
  }, [teamMail])

  //for admin/client
  const myForms = fs?.filter((f) => f?.myId?._id === userDetails?._id)

  //showing team projects to team member for mombers
  const teamForms = fs?.filter((f) => f?.myId?._id === businessId ||
    f?.team?.find((t) => t === userDetails?.emailid)
  )

  console.log('team forms are', teamForms)

  // 


  //for designer
  const designerForms = fs?.filter((f) => f?.designerId?._id === userDetails?._id)


  //finding (for designer) those forms where selectedadminid is present
  const filteredForms = designerForms?.filter((f) => f?.myId?._id === selectedAdmin?.id)

  //finding (for admin) those forms where selecteddesigners is present
  const filteredFormsForAdmin = myForms?.filter((f) => f?.designerId?._id === selectedDesigner?.id)

  //when on designers are selected
  const getMultiimgData = (id) => {
    setImgArray(myForms[id]?.multiimgSize)
    setUrlArray(myForms[id]?.multiImgUrl)
  }

  //when designers are selected
  const getFilterMultiimgData = (id) => {
    setImgArray(filteredFormsForAdmin[id]?.multiimgSize)
    setUrlArray(filteredFormsForAdmin[id]?.multiImgUrl)


  }
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2500);
  })

  useEffect(() => {
    const fetchData = async () => {
      if ((localStorage.getItem("myuser")) && !userDetails) {
        // alert("getUser dispatched from in app js")
        await dispatch(getUser());
      }
    };

    fetchData();
  }, [dispatch, userDetails]);



  return (
    <>
      {
        loading ?
          <div>

            <FormSkaliton />
          </div> :
          <div className=' py-2 px-4'>
            {/* <ToastContainer/> */}
            {/* to show img model to designer only */}
            <ShowImgmodel
              open={open} setOpen={setOpen}
              imgSrc={imgSrc}
            />

            {/* for client/admin only */}
            <InstructionsPopUp
              urlArray={urlArray} setUrlArray={setUrlArray}
              imgArray={imgArray} setImgArray={setImgArray}
              allUsers={allUsers}
              size={size}
              setSize={setSize}
              inputSize={inputSize}
              setInputSize={setInputSize}
              formData={formData} setFormData={setFormData} open={instructionOpen} setOpen={setInstructionPopup}
            />

            {/* to show the forms which ar created by client and users */}

            {
              userDetails?.Role !== 'designer' &&
              <div className=' flex flex-col gap-2 mt-1'>

                {
                  (userDetails?.Role === "corporate_admin" || businessId) &&
                  <div className=' flex justify-end flex-wrap gap-2 mt-2 my-auto lg:ml-[360px] pl-2.5 '>

                    {/* for admin to show the team projects */}
                    {userDetails?.Role === "corporate_admin" && !businessId &&
                      <div className=' flex gap-2 items-center px-2 py-1 bg-zinc-700 rounded-3xl'>
                        {whoseProject?.map((item, id) => (
                          <button
                            onClick={() => {
                              // setSelectedDesigner({
                              //   id:'', 
                              //   name:''
                              // })
                              // setWorkState('All')
                              if (item === "My Projects") {
                                setIsShowTeamProjects(false)
                              }
                              else {
                                setIsShowTeamProjects(true)
                              }
                              setTeamPro(item)
                              // setDesignerName(null)
                              // setShowArchived(false)
                              // setChangeShowProject(item)
                              setChangeShowProject(item)


                            }}
                            // className={item === teamPro ? ' rounded-2xl bg-slate-50 text-black px-3 py-1 m-1 ' : ' text-white px-2 py-1 m-1 '}>
                            className={item === changeShowProject ? ' rounded-2xl bg-slate-50 text-black px-3 py-1 m-1 ' : ' text-white px-2 py-1 m-1 '}>

                            {item}
                          </button>
                        ))}
                      </div>
                    }
                    {/* <p className=' font-semibold my-auto text-2xl'>{ (designerName && !showArchived) ?`${"Instructions for "+designerName}`: (!showArchived && !designerName) ?"All Instructions":"Previous Instructions"}</p> */}
                    <div className=' z-[40] flex gap-2 py-1 px-2 text-white items-center rounded-3xl my-auto bg-zinc-700 '>
                      {jobState?.map((item, id) => (
                        <button
                          disabled={(item === 'Await my actions' && userDetails?.Role !== "corporate_admin" && changeShowProject !== 'My Projects') && true}
                          onClick={() => {
                            setSelectedDesigner({
                              id: '',
                              name: ''
                            })
                            setDesignerName(null)
                            setShowArchived(false)
                            setTeamPro("My Projects")
                            setIsShowTeamProjects(false)
                            setWorkState(item)
                          }}
                          className={item === workState ? ' rounded-2xl bg-slate-50 text-black px-3 py-1 m-1 ' : ' text-white px-2 py-1 m-1 '}>
                          {item}
                        </button>
                      ))}
                    </div>

                    <div
                      className=' flex gap-2 my-auto'>
                      {
                        businessId &&
                        <div className=' z-[40] flex gap-2 py-1 px-2 text-white items-center rounded-3xl my-auto bg-zinc-700 '>
                          {whoseProject?.map((item, id) => (
                            <button
                              onClick={() => {
                                setSelectedDesigner({
                                  id: '',
                                  name: ''
                                })
                                setWorkState('All')
                                setDesignerName(null)
                                setShowArchived(false)
                                setChangeShowProject(item)
                              }}
                              className={item === changeShowProject ? ' rounded-2xl bg-slate-50 text-black px-3 py-1 m-1 ' : ' text-white px-2 py-1 m-1 '}>
                              {item}
                            </button>
                          ))}
                        </div>}
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">See Instructions</Typography>
                            <Typography sx={{ fontSize: '15px' }} color="inherit">of your approved designs</Typography>
                          </React.Fragment>

                        }
                      >


                        <button
                          style={{
                            zIndex: 1
                          }}
                          className=' bg-zinc-800 md:text-base text-base text-white border-[1px] border-zinc-500 px-4 py-2.5 rounded-md hover:bg-indigo-800 transition-all duration-200'
                          onClick={() => {
                            setDesignerName(null)
                            setSelectedDesigner({
                              id: '',
                              name: ''
                            })
                            setShowArchived(true)
                            setIsShowTeamProjects(false)
                          }}
                        >Archived</button>

                      </Tooltip>
                    </div>
                  </div>}

                {
                  (userDetails?.Role === "corporate_admin" || businessId) &&
                  <>


                    {/* <p className=' font-semibold my-auto ml-2.5 text-2xl'>{ (designerName && !showArchived) ?`${"Instructions for "+designerName}`: (!showArchived && !designerName && workState ==='All') ?"All Projects":(workState ==='In progress' && !showArchived)?"Ongoing Projects":(workState ==='Await my actions' && !showArchived)?"Approve pending Projects":"Previous Projucts"}</p> */}

                    {/* {selectedDesigner?.id && filteredFormsForAdmin?.length === 0 &&
  <div className=' h-[60vh] flex justify-center items-center w-full'>
   <p className=' font-bold text-2xl text-black'>No instructions yet</p>
  </div>
  } */}
                    {/* {!selectedDesigner?.id && myForms?.length === 0 &&
  <div className=' h-[60vh] flex justify-center items-center w-full'>
   <p className=' font-bold text-2xl text-black'>No Instructions are available</p>
  </div>
  } */}
                  </>}


                {
                  (changeShowProject === 'My Projects' && userDetails?.Role !== 'designer') ?
                    <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                      sx={{
                        ml: 0.5
                      }}
                      spacing={2}>

                      {/* {
                        userDetails?.Role !== 'designer' &&
                        <div
                          className=' flex flex-col justify-center items-center'>
                          <p className=' font-bold lg:text-4xl text-xl lg:ml-3 lg:mt-[35px] z-50 p-2 text-[#74cf29]'>Create something Amazing </p>
                          {
                            userDetails?.Role !== 'designer' &&
                            <Tooltip followCursor
                              title={
                                <React.Fragment >
                                  <Typography color="inherit">Create New Project</Typography>
                                </React.Fragment>
                              }
                            >
                              <div className=' flex gap-2 justify-between items-center h-[200px]'>
                                <p className=' xl:flex hidden xl:flex-wrap font-bold mt-[-20px] text-xl w-[180px] '>{singleUser?.name}</p>
                                <Tooltip followCursor
                                  title={
                                    <React.Fragment >
                                      <Typography color="inherit">Create New Project</Typography>
                                    </React.Fragment>
                                  }
                                ></Tooltip>
                                <Divider
                                  className='xl:flex hidden'
                                  sx={{
                                    color: 'black',
                                    background: 'black',
                                    width: '3px',
                                    mt: '-5px',
                                    height: '170px'
                                  }} orientation="vertical"></Divider>
                                <img
                                  onClick={() => {
                                    getUsers()
                                    setFormData({
                                      ...formData,
                                      title: '',
                                      id: '',
                                      isMulti: false,
                                      templateId: '',
                                      myId: `${userDetails?._id}`,
                                      designerId: '',
                                      team: [],
                                      purpose: '',
                                      Material: '',
                                      demoImgUrl: '',
                                      projectDesc: '',
                                      placestoDecorate: {
                                        where: '',
                                        color: '#fffff'
                                      },
                                      canvasSize: {
                                        width: '',
                                        height: ''
                                      }

                                    })
                                    setUrlArray([])
                                    setImgArray([])
                                    setSelectedDesigner({
                                      id: '',
                                      name: ''
                                    })
                                    setDesignerName(null)
                                    setInstructionPopup(true)

                                  }
                                  }

                                  className=' border-[1px] mt-[-40px] max-h-[200px] w-[200px] hover:border-indigo-700 transition-all duration-200 cursor-pointer border-indigo-300 rounded-xl' src="https://ssl.gstatic.com/docs/templates/thumbnails/forms-blank-googlecolors.png" alt="" />
                              </div>
                            </Tooltip>}

                        </div>
                        } */}

{  console.log(changeShowProject , "andworkstate",workState )  }
                         {(userDetails?.Role!=='designer' &&     ( changeShowProject==="My Projects" && workState ==="All" ) &&  !(changeShowProject==="Team Projects" && workState ==="All" ) && workState !=='In progress' && workState !=="Await my actions"    ) &&  
                     
                    //  <div
                    //      className=' flex flex-col justify-center items-center'>
                    //      <p className=' font-bold lg:text-4xl text-xl lg:ml-3 lg:mt-[35px] z-50 p-2 text-[#74cf29]'>Create something Amazing Today</p>
                    //      {
                    //        userDetails?.Role !== 'designer' &&
                    //        <Tooltip followCursor
                    //          title={
                    //            <React.Fragment >
                    //              <Typography color="inherit">Create New Project</Typography>
                    //            </React.Fragment>
                    //          }
                    //        >
                    //          <div className=' flex gap-2 justify-between items-center h-[200px]'>
                    //            <p className=' xl:flex hidden xl:flex-wrap font-bold mt-[-20px] text-xl w-[180px] '>{singleUser?.name}</p>
                    //            <Tooltip followCursor
                    //              title={
                    //                <React.Fragment >
                    //                  <Typography color="inherit">Create New Project</Typography>
                    //                </React.Fragment>
                    //              }
                    //            ></Tooltip>
                    //            <Divider
                    //              className='xl:flex hidden'
                    //              sx={{
                    //                color: 'black',
                    //                background: 'black',
                    //                width: '3px',
                    //                mt: '-5px',
                    //                height: '170px'
                    //              }} orientation="vertical"></Divider>
                    //            <img
                    //              onClick={() => {
                    //                getUsers()
                    //                setFormData({
                    //                  ...formData,
                    //                  title: '',
                    //                  id: '',
                    //                  isMulti: false,
                    //                  templateId: '',
                    //                  myId: `${userDetails?._id}`,
                    //                  designerId: '',
                    //                  team: [],
                    //                  purpose: '',
                    //                  Material: '',
                    //                  demoImgUrl: '',
                    //                  projectDesc: '',
                    //                  placestoDecorate: {
                    //                    where: '',
                    //                    color: '#fffff'
                    //                  },
                    //                  canvasSize: {
                    //                    width: '',
                    //                    height: ''
                    //                  }

                    //                })
                    //                setUrlArray([])
                    //                setImgArray([])
                    //                setSelectedDesigner({
                    //                  id: '',
                    //                  name: ''
                    //                })
                    //                setDesignerName(null)
                    //                setInstructionPopup(true)

                    //              }
                    //              }

                    //              className=' border-[1px] mt-[-40px] max-h-[200px] w-[200px] hover:border-indigo-700 transition-all duration-200 cursor-pointer border-indigo-300 rounded-xl' src="https://ssl.gstatic.com/docs/templates/thumbnails/forms-blank-googlecolors.png" alt="" />
                    //          </div>
                    //        </Tooltip>
                    //       }

                    //    </div> 

                    <div
                          className=' flex flex-col justify-center items-center'>
                          <p className=' font-bold xl:text-4xl lg:text-xl lg:ml-3 lg:mt-[35px] z-50 p-2 text-[#74cf29]'>Create something Amazing Today</p>
                          {
                            userDetails?.Role !== 'designer' &&
                            // <Tooltip followCursor
                            //   title={
                            //     <React.Fragment >
                            //       <Typography color="inherit">Create New Project</Typography>
                            //     </React.Fragment>
                            //   }
                            // >
                            <div className=' flex xl:flex-row md:flex-col gap-2 justify-between items-center h-[200px]'>
                              <div className='flex flex-wrap xl:w-[8rem] md:w-full'>
                                   {/* <p className='  font-bold text-xl break-all'>{singleUser?.name}</p> */}
                                   {myadminid ? <p className='  font-bold text-xl break-all'>{adminNamebyid}</p>: <p className='  font-bold text-xl break-all'>{singleUser?.name}</p> }
                              </div>
                              <div>
                                <Divider
                                  className='xl:flex hidden'
                                  sx={{
                                    color: 'black',
                                    background: 'black',
                                    width: '3px',
                                    mt: '-5px',
                                    height: '170px'
                                  }} orientation="vertical">
                                </Divider>
                              </div>
                              <div className='pt-7'>
                                <Tooltip followCursor
                                  title={
                                    <React.Fragment >
                                      <Typography color="inherit">Create New Project</Typography>
                                    </React.Fragment>
                                  }
                                >
                                  <img
                                    onClick={() => {
                                      getUsers()
                                      setFormData({
                                        ...formData,
                                        title: '',
                                        id: '',
                                        isMulti: false,
                                        templateId: '',
                                        myId: `${userDetails?._id}`,
                                        designerId: '',
                                        team: [],
                                        purpose: '',
                                        Material: '',
                                        demoImgUrl: '',
                                        projectDesc: '',
                                        placestoDecorate: {
                                          where: '',
                                          color: '#fffff'
                                        },
                                        canvasSize: {
                                          width: '',
                                          height: ''
                                        }

                                      })
                                      setUrlArray([])
                                      setImgArray([])
                                      setSelectedDesigner({
                                        id: '',
                                        name: ''
                                      })
                                      setDesignerName(null)
                                      setInstructionPopup(true)

                                    }
                                    }

                                    className=' border-[1px] mt-[-40px] max-h-[200px] w-[200px] hover:border-indigo-700 transition-all duration-200 cursor-pointer border-indigo-300 rounded-xl' src="https://ssl.gstatic.com/docs/templates/thumbnails/forms-blank-googlecolors.png" alt="" />
                                </Tooltip>
                              </div>
                            </div>
                            // </Tooltip>
                          }

                        </div>


                        } 

                      {
                        myForms?.length === 0 && userDetails?.Role !== 'designer' &&
                        [...Array(4)]?.map((item, idx) => (
                          <div
                            onClick={() => {
                              setFormData({
                                ...formData,
                                title: '',
                                id: '',
                                isMulti: false,
                                templateId: '',
                                myId: `${userDetails?._id}`,
                                designerId: '',
                                purpose: '',
                                Material: '',
                                demoImgUrl: '',
                                projectDesc: '',
                                placestoDecorate: {
                                  where: '',
                                  color: '#fffff'
                                },
                                canvasSize: {
                                  width: '',
                                  height: ''
                                }

                              })
                              setUrlArray([])
                              setImgArray([])
                              setSelectedDesigner({
                                id: '',
                                name: ''
                              })
                              setDesignerName(null)
                              setInstructionPopup(true)

                            }
                            }
                            className=' bg-indigo-200 hover:translate-y-[-2px] cursor-pointer transition-all duration-200 text-xl font-bold rounded-xl w-full flex justify-center items-center h-[270px]'>
                            Fill with your imagination
                          </div>
                        ))
                      }

                      {myForms?.map((f, idx) => (
                        <Form
                          isShowTeamProjects={isShowTeamProjects}
                          changeShowProject={changeShowProject}
                          workState={workState}
                          setWorkState={setWorkState}
                          showArchived={showArchived}
                          myForms={myForms} f={f} idx={idx} userDetails={userDetails} getMultiimgData={getMultiimgData} formData={formData} setFormData={setFormData} setInstructionPopup={setInstructionPopup} />
                      ))}

                      {
                        !businessId && singleUser?.Role === "corporate_admin" && isShowTeamProjects &&
                        <>
                          {
                            forms?.length > 0 ?
                              <>
                                {forms?.map((f, idx) => (
                                  <TeamForm
                                    setInstructionPopup={setInstructionPopup}
                                    formData={formData}
                                    setFormData={setFormData}
                                    f={f} id={idx} singleUser={singleUser} />
                                ))}
                              </>
                              :
                              <div className=' relative w-full flex justify-center items-center mx-auto'>
                                <div
                                  // style={{
                                  //   transform:'translate(-50%,-50%)'
                                  // }}
                                  className=' mt-16 flex items-center justify-center'>
                                  <Puff
                                    visible={true}
                                    height="90"
                                    width="90"
                                    color="#14366e"
                                    ariaLabel="puff-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />

                                </div>

                              </div>
                          }
                        </>
                      }


                    </Masonry> :
                    <Masonry columns={{ xs: 1, md: 2, lg: 4 }}
                      sx={{
                        ml: 0.5
                      }}
                      spacing={2}>

                      {
                        userDetails?.Role !== 'designer' &&
                        teamForms?.map((f, idx) => (
                          <Form
                            isShowTeamProjects={isShowTeamProjects}
                            changeShowProject={changeShowProject}
                            workState={workState}
                            setWorkState={setWorkState}
                            showArchived={showArchived}
                            myForms={myForms} f={f} idx={idx} userDetails={userDetails} getMultiimgData={getMultiimgData} formData={formData} setFormData={setFormData} setInstructionPopup={setInstructionPopup} />
                        ))}

                    </Masonry>
                }


              </div>}



            {/* to show the form of clients to the designer */}
            {userDetails?.Role === 'designer' &&
              <div className=' flex flex-col gap-2'>
                <div className=' flex justify-between my-auto pl-2.5 '>
                  <p className=' font-semibold my-auto text-2xl'>{(adminName && !showArchived) ? `${"Instructions from " + adminName}` : (!showArchived && !adminName) ? "All Instructions" : "Previous Instructions"}</p>

                  <div className=' flex gap-2 my-auto'>
                    <Tooltip title={
                      <React.Fragment>
                        <Typography color="inherit">See Instructions</Typography>
                        <Typography sx={{ fontSize: '15px' }} color="inherit">of approved designs</Typography>
                      </React.Fragment>

                    }>
                      <button
                        className=' bg-indigo-100 border-[1px] border-zinc-500 px-4 rounded-[30px] hover:bg-indigo-200 transition-all duration-200'
                        onClick={() => {
                          setAdminName(null)
                          setSelectedAdmin({
                            id: '',
                            name: ''
                          })
                          setShowArchived(true)
                        }}
                      >Archived</button>
                    </Tooltip>

                    <FormControl sx={{ minWidth: 200, mr: 1 }} className=" min-w-[300px]">

                      <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
                      <Select
                        onMouseEnter={() => dispatch(getSingle_User(userDetails?._id))}
                        endAdornment={selectedAdmin?.id && <CloseRounded
                          onClick={() => {

                            setSelectedAdmin({
                              id: '',
                              name: ''
                            })
                            setAdminName(null)
                          }}
                          className=' mr-4 cursor-pointer text-rose-500' />}
                        sx={{
                          height: '55px',
                          width: '250px',
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedAdmin?.id}
                        label="Select Admin"
                        onChange={handleChange}
                      >
                        {singleUser?.adminIds?.map((admin) => (
                          <MenuItem
                            onClick={() => {
                              setShowArchived(false)
                              setAdminName(admin?.name)
                              setSelectedAdmin({
                                id: admin?._id,
                                name: admin?.name
                              })
                            }
                            }
                            value={admin?._id}>
                            <div className=" flex gap-3 my-auto">
                              <Avatar src={admin?.name} alt={admin?.name} />
                              <p className=" my-auto">
                                {admin?.name}
                              </p>

                            </div>
                          </MenuItem>

                        ))}

                      </Select>
                    </FormControl>
                  </div>
                </div>
                {selectedAdmin?.id && filteredForms?.length === 0 &&
                  <div className=' h-[60vh] flex justify-center items-center w-full'>
                    <p className=' font-bold text-2xl text-black'>No Instructions are available from this client</p>
                  </div>
                }
                {!selectedAdmin?.id && designerForms?.length === 0 &&
                  <div className=' h-[60vh] flex justify-center items-center w-full'>
                    <p className=' font-bold text-2xl text-black'>No Instructions are available from any client</p>
                  </div>
                }
                {(!selectedAdmin?._id && !adminName) ?

                  <Masonry columns={{ xs: 1, md: 2, lg: 4 }}
                    sx={{
                      ml: 0.5
                    }}
                    spacing={2}>
                    {designerForms?.map((f, idx) => (
                      <DesignerForm showArchived={showArchived} setOpen={setOpen} imgSrc={imgSrc} setImgSrc={setImgSrc} f={f} userDetails={userDetails} idx={idx} formData={formData} setFormData={setFormData} setInstructionPopup={setInstructionPopup} />
                    ))}
                  </Masonry> :
                  <Masonry columns={{ xs: 1, md: 2, lg: 4 }}
                    sx={{
                      ml: 0.5
                    }}
                    spacing={2}>
                    {filteredForms?.map((f, idx) => (
                      <DesignerFilterForm setOpen={setOpen} imgSrc={imgSrc} setImgSrc={setImgSrc} f={f} userDetails={userDetails} idx={idx} formData={formData} setFormData={setFormData} setInstructionPopup={setInstructionPopup} />
                    ))}
                  </Masonry>
                }
              </div>}


          </div>}
    </>
  )
}
