
const initialState={
    isDeleting:false,
    isSaving:false,
    onlyAbletoSeeandDownload:false
}

 
 export const LoadingReducer=(state=initialState,action)=>{

       switch(action.type){
          case 'START_DELETING':
            return { ...state,isDeleting:true   };    
          
            case 'FINIST_DELETING':
                return { ...state, isDeleting:false  };

          case 'START_SAVING':
            return { ...state,isSaving:true   };

          case 'FINIST_SAVING':
               return { ...state, isSaving:false  };

          case 'ONLYABLE_TO_SEE':
             return { ...state,onlyAbletoSeeandDownload:true   };
          case 'ONLYABLE_TO_SEE_COMPLETE':
              return {...state,onlyAbletoSeeandDownload:false}

           default:
               return state;
       }
}