import React from "react";
import { WishLsitPopUp } from "../popUp/PopUp";
import iconImage from "../../../images/navbarProfileImages/423885b83ba7104caed9fd42e7c80152 1.png";
import { useState } from "react";

const NotSignInPopUp = () => {
  const [popup, setPopup] = useState(true);
  return (
    <div style={{ height: "75vh" }}>
      <WishLsitPopUp
        image={iconImage}
        popup={popup}
        setMyPopup={setPopup}
        heading="Add the products in your Wishlist by"
        // desc="Feature will be available soon....."
        // secondBtn={true}
      />
    </div>
  );
};

export default NotSignInPopUp;
