import axios from 'axios';
import { API } from '../backend';

export const get_User = async (id) => {
    // const getState = useSelector((state) => state);
    //  
    return await axios.get(`${API}auth/getsingleUser/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            console.log("AXIOS_ERROR : ",err)
            return null;
        })
}