import * as React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import MoreVert from '@mui/icons-material/MoreVert';
import FileBase from 'react-file-base64'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Tooltip from '@mui/material/Tooltip'
export default function AddfileMenue({uploadImgSingle,chatData,setChatData}) {
  console.log(chatData)
  const [file,setFile] = React.useState(null)
  console.log('my file is',file)
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
      >
        <Tooltip title="Add file">
        <AttachFileIcon />
        </Tooltip>
      </MenuButton>
      <Menu>
        <MenuItem>
        <div className=''>
        <input
          value={chatData?.demoImgUrl}
          className=''
          onChange={(e) => uploadImgSingle(e)}
          id="file-upload" name="file-upload" type="file" />      
        </div>
        Image</MenuItem>
        <MenuItem>

        <div className=''>
        <FileBase
  type='file'
  multiple={false}
  onDone={({ base64 }) => {
    // setFormdata({ ...formdata, selectedFile: base64 })
    setFile(base64)
    setChatData({...chatData,demoFileUrl:base64})
  }}
/>
        </div>
        File</MenuItem>
      </Menu>
    </Dropdown>
  );
}
