import * as React from 'react';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export default function SortBy({setSelectedCategories,setSelectedMaterial,setSortBytime,sortBytime}) {
  return (
    <Select
    value={sortBytime}
    onChange={(e)=>{
      setSelectedMaterial(null)
      setSelectedCategories([])
      setSortBytime(e.target.value)}}
      placeholder="Sort By"
      indicator={<KeyboardArrowDown />}
      sx={{
        width: 140,
        [`& .${selectClasses.indicator}`]: {
          transition: '0.2s',
          [`&.${selectClasses.expanded}`]: {
            transform: 'rotate(-180deg)',
          },
        },
      }}
    >
      <Option
      onClick={()=>{
        setSelectedMaterial(null)
        setSelectedCategories([])
        setSortBytime("getnewtemps")}}
      value="getnewtemps">Newest</Option>
      <Option
      onClick={()=>{
        setSelectedMaterial(null)
        setSelectedCategories([])
        setSortBytime("getoldtemps")}}
      value="getoldtemps">Oldest</Option>
    </Select>
  );
}