const initialState = {
    //brfore creating new project this values are populated to the designer
    instructionDetail:{
    //instruction id
    instruction:'',
    //client id
    adminId:'',
    //the title which is given by the client
    title:''
    }
    };

const handleInstruction = (state = initialState, action) => {
    switch (action.type) {
      case 'POPULATE_TEMPLATE_DETAIL':
        return {
          ...state,
          instructionDetail: {
            ...state.instructionDetail,
            ...action.payload,
          }
        };
      default:
        return state;
    }
  };
  
  export default handleInstruction;
  