

const initialState = [];
const initialHistory = {
    past: [],
    present: null,
    future: []
}

const handleHistory = (state = initialState, action) => {

    switch(action.type){
        case "UNDO": 
        const {past, present, future} = state[action?.payload?.activePage];
            const prev = past[past.length -1];
            const newPast = past.slice(0, past.length -1);
            state[action.payload.activePage] = {
                past: newPast,
                present: prev,
                future: [present, ...future]
            };
            return state;

        case "REDO": 
        // const {past, present, future} = state[action?.payload?.activePage];
            const next = future[0];
            const newFuture = future.slice(1);
            state[action.payload.activePage] = {
                past: [...past,present],
                present: next,
                future: newFuture
            }
            return state;
        
        case "ADD_HISTORY": 
        // const {past, present, future} = state[action?.payload?.activePage];
            state[action.payload.activePage] = {
                past: present ? past.push(present) : [],
                present: action.payload.currPage,
                future: future
            }        
            return state;

        default: 
            return state;
    }
}

export default handleHistory;