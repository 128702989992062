import { Button } from '@mui/joy'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { API, API1 } from '../../../../../../backend'
import DesignCard from './DesignCard'
import { Puff } from 'react-loader-spinner'

export default function CorporateDesigns({ campaignData, setCampaignData, designArray, setDesignArray }) {
  const userDetails = useSelector((state) => state.user.userDetail)
  const getState = useSelector((state) => state)
  const businessId = JSON.parse(localStorage.getItem('businessId'))
  const [loading, setLoding] = useState(false)
  const [templates, setTemplates] = useState([])
  const state = [
    "My Designs",
    "Team Designs"
  ]

   

  //adding design to designArray
  const addDesign = (id) => {
    if (!designArray?.find((idx) => id === idx)) {
      setDesignArray([...designArray, id])
    }
  }
  //removing design from designArray
  const removeDesign = (id) => {
    let updatedDesignArray = designArray?.filter((idx) => idx !== id)
    setDesignArray(updatedDesignArray)
  }

  //for repainting the design array data after adding a template to the state designArray
  useEffect(() => {
    setCampaignData({ ...campaignData, designs: designArray })
  }, [designArray])


  console.log("corporate designs are", campaignData?.designs)

  const [currentState, setCurrentState] = useState("My Designs")
  React.useEffect(() => {
    const getTemp = async (getState) => {
      setLoding(true)
      if (currentState === "My Designs") {
        await axios.get(`${API}diy/gettemplatesbyadminId?adminId=${userDetails?._id}`)
          .then(({ data }) => {
            const res = data?.filter((t, id) => t?.isApproved)
            setTemplates(res)
            setLoding(false)
          })
      }
      else if (currentState === "Team Designs") {
        if (!businessId) {
          //for only Team Projects
          const filteredList = await Promise.all(
            userDetails?.team?.map(async (mail) => {
              let userdata = await axios.get(`${API1}auth/getUserByMail/${mail}`)
              userdata = userdata?.data
              let res = await axios.get(`${API}diy/gettemplatesbyadminId?adminId=${userdata?._id}`)
              const newRes = res?.data?.filter((t) => t?.adminId === userdata?._id && t?.isApproved)
              return newRes
            })
          )
          console.log('filtered Data is this', filteredList.flat())
          setTemplates(filteredList?.flat())
          setLoding(false)
        }
        else {
          await axios.get(`${API}diy/gettemplates`)
            .then(({ data }) => {
              const res = data?.filter((f) => (f?.adminId === businessId ||
                f?.instruction?.team?.find((t) => t === userDetails?.emailid) && f?.isApproved)
              )
              setTemplates(res)
              setLoding(false)
            })
        }

      }
    }
    getTemp()
  }, [currentState])

  return (
    <div className=' w-full py-1.5'>
      <div className=' flex gap-2 mt-[-12px] items-center'>
        {state?.map((item, idx) => (
          <Button
            variant={item !== currentState ? "soft" : "outlined"}
            onClick={() => setCurrentState(item)}

          >{item}</Button>
        ))}
      </div>
      {
        !loading ?
          <div className='grid gap-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 py-2 h-[300px] overflow-y-auto scroll-smooth'>
            {
              templates?.map((t, idx) => (
                <DesignCard
                  designArray={designArray}
                  addDesign={addDesign}
                  removeDesign={removeDesign}
                  t={t} idx={idx} />
              ))
            }

          </div> :
          <div className=' h-[300px] relative'>
            <div
              style={{
                transform: 'translate(-50%,-50%)'
              }}
              className=' absolute top-[50%] left-[50%] flex justify-center items-center'>
              <Puff
                visible={true}
                height="90"
                width="90"
                color="#14366e"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </div>
      }
    </div>
  )
}
