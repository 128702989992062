import { Input } from '@mui/joy'
import React, { useRef, useState } from 'react'

export default function ImageSingle({DeleteForeverIcon,setImgArray,imgArray,delImg,urlArray,id,formData,setFormData}) {
    const inputRef = useRef(null);
    const [showSize,setShowSize] = useState(false)
    console.log('image array',imgArray)
  return (
    <div
    onMouseEnter={()=>{
      setShowSize(true)}}
    onMouseLeave={()=>setShowSize(false)}
    className=' relative'>
 <div
style={{
    background:'linear-gradient(270deg, rgba(100,111,129,0.6671918767507004) 0%, rgba(13,19,29,0.8240546218487395) 99%)'
}}
className={ !showSize?' transition-all p-3 rounded-l-xl  backdrop-blur-md duration-200 absolute left-0 bottom-0 w-full h-[100px] opacity-90':' opacity-100 transition-all p-3 rounded-l-xl w-[70%] h-full backdrop-blur-md duration-200 absolute left-0 top-0'}>
<div className=' flex flex-col gap-2'>
<p className=' my-auto text-white'>
Choose Size:
</p>
<div className={ showSize?' flex flex-col gap-2':' flex gap-2 my-auto'}>
<Input
required
sx={{
width:150
}}
type="number"
placeholder='in inch'
value={imgArray[id]?.width}
onChange={(event)=>{
let updatedArray = [...imgArray]
updatedArray[id].width = event.target.value
setImgArray(updatedArray)}}
slotProps={{
input: {
ref: inputRef,
min: 2,
max: 48,
step: 0.1,
},
}}
/>
<Input
    sx={{
      width:150
    }}
required
type="number"
placeholder='in inch'
value={imgArray[id]?.height}
onChange={(event)=>{
let updatedArray = [...imgArray]
updatedArray[id].height = event.target.value
setImgArray(updatedArray)}}
slotProps={{
input: {
ref: inputRef,
min: 2,
max: 48,
step: 0.1,
},
}}
/>
</div>
</div>
</div>
                          <div
        onClick={()=>delImg(id)}
        className='  bg-zinc-800 rounded-full cursor-pointer p-2 
         transition-all duration-200
        hover:bg-black absolute right-0 top-0 mt-1 mr-1 border-[1px] border-zinc-600 '>

            <DeleteForeverIcon className=' text-rose-400'/>
        </div>
    <img
    style={{
        backgroundSize:'cover'
    }}
    className=' rounded-lg bg-no-repeat object-cover w-full h-auto'
    src={urlArray[id]} alt="" />
    </div>
  )
}
