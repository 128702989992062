import React from "react";
import { useNavigate } from "react-router-dom";

const Studios = () => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate("/create-studios");
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col items-center p-6">
        <img
          src="https://static.canva.com/web/images/e7f4530e286a04beb469c13d39964a8f.png"
          alt="Studio Image"
          className="mb-4 max-w-xs"
        />
        <div className="text-center mb-4">
          <span className="text-lg font-bold block">
            Share faster with groups
          </span>
          <span className="text-sm block">
            Share designs and folders with a group of people within your team.{" "}
          </span>
          <span className="text-sm block">
            No more adding individual people! To get started, create a group and
            add your team members.
          </span>
        </div>
        <button
          onClick={handleClick}
          className="bg-[#04c4cc] hover:bg-[#a68cef] text-white font-medium py-2 px-8 rounded text-lg"
        >
          Create Studio
        </button>
      </div>
    </div>
  );
};

export default Studios;
