// // const pageEmpty=[{
// //     template:null,
// //     svgs:[],
// //     logos:[],
// //     texts:[],
// //     textTemplate:[],
// //     backgroundColor:"white",
// //     comment:"",
// //     notes:"",
// //     title:"",
// //     frame:{

// //         frameNumber:0
// //     }
// // }]
// // const initialState = [];

// // const handlePage = (state = initialState, action) => {
// //     // console.log(state.cart);

// //     let page =null;
// //     switch (action.type) {
// //         case 'ADD_PAGE':return [...action.payload.page];

// //         case 'EMPTY_PAGE' :

// //             return state.slice(0, action.payload.pageIndex).concat([pageEmpty]).concat(state.slice(action.payload.pageIndex+1));
// //          case "UPDATE_PAGE":
// //             return state.slice(0, action.payload.pageIndex).concat([action.payload.pageUpdated]).concat(state.slice(action.payload.pageIndex+1));

// //         case 'GET_TEMPLATE':

// //         // console.log(state,"------");

// //             page ={...state[action.payload.pageIndex], template:action.payload.template}
// //             const obj= state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));
// //         //    console.log(obj,"=====");
// //            return obj
// //         case 'UPDATE_TEMPLATE_COLOR':
// //                 return  state.slice(0, action.payload.pageIndex).concat([action.payload.page]).concat(state.slice(action.payload.pageIndex+1));
// //         case 'GET_SVGS' :
// //             page = {
// //                 ...state[action.payload.pageIndex], svgs:[...state[action.payload.pageIndex][0].svgs, ...action.payload.Svg]
// //             }
// //             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

// //         case 'GET_LOGOS':
// //             page =  {
// //                 ...state[action.payload.pageIndex][0],
// //                 logos:[...state[action.payload.pageIndex][0].logos,...action.payload.logo]
// //             }
// //             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

// //         case 'SET_LOGOS':
// //             page =   {
// //                 ...state[action.payload.pageIndex], logos:action.payload.logo
// //             }
// //             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

// //         case 'GET_TEXTS':
// //             page =  {
// //                 ...state[action.payload.pageIndex][0], texts:[...state[action.payload.pageIndex][0].texts ,...action.payload.text]
// //             }
// //             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

// //         case 'UPDATE_TEXTS':
// //                page =   {
// //                     ...state[action.payload.pageIndex], texts:[...action.payload.textArr]
// //                 }
// //                 console.log(page,"#################################");
// //                // return [...state]
// //                 return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

// //         case 'GET_TEXT_TEMPLATE':
// //             page = {
// //                 ...state[action.payload.pageIndex], textTemplate:[...state[action.payload.pageIndex][0].textTemplate, ...action.payload.textTemplate]
// //             }
// //             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

// //         default: return state
// //     }
// // }
// // export default handlePage;

// // =======================================================================================================

// const pageEmpty = {
//   // template: null,
//   svgs: [],
//   logos: [],
//   texts: [],
//   // textTemplate: [],
//   shapes: [],
//   backgroundColor: "#FFFFFF",
//   comment: "",
//   notes: "",
//   title: "Untitled Project",
//   // frame: {
//   //   frameNumber: 0,
//   // },
//   shapeCanvas: "6x6",
// };

// const initialState = [

// ];
// const textBox = { pageIndex: "", index: "" };

// const handlePage = (state = initialState, action) => {
//   // console.log(state.cart);

//   let page = null;
//   let tB = null;

//   switch (action.type) {
//     case "ADD_PAGE":
//       return [...action.payload.page];

//     case "CREATE_EMPTY_PAGE":
//       return [...action.payload.page, { ...pageEmpty }];

//     case "CREATE_MOCkUP_PAGE":
//       return [...action.payload.page, action.payload.mockup];

//     case "EMPTY_PAGE":
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat([pageEmpty])
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "UPDATE_PAGE":
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat([action.payload.pageUpdated])
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "SET_PAGES":
//       return [...action.payload.page];

//     case "REPLACE_TEMP":
//       return [...action.payload.page];

//     case "GET_TEMPLATE":
//       page = {
//         ...state[action.payload.pageIndex],
//         template: action.payload.template,
//       };
//       const obj = state
//         .slice(0, action.payload.pageIndex)
//         .concat([page])
//         .concat(state.slice(action.payload.pageIndex + 1));
//       return obj;

//     case "UPDATE_TEMPLATE_COLOR":
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat(action.payload.page)
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "GET_SVGS":
//       page = {
//         ...state[action.payload.pageIndex],
//         svgs: [
//           ...state[action.payload.pageIndex][0].svgs,
//           ...action.payload.Svg,
//         ],
//       };
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat([page])
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "CHANGE_TEMPLATE":
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat(action.payload.pageUpdated)
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "GET_LOGOS":
//       page = {
//         ...state[action.payload.pageIndex][0],
//         logos: [
//           ...state[action.payload.pageIndex][0].logos,
//           ...action.payload.logo,
//         ],
//       };
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat(page)
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "ADD_LOGO":
//       const currPageIdx = action.payload.pageIndex;
//       page = {
//         ...state[currPageIdx],
//         logos: [
//           ...(Array.isArray(state[currPageIdx]?.logos)
//             ? state[currPageIdx].logos
//             : []),
//           action.payload.logo,
//         ],
//       };
//       state[currPageIdx].logos = page.logos;
//       return state;

//     case "ADD_TEXT":
//       const currPage = action.payload.pageIndex;
//       page = {
//         ...state[currPage],
//         texts: [
//           ...(Array.isArray(state[currPage].texts)
//             ? state[currPage].texts
//             : []),
//           ...action.payload.text,
//         ],
//       };
//       state[currPage].texts = page.texts;
//       return state;

//     case "UPDATE_TEXTS":
//       page = {
//         ...state[action.payload.pageIndex],
//         texts: [...action.payload.textArr],
//       };

//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat([page])
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "GET_TEXT_TEMPLATE":
//       page = {
//         ...state[action.payload.pageIndex],
//         textTemplate: [
//           ...state[action.payload.pageIndex][0].textTemplate,
//           ...action.payload.textTemplate,
//         ],
//       };
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat([page])
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "UPDATE_PAGE_SHAPE":
//       return (state[action.payload.pageIndex].shapeCanvas =
//         action.payload.shape);

//     case "SET_TEXTBOX":
//       textBox.pageIndex = action.payload.pageIndex;
//       textBox.index = action.payload.textboxIndex;
//       return state;

//     case "EDIT_TEXT":
//       tB = {
//         ...state[action.payload.activePage].texts[action.payload.activeElement],
//         fontSize: action.payload.fontSize,
//         fontWeight: action.payload.fontWeight,
//         family: action.payload.fontFamily,
//       };
//       let p = {
//         ...state[action.payload.activePage],
//         texts: state[action.payload.activePage].texts
//           .slice(0, action.payload.activeElement)
//           .concat(tB)
//           .concat(
//             state[action.payload.activePage].texts.slice(
//               action.payload.activeElement + 1
//             )
//           ),
//       };

//       return state
//         .slice(0, textBox.pageIndex)
//         .concat(p)
//         .concat(state.slice(textBox.pageIndex + 1));

//     // case "ROTATE_TEXT":
//     // 	state[action.payload.pageIndex].texts[
//     // 		action.payload.Eindex
//     // 	].rotate = action.payload.rotate;
//     // 	return state;

//     case "UPLOAD_IMG":
//       state[action.payload.pageIndex].images
//         ? (page = {
//             ...state[action.payload.pageIndex],
//             images: [
//               ...state[action.payload.pageIndex].images,
//               {
//                 file: action.payload.file,
//                 height: 100 * action.payload.aspectratio,
//                 width: 100,
//                 x: "50px",
//                 y: "50px",
//                 rotate: 0,
//                 zIndex: 0,
//               },
//             ],
//           })
//         : (page = {
//             ...state[action.payload.pageIndex],
//             images: [
//               {
//                 file: action.payload.file,
//                 height: 100 * action.payload.aspectratio,
//                 width: 100,
//                 x: "50px",
//                 y: "50px",
//                 rotate: 0,
//                 zIndex: 0,
//               },
//             ],
//           });
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat(page)
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "UPLOAD_MOCKIMG":
//       state[action.payload.pageIndex].images
//         ? (page = {
//             ...state[action.payload.pageIndex],
//             images: [
//               ...state[action.payload.pageIndex].images,
//               {
//                 file: action.payload.file,
//                 height: action.payload.h,
//                 width: action.payload.w,
//                 x: "152px",
//                 y: "35px",
//                 rotate: 0,
//                 zIndex: 2,
//               },
//             ],
//           })
//         : (page = {
//             ...state[action.payload.pageIndex],
//             images: [
//               {
//                 file: action.payload.file,
//                 height: action.payload.h,
//                 width: action.payload.w,
//                 x: "152px",
//                 y: "35px",
//                 rotate: 0,
//                 zIndex: 2,
//               },
//             ],
//           });
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat(page)
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "DELETE_IMG":
//       state[action.payload.pageIndex].images.length > 1
//         ? (page = {
//             ...state[action.payload.pageIndex],
//             images: state[action.payload.pageIndex].images.filter(
//               (item, Eindex) => Eindex !== action.payload.index
//             ),
//           })
//         : (page = {
//             ...state[action.payload.pageIndex],
//             images: [],
//           });
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat(page)
//         .concat(state.slice(action.payload.pageIndex + 1));

//     // case "DELETE_SHAPE":
//     //   const pageIndex = action.payload.pageIndex;
//     //   const shapeIndex = action.payload.index;

//     //   if (state[pageIndex] && Array.isArray(state[pageIndex].shapes)) {
//     //     console.log("Original shapes array:", state[pageIndex].shapes);
//     //     console.log("Shape index to filter out:", shapeIndex);

//     //     const newShapes = state[pageIndex].shapes.filter((item, index) => {
//     //       console.log("INDEX: ", index)
//     //       return(index === shapeIndex)
//     //     });

//     //     console.log("Filtered shapes array:", newShapes);
//     //   }
//     //   // console.log("E_INDEX :", Eindex)
      
//     //   state[action.payload.pageIndex].shapes.length > 1
//     //     ? (page = {
//     //         ...state[action.payload.pageIndex],
//     //         shapes: state[action.payload.pageIndex].shapes.filter(
//     //           (item, Eindex) => (Eindex !== action.payload.index)),
//     //         })
//     //         : (page = {
//     //         ...state[action.payload.pageIndex],
//     //         shapes: [],
//     //         });
//     //         return state
//     //         .slice(0, action.payload.pageIndex)
//     //         .concat(page)
//     //         .concat(state.slice(action.payload.pageIndex + 1));
            
//     //updated code
//     case "DELETE_SHAPE":
//       let pageIndex = action.payload.pageIndex;
//       let shapeIndex = action.payload.index;

//       if (state[pageIndex] && Array.isArray(state[pageIndex].shapes)) {
//         console.log("Original shapes array:", state[pageIndex].shapes);
//         console.log("Shape index to filter out:", shapeIndex);

//         // const newShapes = state[pageIndex].shapes.filter((item, index) => {
//         //   console.log("INDEX: ", index)
//         //   return (index !== shapeIndex)
//         // });
//         state[pageIndex].shapes.splice(shapeIndex, 1)
//         console.log("Filtered shapes array:", state[pageIndex].shapes);
//       }

//       state[action.payload.pageIndex].shapes.length > 1
//         ? (page = {
//           ...state[pageIndex],
//           shapes: state[pageIndex].shapes,
//         })
//         : (page = {
//           ...state[pageIndex],
//           shapes: state[pageIndex].shapes,
//         });
//       return state
//         .slice(0, action.payload.pageIndex)
//         .concat(page)
//         .concat(state.slice(action.payload.pageIndex + 1));
    
    
//             case "DELETE_TEXT":
//               state[action.payload.pageIndex].texts.length > 1
//               ? (page = {
//                 ...state[action.payload.pageIndex],
//                 texts: state[action.payload.pageIndex].texts.filter(
//               (item, Eindex) => Eindex !== action.payload.index
//             ),
//             })
//             : (page = {
//               ...state[action.payload.pageIndex],
//               texts: [],
//               });
//               return state
//               .slice(0, action.payload.pageIndex)
//               .concat(page)
//         .concat(state.slice(action.payload.pageIndex + 1));

//     case "ROTATE_IMG":
//       if (action.payload.element === "image") {
//         state[action.payload.pageIndex].images[action.payload.Eindex].rotate =
//           action.payload.rotate;
//       } else if (action.payload.element === "logos") {
//         state[action.payload.pageIndex].logos[action.payload.Eindex].rotate =
//           action.payload.rotate;
//       }
//       // else {
//       //   state[action.payload.pageIndex].onlineElements[
//       //     action.payload.Eindex
//       //   ].rotate = action.payload.rotate;
//       // }
//       return state;
//     // case "ROTATE_SHAPE":
//     // 	state[action.payload.pageIndex].shapes[
//     // 		action.payload.Eindex
//     // 	].rotate = action.payload.rotate;
//     // 	return state;

// 	case "ROTATE_IMAGE":
// 		state[action.payload.pageIndex].images[action.payload.elementIndex].transform = action.payload.transform;
// 	return state;

// 	case "ROTATE_LOGO":
// 		state[action.payload.pageIndex].logos[action.payload.elementIndex].transform = action.payload.transform;
// 	return state;

// 	case "SET_LOGO_POSITION":
// 		state[action.payload.pageIndex].logos[action.payload.elementIndex].x =
// 		  action.payload.x;
// 		state[action.payload.pageIndex].logos[action.payload.elementIndex].y =
// 		  action.payload.y;
// 		return state;

// 	case "SET_IMAGE_POSITION":
// 		state[action.payload.pageIndex].images[action.payload.elementIndex].x =
// 		  action.payload.x;
// 		state[action.payload.pageIndex].images[action.payload.elementIndex].y =
// 		  action.payload.y;
// 		return state;

//     case "ROTATE_SHAPE":
//       // state[action.payload.pageIndex].shapes[
//       //   action.payload.elementIndex
//       // ].transform = action.payload.transform;

//       console.log(action.payload,"checinhaction.payload")
//       state[action.payload.pageIndex].shapes[action.payload.elementIndex].width = action.payload.width
//       state[action.payload.pageIndex].shapes[action.payload.elementIndex].height = action.payload.height
//       return state;

//     // case "ROTATE_SHAPE":
//     //   console.log("Original width and height", state[action.payload.pageIndex].shapes[action.payload.elementIndex])
//     //   console.log(action.payload.transform)

//     //   const uniformScale = action.payload.transform?.split("(")[1]?.split(")")[0];
//     //   console.log("Scale", uniformScale)

//     //   let arr0 = (action.payload.transform?.split(', ')[0]);
//     //   let arr1 = (action.payload.transform?.split(', ')[1]);
//     //   console.log("TRANSFORM___>", typeof (arr0?.split('(')[1]), arr0?.split('(')[1]);
//     //   console.log("TRANSFORM___>", arr1?.split(')')[0]);
//     //   let w = Number(state[action.payload.pageIndex].shapes[action.payload.elementIndex].width?.split('p')[0]);
//     //   let h = Number(state[action.payload.pageIndex].shapes[action.payload.elementIndex].height?.split('p')[0]);

//     //   if(arr0 && arr1){
//     //     state[action.payload.pageIndex].shapes[action.payload.elementIndex].width = String(w * Number(arr0?.split('(')[1])) + "px";
//     //     state[action.payload.pageIndex].shapes[action.payload.elementIndex].height = String(h * Number(arr1?.split(')')[0])) + "px";
//     //   }
//     //   else{
//     //     state[action.payload.pageIndex].shapes[action.payload.elementIndex].width = String(w * Number(uniformScale)) + "px";
//     //     state[action.payload.pageIndex].shapes[action.payload.elementIndex].height = String(h * Number(uniformScale)) + "px";
//     //   }
//     //   console.log("Changed width and height", state[action.payload.pageIndex].shapes[action.payload.elementIndex])

//     //   state[action.payload.pageIndex].shapes[action.payload.elementIndex].aspectRatio = Number(state[action.payload.pageIndex].shapes[action.payload.elementIndex].width?.split('p')[0]) / Number(state[action.payload.pageIndex].shapes[action.payload.elementIndex].height?.split('p')[0])
//     //   state[action.payload.pageIndex].shapes[action.payload.elementIndex].x = action.payload.x
//     //   state[action.payload.pageIndex].shapes[action.payload.elementIndex].y = action.payload.y
//     //   // state[action.payload.pageIndex].shapes[
//     //   //   action.payload.elementIndex
//     //   // ].transform = action.payload.transform;
//     //   return state;

//     case "SET_SHAPE_POSITION":
//       state[action.payload.pageIndex].shapes[action.payload.elementIndex].x =
//         action.payload.x;
//       state[action.payload.pageIndex].shapes[action.payload.elementIndex].y =
//         action.payload.y;
//       return state;

//     case "RESIZE_TEXT":
//       state[action.payload.pageIndex].texts[action.payload.elementIndex].width =
//         action.payload.width;
//       state[action.payload.pageIndex].texts[
//         action.payload.elementIndex
//       ].height = action.payload.height;
//       return state;

//     case "ROTATE_TEXT":
//       state[action.payload.pageIndex].texts[
//         action.payload.elementIndex
//       ].transform = action.payload.transform;
//       return state;

//     case "SET_TEXT_POSITION":
//       const newTexts = state[action.payload.pageIndex].texts.map((text,idx)=> idx === action.payload.elementIndex ? {
//         ...text,
//         x: action.payload.x,
//         y: action.payload.y
//       } : text);
//       state[action.payload.pageIndex].texts = newTexts;
//       return state;

//     case "EDIT_FONT_OPACITY": 
//       state[action.payload.pageIndex].texts[action.payload.elementIndex].opacity = action.payload.opacity;
//       return state;

//     case "EDIT_FONT_BOLD":
//       state[action.payload.pageIndex].texts[
//         action.payload.elementIndex
//       ].isBold = action.payload.bold;
//       return state;

//     case "EDIT_FONT_ITALICS":
//       state[action.payload.pageIndex].texts[
//         action.payload.elementIndex
//       ].isItalic = action.payload.italics;
//       return state;

//     case "EDIT_FONT_UNDERLINE":
//       state[action.payload.pageIndex].texts[
//         action.payload.elementIndex
//       ].underline = action.payload.underline;
//       return state;

//     case "EDIT_SKEWED_TEXT":
//       state[action.payload.pageIndex].texts[
//         action.payload.elementIndex
//       ].isSkewed = action.payload.skewed;
//       return state;

//     case "EDIT_FONT_FAMILY":
//       state[action.payload.pageIndex].texts[
//         action.payload.elementIndex
//       ].family = action.payload.family;
//       return state;

//     case "EDIT_FONT_SIZE":
//       state[action.payload.pageIndex].texts[
//         action.payload.elementIndex
//       ].fontSize = action.payload.fontSize;
//       return state;

//     case "EDIT_FONT_COLOR":
//       state[action.payload.pageIndex].texts[action.payload.elementIndex].color =
//         action.payload.color;
//       return state;
    
//     case "EDIT_LINE_HEIGHT":
//         state[action.payload.pageIndex].texts[action.payload.elementIndex].lineHeight =
//           action.payload.lineHeight;
//         return state;
    
//     case "EDIT_LETTER_SPACING":
//       state[action.payload.pageIndex].texts[action.payload.elementIndex].letterSpacing =
//         action.payload.letterSpacing;
//       return state;

//     case "EDIT_FONT_ALIGNMENT":
//       state[action.payload.pageIndex].texts[action.payload.elementIndex].align =
//         action.payload.align;
//       return state;

//     case "EDIT_SHAPE_BGCOLOR":
//       state[action.payload.pageIndex].shapes[
//         action.payload.elementIndex
//       ].background = action.payload.color;
//       return state;
//     case "EDIT_SHAPE_BORDERCOLOR":
//       state[action.payload.pageIndex].shapes[
//         action.payload.elementIndex
//       ].borderColor = action.payload.borderColor;
//       return state;
//     case "EDIT_SHAPE_ZINDEX":
//       state[action.payload.pageIndex].shapes[
//         action.payload.elementIndex
//       ].zIndex = action.payload.index;
//       return state;

//     case "EDIT_SHAPE_CURVE":
//       state[action.payload.pageIndex].shapes[
//         action.payload.elementIndex
//       ].curve = action.payload.index;
//       return state;

//     case "EDIT_SHAPE_BORDER":
//       state[action.payload.pageIndex].shapes[
//         action.payload.elementIndex
//       ].border = action.payload.index;
//       // Setting Border Color to black by default
//       state[action.payload.pageIndex].shapes[
//         action.payload.elementIndex
//       ].borderColor = "#000000";

//       return state;
//     case "EDIT_IMAGES_ZINDEX":
//       state[action.payload.pageIndex].images[
//         action.payload.elementIndex
//       ].zIndex = action.payload.index;
//       return state;
//     case "EDIT_LOGOS_ZINDEX":
//       state[action.payload.pageIndex].logos[
//         action.payload.elementIndex
//       ].zIndex = action.payload.index;
//       return state;
//     case "EDIT_ONLINE_ELEMENT_ZINDEX":
//       state[action.payload.pageIndex].onlineElements[
//         action.payload.elementIndex
//       ].zIndex = action.payload.index;
//       return state;

//     case "DELETE_PAGE":
//       return [...action.payload.page];

//     default:
//       return state;
//   }
// };

// export default handlePage;




// const pageEmpty=[{
//     template:null,
//     svgs:[],
//     logos:[],
//     texts:[],
//     textTemplate:[],
//     backgroundColor:"white",
//     comment:"",
//     notes:"",
//     title:"",
//     frame:{

import { act } from "react";

//         frameNumber:0
//     }
// }]
// const initialState = [];

// const handlePage = (state = initialState, action) => {
//     // console.log(state.cart);

//     let page =null;
//     switch (action.type) {
//         case 'ADD_PAGE':return [...action.payload.page];

//         case 'EMPTY_PAGE' :

//             return state.slice(0, action.payload.pageIndex).concat([pageEmpty]).concat(state.slice(action.payload.pageIndex+1));
//          case "UPDATE_PAGE":
//             return state.slice(0, action.payload.pageIndex).concat([action.payload.pageUpdated]).concat(state.slice(action.payload.pageIndex+1));

//         case 'GET_TEMPLATE':

//         // console.log(state,"------");

//             page ={...state[action.payload.pageIndex], template:action.payload.template}
//             const obj= state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));
//         //    console.log(obj,"=====");
//            return obj
//         case 'UPDATE_TEMPLATE_COLOR':
//                 return  state.slice(0, action.payload.pageIndex).concat([action.payload.page]).concat(state.slice(action.payload.pageIndex+1));
//         case 'GET_SVGS' :
//             page = {
//                 ...state[action.payload.pageIndex], svgs:[...state[action.payload.pageIndex][0].svgs, ...action.payload.Svg]
//             }
//             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

//         case 'GET_LOGOS':
//             page =  {
//                 ...state[action.payload.pageIndex][0],
//                 logos:[...state[action.payload.pageIndex][0].logos,...action.payload.logo]
//             }
//             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

//         case 'SET_LOGOS':
//             page =   {
//                 ...state[action.payload.pageIndex], logos:action.payload.logo
//             }
//             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

//         case 'GET_TEXTS':
//             page =  {
//                 ...state[action.payload.pageIndex][0], texts:[...state[action.payload.pageIndex][0].texts ,...action.payload.text]
//             }
//             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

//         case 'UPDATE_TEXTS':
//                page =   {
//                     ...state[action.payload.pageIndex], texts:[...action.payload.textArr]
//                 }
//                 console.log(page,"#################################");
//                // return [...state]
//                 return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

//         case 'GET_TEXT_TEMPLATE':
//             page = {
//                 ...state[action.payload.pageIndex], textTemplate:[...state[action.payload.pageIndex][0].textTemplate, ...action.payload.textTemplate]
//             }
//             return  state.slice(0, action.payload.pageIndex).concat([page]).concat(state.slice(action.payload.pageIndex+1));

//         default: return state
//     }
// }
// export default handlePage;

// =======================================================================================================

const pageEmpty = {
  // template: null,
  svgs: [],
  logos: [],
  texts: [],
  // textTemplate: [],
  shapes: [],
  backgroundColor: "#FFFFFF",
  comment: "",
  notes: "",
  title: "Untitled Project",
  // frame: {
  //   frameNumber: 0,
  // },
  shapeCanvas: "6x6",
   canvasDimension:"530x455"
};

const initialState = [

];
const textBox = { pageIndex: "", index: "" };

const handlePage = (state = initialState, action) => {
  // console.log(state.cart);

  let page = null;
  let tB = null;

  switch (action.type) {
    case "ADD_PAGE":
      return [...action.payload.page];

    case "CREATE_EMPTY_PAGE":
      return [...action.payload.page, { ...pageEmpty }];

    case "CREATE_MOCkUP_PAGE":
      return [...action.payload.page, action.payload.mockup];

    case "EMPTY_PAGE":
      return state
        .slice(0, action.payload.pageIndex)
        .concat([pageEmpty])
        .concat(state.slice(action.payload.pageIndex + 1));

    case "UPDATE_PAGE":
      return state
        .slice(0, action.payload.pageIndex)
        .concat([action.payload.pageUpdated])
        .concat(state.slice(action.payload.pageIndex + 1));

    case "SET_PAGES":
      return [...action.payload.page];

    case "REPLACE_TEMP":
      return [...action.payload.page];

    case "GET_TEMPLATE":
      page = {
        ...state[action.payload.pageIndex],
        template: action.payload.template,
      };
      const obj = state
        .slice(0, action.payload.pageIndex)
        .concat([page])
        .concat(state.slice(action.payload.pageIndex + 1));
      return obj;

    case "UPDATE_TEMPLATE_COLOR":
      return state
        .slice(0, action.payload.pageIndex)
        .concat(action.payload.page)
        .concat(state.slice(action.payload.pageIndex + 1));

    case "GET_SVGS":
      page = {
        ...state[action.payload.pageIndex],
        svgs: [
          ...state[action.payload.pageIndex][0].svgs,
          ...action.payload.Svg,
        ],
      };
      return state
        .slice(0, action.payload.pageIndex)
        .concat([page])
        .concat(state.slice(action.payload.pageIndex + 1));

    case "CHANGE_TEMPLATE":
      // console.log("inslice"  ,state.slice(0,action.payload.pageIndex).concat(action.payload.pageUpdated))
      return state
        .slice(0, action.payload.pageIndex)
        .concat(action.payload.pageUpdated)
        .concat(state.slice(action.payload.pageIndex + 1));

    case "GET_LOGOS":
      page = {
        ...state[action.payload.pageIndex][0],
        logos: [
          ...state[action.payload.pageIndex][0].logos,
          ...action.payload.logo,
        ],
      };
      return state
        .slice(0, action.payload.pageIndex)
        .concat(page)
        .concat(state.slice(action.payload.pageIndex + 1));

    case "ADD_LOGO":
      const currPageIdx = action.payload.pageIndex;
      page = {
        ...state[currPageIdx],
        logos: [
          ...(Array.isArray(state[currPageIdx]?.logos)
            ? state[currPageIdx].logos
            : []),
          action.payload.logo,
        ],
      };
      state[currPageIdx].logos = page.logos;
      return state;

    case "ADD_TEXT":
      const currPage = action.payload.pageIndex;
      page = {
        ...state[currPage],
        texts: [
          ...(Array.isArray(state[currPage].texts)
            ? state[currPage].texts
            : []),
          ...action.payload.text,
        ],
      };
      state[currPage].texts = page.texts;
      return state;

    case "UPDATE_TEXTS":
      page = {
        ...state[action.payload.pageIndex],
        texts: [...action.payload.textArr],
      };

      return state
        .slice(0, action.payload.pageIndex)
        .concat([page])
        .concat(state.slice(action.payload.pageIndex + 1));

    case "GET_TEXT_TEMPLATE":
      page = {
        ...state[action.payload.pageIndex],
        textTemplate: [
          ...state[action.payload.pageIndex][0].textTemplate,
          ...action.payload.textTemplate,
        ],
      };
      return state
        .slice(0, action.payload.pageIndex)
        .concat([page])
        .concat(state.slice(action.payload.pageIndex + 1));

    case "UPDATE_PAGE_SHAPE":
      return (state[action.payload.pageIndex].shapeCanvas =
        action.payload.shape);

    case "SET_TEXTBOX":
      textBox.pageIndex = action.payload.pageIndex;
      textBox.index = action.payload.textboxIndex;
      return state;

    case "EDIT_TEXT":
      tB = {
        ...state[action.payload.activePage].texts[action.payload.activeElement],
        fontSize: action.payload.fontSize,
        fontWeight: action.payload.fontWeight,
        family: action.payload.fontFamily,
      };
      let p = {
        ...state[action.payload.activePage],
        texts: state[action.payload.activePage].texts
          .slice(0, action.payload.activeElement)
          .concat(tB)
          .concat(
            state[action.payload.activePage].texts.slice(
              action.payload.activeElement + 1
            )
          ),
      };

      return state
        .slice(0, textBox.pageIndex)
        .concat(p)
        .concat(state.slice(textBox.pageIndex + 1));

    // case "ROTATE_TEXT":
    // 	state[action.payload.pageIndex].texts[
    // 		action.payload.Eindex
    // 	].rotate = action.payload.rotate;
    // 	return state;

    case "UPLOAD_IMG":
      // console.log("STATE--->", state[action.payload.pageIndex])
      state[action.payload.pageIndex].images
        ? (page = {
          ...state[action.payload.pageIndex],
          images: [
            ...state[action.payload.pageIndex].images,
            {
              file: action.payload.file,
              height: 100 * action.payload.aspectratio,
              width: 100,
              x: "50px",
              y: "50px",
              rotate: 0,
              zIndex: 0,
            },
          ],
        })
        : (page = {
          ...state[action.payload.pageIndex],
          images: [
            {
              file: action.payload.file,
              height: 100 * action.payload.aspectratio,
              width: 100,
              x: "50px",
              y: "50px",
              rotate: 0,
              zIndex: 0,
            },
          ],
        });
      return state
        .slice(0, action.payload.pageIndex)
        .concat(page)
        .concat(state.slice(action.payload.pageIndex + 1));

    case "UPLOAD_MOCKIMG":
      state[action.payload.pageIndex].images
        ? (page = {
          ...state[action.payload.pageIndex],
          images: [
            ...state[action.payload.pageIndex].images,
            {
              file: action.payload.file,
              height: action.payload.h,
              width: action.payload.w,
              x: "152px",
              y: "35px",
              rotate: 0,
              zIndex: 2,
            },
          ],
        })
        : (page = {
          ...state[action.payload.pageIndex],
          images: [
            {
              file: action.payload.file,
              height: action.payload.h,
              width: action.payload.w,
              x: "152px",
              y: "35px",
              rotate: 0,
              zIndex: 2,
            },
          ],
        });
      return state
        .slice(0, action.payload.pageIndex)
        .concat(page)
        .concat(state.slice(action.payload.pageIndex + 1));

    case "DELETE_IMG":
      state[action.payload.pageIndex].images.length > 1
        ? (page = {
          ...state[action.payload.pageIndex],
          images: state[action.payload.pageIndex].images.filter(
            (item, Eindex) => Eindex !== action.payload.index
          ),
        })
        : (page = {
          ...state[action.payload.pageIndex],
          images: [],
        });
      return state
        .slice(0, action.payload.pageIndex)
        .concat(page)
        .concat(state.slice(action.payload.pageIndex + 1));

    case "DELETE_SHAPE":
      let pageIndex = action.payload.pageIndex;
      let shapeIndex = action.payload.index;

      if (state[pageIndex] && Array.isArray(state[pageIndex].shapes)) {
        console.log("Original shapes array:", state[pageIndex].shapes);
        console.log("Shape index to filter out:", shapeIndex);

        // const newShapes = state[pageIndex].shapes.filter((item, index) => {
        //   console.log("INDEX: ", index)
        //   return (index !== shapeIndex)
        // });
        state[pageIndex].shapes.splice(shapeIndex, 1)
        console.log("Filtered shapes array:", state[pageIndex].shapes);
      }

      state[action.payload.pageIndex].shapes.length > 1
        ? (page = {
          ...state[pageIndex],
          shapes: state[pageIndex].shapes,
        })
        : (page = {
          ...state[pageIndex],
          shapes: state[pageIndex].shapes,
        });
      return state
        .slice(0, action.payload.pageIndex)
        .concat(page)
        .concat(state.slice(action.payload.pageIndex + 1));

    case "DELETE_TEXT":
      state[action.payload.pageIndex].texts.length > 1
        ? (page = {
          ...state[action.payload.pageIndex],
          texts: state[action.payload.pageIndex].texts.filter(
            (item, Eindex) => Eindex !== action.payload.index
          ),
        })
        : (page = {
          ...state[action.payload.pageIndex],
          texts: [],
        });
      return state
        .slice(0, action.payload.pageIndex)
        .concat(page)
        .concat(state.slice(action.payload.pageIndex + 1));

    case "ROTATE_IMG":
      if (action.payload.element === "image") {
        state[action.payload.pageIndex].images[action.payload.Eindex].rotate =
          action.payload.rotate;
      } else if (action.payload.element === "logos") {
        state[action.payload.pageIndex].logos[action.payload.Eindex].rotate =
          action.payload.rotate;
      }
      // else {
      //   state[action.payload.pageIndex].onlineElements[
      //     action.payload.Eindex
      //   ].rotate = action.payload.rotate;
      // }
      return state;
    // case "ROTATE_SHAPE":
    // 	state[action.payload.pageIndex].shapes[
    // 		action.payload.Eindex
    // 	].rotate = action.payload.rotate;
    // 	return state;

    // case "ROTATE_IMAGE":
    //   state[action.payload.pageIndex].images[action.payload.elementIndex].transform = action.payload.transform;
    //   return state;

    // case "ROTATE_LOGO":
    //   state[action.payload.pageIndex].logos[action.payload.elementIndex].transform = action.payload.transform;
    //   return state;
    case "ROTATE_IMAGE":
      // state[action.payload.pageIndex].images[action.payload.elementIndex].width = action.payload.width
      // state[action.payload.pageIndex].images[action.payload.elementIndex].height = action.payload.height
      state[action.payload.pageIndex].images[action.payload.elementIndex].transform = action.payload.transform
      console.log("Changed width and height", state[action.payload.pageIndex].images[action.payload.elementIndex])

      // state[action.payload.pageIndex].images[action.payload.elementIndex].aspectRatio = Number(state[action.payload.pageIndex].images[action.payload.elementIndex].width?.split('p')[0]) / Number(state[action.payload.pageIndex].images[action.payload.elementIndex].height?.split('p')[0])
      // state[action.payload.pageIndex].images[action.payload.elementIndex].zIndex = action.payload.zIndex;
      return state;

    case "ROTATE_LOGO":
      // state[action.payload.pageIndex].images[action.payload.elementIndex].width = action.payload.width
      // state[action.payload.pageIndex].images[action.payload.elementIndex].height = action.payload.height
      state[action.payload.pageIndex].images[action.payload.elementIndex].transform = action.payload.transform
      console.log("Changed width and height", state[action.payload.pageIndex].images[action.payload.elementIndex])

      // state[action.payload.pageIndex].images[action.payload.elementIndex].aspectRatio = Number(state[action.payload.pageIndex].images[action.payload.elementIndex].width?.split('p')[0]) / Number(state[action.payload.pageIndex].images[action.payload.elementIndex].height?.split('p')[0])
      return state;



    case "SET_LOGO_POSITION":
      state[action.payload.pageIndex].logos[action.payload.elementIndex].x =
        action.payload.x;
      state[action.payload.pageIndex].logos[action.payload.elementIndex].y =
        action.payload.y;
      return state;

    case "SET_IMAGE_POSITION":
      state[action.payload.pageIndex].images[action.payload.elementIndex].x =
        action.payload.x;
      state[action.payload.pageIndex].images[action.payload.elementIndex].y =
        action.payload.y;
      return state;

    case "ROTATE_SHAPE":
      console.log("Original width and height", state[action.payload.pageIndex].shapes[action.payload.elementIndex])
      console.log(action.payload.width)
      console.log(action.payload.height)

  
      state[action.payload.pageIndex].shapes[action.payload.elementIndex].width = action.payload.width
      state[action.payload.pageIndex].shapes[action.payload.elementIndex].height = action.payload.height
      state[action.payload.pageIndex].shapes[action.payload.elementIndex].transform = action.payload.transform;
      console.log("Changed width and height", state[action.payload.pageIndex].shapes[action.payload.elementIndex])

      state[action.payload.pageIndex].shapes[action.payload.elementIndex].aspectRatio = Number(state[action.payload.pageIndex].shapes[action.payload.elementIndex].width?.split('p')[0]) / Number(state[action.payload.pageIndex].shapes[action.payload.elementIndex].height?.split('p')[0])
      // state[action.payload.pageIndex].shapes[action.payload.elementIndex].x = action.payload.x
      // state[action.payload.pageIndex].shapes[action.payload.elementIndex].y = action.payload.y
      return state;

      case "SET_ROTATED_ANGLE":
      console.log("Angle", action.payload.angle);
      state[action.payload.pageIndex].shapes[action.payload.elementIndex].transform = `rotate(${action.payload.angle}deg)`;
      return state;

    case "SET_SHAPE_POSITION":
      console.log("PAYLOAD--->", action.payload)
      state[action.payload.pageIndex].shapes[action.payload.elementIndex].x =
        action.payload.x;
      state[action.payload.pageIndex].shapes[action.payload.elementIndex].y =
        action.payload.y;
      return state;

    case "RESIZE_TEXT":
      state[action.payload.pageIndex].texts[action.payload.elementIndex].width =
        action.payload.width;
      state[action.payload.pageIndex].texts[
        action.payload.elementIndex
      ].height = action.payload.height;
      return state;

    case "ROTATE_TEXT":
      state[action.payload.pageIndex].texts[
        action.payload.elementIndex
      ].transform = action.payload.transform;
      return state;

    case "SET_TEXT_POSITION":
      const newTexts = state[action.payload.pageIndex].texts.map((text, idx) => idx === action.payload.elementIndex ? {
        ...text,
        x: action.payload.x,
        y: action.payload.y
      } : text);
      state[action.payload.pageIndex].texts = newTexts;
      return state;

    case "EDIT_FONT_OPACITY":
      state[action.payload.pageIndex].texts[action.payload.elementIndex].opacity = action.payload.opacity;
      return state;

    case "EDIT_FONT_BOLD":
      state[action.payload.pageIndex].texts[
        action.payload.elementIndex
      ].isBold = action.payload.bold;
      return state;

    case "EDIT_FONT_ITALICS":
      state[action.payload.pageIndex].texts[
        action.payload.elementIndex
      ].isItalic = action.payload.italics;
      return state;

    case "EDIT_FONT_UNDERLINE":
      state[action.payload.pageIndex].texts[
        action.payload.elementIndex
      ].underline = action.payload.underline;
      return state;

    case "EDIT_SKEWED_TEXT":
      state[action.payload.pageIndex].texts[
        action.payload.elementIndex
      ].isSkewed = action.payload.skewed;
      return state;

    case "EDIT_FONT_FAMILY":
      state[action.payload.pageIndex].texts[
        action.payload.elementIndex
      ].family = action.payload.family;
      return state;

    case "EDIT_FONT_SIZE":
      state[action.payload.pageIndex].texts[
        action.payload.elementIndex
      ].fontSize = action.payload.fontSize;
      return state;

    case "EDIT_FONT_COLOR":
      state[action.payload.pageIndex].texts[action.payload.elementIndex].color =
        action.payload.color;
      return state;

    case "EDIT_LINE_HEIGHT":
      state[action.payload.pageIndex].texts[action.payload.elementIndex].lineHeight =
        action.payload.lineHeight;
      return state;

    case "EDIT_LETTER_SPACING":
      state[action.payload.pageIndex].texts[action.payload.elementIndex].letterSpacing =
        action.payload.letterSpacing;
      return state;

    case "EDIT_FONT_ALIGNMENT":
      state[action.payload.pageIndex].texts[action.payload.elementIndex].align =
        action.payload.align;
      return state;

    case "EDIT_SHAPE_BGCOLOR":
      state[action.payload.pageIndex].shapes[
        action.payload.elementIndex
      ].background = action.payload.color;
      return state;
    case "EDIT_SHAPE_BORDERCOLOR":
      state[action.payload.pageIndex].shapes[
        action.payload.elementIndex
      ].borderColor = action.payload.borderColor;
      return state;
    case "EDIT_SHAPE_ZINDEX":
      state[action.payload.pageIndex].shapes[
        action.payload.elementIndex
      ].zIndex = action.payload.index;
      return state;
    case "EDIT_SHAPE_WIDTH":
      state[action.payload.pageIndex].shapes[
        action.payload.elementIndex
      ].width = action.payload.width + "px";
      return state;
    case "EDIT_SHAPE_HEIGHT":
      state[action.payload.pageIndex].shapes[
        action.payload.elementIndex
      ].height = action.payload.height + "px";
      return state;

    case "EDIT_SHAPE_CURVE":
      let right = state[action.payload.pageIndex].shapes[action.payload.elementIndex].y
      console.log("HEIGHT", action.payload.right)
      state[action.payload.pageIndex].shapes[action.payload.elementIndex].curve = action.payload.index;
      // state[action.payload.pageIndex].shapes[action.payload.elementIndex].height = height
      return state;

    case "EDIT_SHAPE_BORDER":
      state[action.payload.pageIndex].shapes[
        action.payload.elementIndex
      ].border = action.payload.index;
      // Setting Border Color to black by default
      state[action.payload.pageIndex].shapes[
        action.payload.elementIndex
      ].borderColor = "#000000";

      return state;

      case "EDIT_IMAGE_WIDTH":
        state[action.payload.pageIndex].images[
          action.payload.elementIndex
        ].width = action.payload.width + "px";
        return state;
      case "EDIT_IMAGE_HEIGHT":
        state[action.payload.pageIndex].images[
          action.payload.elementIndex
        ].height = action.payload.height + "px";
        return state;


    case "EDIT_IMAGES_ZINDEX":
      state[action.payload.pageIndex].images[
        action.payload.elementIndex
      ].zIndex = action.payload.index;
      return state;
    case "EDIT_LOGOS_ZINDEX":
      state[action.payload.pageIndex].logos[
        action.payload.elementIndex
      ].zIndex = action.payload.index;
      return state;
    case "EDIT_ONLINE_ELEMENT_ZINDEX":
      state[action.payload.pageIndex].onlineElements[
        action.payload.elementIndex
      ].zIndex = action.payload.index;
      return state;

    case "DELETE_PAGE":
      return [...action.payload.page];

    default:
      return state;
  }
};

export default handlePage;
