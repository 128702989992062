import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../../backend";

const StudioDetails = () => {
  const dispatch = useDispatch();
  const studios = useSelector((state) => state.user.studio);
  const userDetails = useSelector((state) => state.user.userDetail);
  const getState = useSelector((state) => state);

  console.log("userDetails=======>", userDetails);
  const [fetchedData, setFetchedData] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

   

  const handleItemClick = (index) => {
    setActiveItem(index);
    const selectedStudioUiid = fetchedData[index].studio[0].uiid;
    localStorage.setItem("activeStudioUiid", selectedStudioUiid);
    const selectedStudioName = fetchedData[index].studio[0].studioName;
    localStorage.setItem("activeStudioName", selectedStudioName);
  };

  useEffect(() => {
    const getUserStudios = async () => {
      setIsLoading(true);
      const myuser = JSON.parse(localStorage.getItem("myuser"));
      try {
        const response = await fetch(`${API}auth/get_user_studios`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `${myuser.token}`,
            
          },
          
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "Failed to fetch user studios");
        }
        setFetchedData(data);
      } catch (error) {
        console.error("Error fetching user studios:", error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    if (userDetails && userDetails.emailid) {
      getUserStudios();
    }
  }, [dispatch, userDetails]);

  useEffect(() => {
    const activeStudioUiid = localStorage.getItem("activeStudioUiid");
    if (activeStudioUiid && fetchedData) {
      const index = fetchedData.findIndex(
        (item) => item.studio[0].uiid === activeStudioUiid
      );
      setActiveItem(index);
    }
  }, [fetchedData]);

  return (
    <div className="mx-auto my-8 max-w-screen-lg bg-[#f1f5f9] p-6 rounded shadow-md">
      <h1 className="text-3xl mb-6 font-bold text-teal-500">Studio Details</h1>

      <div className="mb-8">
        <div className="mb-8">
          {studios && studios.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
              {studios.map((studio) => (
                <div
                  key={studio._id}
                  className={`bg-white p-2 rounded shadow-md cursor-pointer`}
                >
                  <div className="h-16 bg-[#e2e8f0] rounded mb-2 flex justify-center items-center">
                    <span className="text-lg">{studio.studioName}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-lg mb-6 text-center">
              You haven't created any studio.
            </div>
          )}
        </div>
      </div>

      <hr className="my-8 border-t border-gray-300" />

      {isLoading && <div className="text-center text-xl mb-4">Loading...</div>}

      {fetchedData && (
        <div className="text-center">
          <span className="block text-xl mb-4">Studios You Belong To</span>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            {fetchedData?.map((item, index) => {
              const userEmail = userDetails.emailid;
              const user = item.studio[0].people.find(
                (person) => person.email === userEmail
              );

              return (
                <div
                  key={item._id}
                  className={`bg-white p-2 rounded shadow-md cursor-pointer ${activeItem === index ? "border-2 border-blue-500" : ""
                    }`}
                  onClick={() => handleItemClick(index)}
                >
                  <div className="h-16 bg-[#e2e8f0] rounded mb-2 flex justify-center items-center">
                    <span className="text-lg">{item.studio[0].studioName}</span>
                  </div>
                  {user && (
                    <div className="text-center">
                      <span className="text-sm capitalize">{user.role}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {localStorage.getItem("activeStudioName") ? (
            <span className="text-center">
              You are working on {localStorage.getItem("activeStudioName")}{" "}
            </span>
          ) : (
            <span className="text-center">Studio Not Selected</span>
          )}
        </div>
      )}
    </div>
  );
};

export default StudioDetails;
