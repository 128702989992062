import { Avatar, Checkbox } from '@mui/joy'
import React from 'react'

export default function DesignCard({t,idx,addDesign,removeDesign,designArray}) {
  return (
    <div
    onClick={()=>{
      if (!designArray?.find((id)=> id === t?._id)) {
      addDesign(t?._id)
      }
      else
      removeDesign(t?._id)
    }}
    className='h-[270px] border-[1px] px-4 pt-4 pb-10 flex flex-col gap-2 shadow-sm hover:translate-y-[-4px] cursor-pointer transition-all duration-150 shadow-zinc-300  border-indigo-100 rounded-xl'>
            <Avatar
            src={t?.imgUrl}
            sx={{
                width:"100%",
                height:"100%",
                borderRadius:5,
                bgcolor:"white"
            }} >
              {t?.title}
            </Avatar>
            <Checkbox
            checked={designArray?.find((id)=> id === t?._id)?true:false}
              label={t?.title}
            //   overlay
              color='primary'
            //   checked={members[2]}
            //   onChange={toggleMember(2)}
            />
    </div>
  )
}
