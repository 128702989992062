import { combineReducers } from "redux";
import handlePage from "./handlePage";
import handleConfiguration from "./handleConfiguration";
import handleCommons from "./handleCommons";
import handleFilter from "./handlerFilter";
import sideSlider from "./openSlider";
import editor from "./editorReducer";
import CreateNew from "./Createnew";
import ScaleReducer from "./ScaleReducer";
import mockupPage from "./handleMockups";
import MockupScaleReducer from "../actions/mockupScaleAction";
import handleClipboard from "./clipboardReducer";
import handleHistory from "./handleHistory";
import handlePageName from "./handlePageName";

const handleDIY = combineReducers({
	pages: handlePage,
	pageName: handlePageName,
	currentPage: handleConfiguration,
	commons: handleCommons,
	filter: handleFilter,
	sideSlider: sideSlider,
	editor: editor,
	creatnew: CreateNew,
	scale: ScaleReducer,
	mockupPages: mockupPage,
	mockupScale: MockupScaleReducer,
	clipboard: handleClipboard,
	history: handleHistory
});

export default handleDIY;
