const initialData = {

    newText: 'en',
    currentText: '',
    keyboardVisibility: false,
    showTransformation: false,
    skewedText: false,
    tiltedText: false,
    waveText: false
}

const handleLanguage = (state = initialData, action) => {
    switch (action.type) {
        case "CHANGE_LANG":
            return {
                ...state,
                newText: action.payload
            }

        case "CHANGE_TEXT":
            return {
                ...state,
                currentText: action.payload
            }

        case "KEYBOARD_VISIBILITY":
            return {
                ...state,
                keyboardVisibility: action.payload
            }

        case "TRANSFORMATION_VISIBILITY":
            return {
                ...state,
                showTransformation: action.payload
            }
        case "SKEWED_TEXT":
            return {
                ...state,
                skewedText: action.payload
            }
        case "TILTED_TEXT":
            return {
                ...state,
                tiltedText: action.payload
            }
        case "WAVE_TEXT":
            return {
                ...state,
                waveText: action.payload
            }

        default: return state
    }
}
export default handleLanguage