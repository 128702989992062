const initialData = {
    form:[
    ],
    currentFormData:null
}

const handleForm=(state=initialData, action)=>{
    switch(action.type){
        case "CREATE_FORM":
            return{
                ...state,
                form:[
                    ...state?.form,
                    action.payload  
                ],
            }

            case "FETCH_ALL_FORM":
                return{
                    form: action.payload
                    ,
                }

            case "UPDATE_FORM":
               
            return{
                ...state,
                form:[
                    ...state?.form, action.payload
                ],
            }

                  case "DELETE_FORM":
                return{
                   form: state.form.filter((item)=>item._id !== action.payload)
                }
                // case "SEARCH_TERM":
                //     return{
                //         ...state,
                //        searchTerm:state.searchTerm = action.payload
                //     }
                case "SELECTED_FORM":
                return{
                   ...state,
                   currentFormData:action.payload
                }

            default:return state
    }
}
export default handleForm