
import { Checkbox } from '@mui/joy'
import React, { useState } from 'react'


export default function FilterCategoryChip({setSortBytime,selectedCategories,setSelectedMaterial,setSelectedCategories,categories,setCategories}) {

  const [isSelcted,setIsSelcted] = useState(false)
    return (
      <div
      onClick={()=>setIsSelcted(!isSelcted)}
      className=' relative flex gap-1 cursor-pointer w-[180px] justify-between my-auto items-center border-[1px] border-zinc-300 px-3 py-1.5 rounded-md'>
       <div className=' text-zinc-500'>Category</div>
       <div className=' flex gap-1 items-center'>
       <div className=' rounded-full w-[20px] text-sm flex justify-center items-center h-[20px] bg-indigo-100 p-1'>{selectedCategories?.length || 0}</div>
       <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.18179 6.18181C4.35753 6.00608 4.64245 6.00608 4.81819 6.18181L7.49999 8.86362L10.1818 6.18181C10.3575 6.00608 10.6424 6.00608 10.8182 6.18181C10.9939 6.35755 10.9939 6.64247 10.8182 6.81821L7.81819 9.81821C7.73379 9.9026 7.61934 9.95001 7.49999 9.95001C7.38064 9.95001 7.26618 9.9026 7.18179 9.81821L4.18179 6.81821C4.00605 6.64247 4.00605 6.35755 4.18179 6.18181Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
       </div>
             {/* checkbox ui */}
      {
        isSelcted &&
        <div className=' bg-white absolute left-0 bottom-[-155px]  z-[50] flex flex-col gap-2 w-[180px] p-2 border-[1px] border-zinc-300 shadow-md shadow-zinc-300 rounded-md'>
            {
                categories?.map((data,id)=>(
                    <div

                    className='flex gap-1 items-center'>
                        <Checkbox
                        
                        checked={selectedCategories?.find((c)=>c?.title === data?.title)?true:false}
                        onClick={()=>{
                          setSelectedMaterial(null)
                          setSortBytime(null)
                        if (!data?.checked) {
                         setSelectedCategories([...selectedCategories,data])  
                         let updatedCheckedData = [...categories]   
                         updatedCheckedData[id].checked = true
                         setCategories(updatedCheckedData)                     
                        }
                        else{
                         const updatedData = selectedCategories.filter((item,idx)=>item?.title !== data?.title)
                         setSelectedCategories(updatedData)
                         let updatedCheckedData = [...categories]   
                         updatedCheckedData[id].checked = false
                         setCategories(updatedCheckedData)  
                        }
                        }}
                        label={data?.title} color="primary"/>
                        </div>
                ))
            }
        </div>
      }
      </div>
    
  )
}
