import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faTrash } from '@fortawesome/free-solid-svg-icons';
import { API } from "../../../../../backend";
import { useSelector } from "react-redux";
const ApproverRedirecter = () => {

  const getState = useSelector((state) => state);

   

  const saveComments = async (comments, uiid, getState) => {
    try {
      const response = await fetch(`${API}auth/save_comments`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': JSON.parse(localStorage.getItem("myuser")).token,
         
        },
       
        body: JSON.stringify({ comments: comments, uiid: uiid })
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error saving comments:', error);
    }
  };

  const [comments, setComments] = useState(JSON.parse(localStorage.getItem("comments")) || []);
  const [newComment, setNewComment] = useState("");
  const [designArray, setDesignArray] = useState([]);

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = () => {
    if (newComment.trim() !== "") {
      const updatedComments = [...comments, newComment];
      setComments(updatedComments);
      saveComments(updatedComments, localStorage.getItem("activeStudioUiid"));
      setNewComment("");
    }
  };


  const handleDeleteComment = (index) => {
    const updatedComments = [...comments];
    updatedComments.splice(index, 1);
    setComments(updatedComments);
    saveComments(updatedComments, localStorage.getItem("activeStudioUiid"));
  };


  // fetching the design Array from studioModel
  useEffect(() => {
    const fetchDesignArray = async (uiid) => {
      try {
        const response = await fetch(`${API}auth/get_design_from_studio`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': JSON.parse(localStorage.getItem("myuser")).token,
           
          },
          
          body: JSON.stringify({ uiid: uiid })
        });
        const data = await response.json();
        setDesignArray(data);
      } catch (error) {
        console.error('Error fetching design array:', error);
      }
    };

    fetchDesignArray(JSON.parse(localStorage.getItem("activeStudioUiid")));
  }, []);

  useEffect(() => {
    localStorage.setItem("comments", JSON.stringify(comments));
  }, [comments]);
  console.log("DesignArray---->", designArray)
  return (
    <div className="flex">
      <div className="flex justify-center items-center h-screen w-3/4 bg-gray-200">
        <div className="bg-white p-8">Canvas Content</div>
      </div>


      <div className="w-1/4 bg-gray-100 p-4">
        <h2 className="text-xl font-bold mb-4">Comments</h2>


        <div className="mb-4">
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            placeholder="Add a comment..."
            value={newComment}
            onChange={handleInputChange}
          />
          <button
            className="mt-2 bg-blue-500 text-white py-2 px-3 rounded-full hover:bg-blue-600"
            onClick={handleAddComment}
          >
            <FontAwesomeIcon icon={faComment} />
          </button>
        </div>


        {comments.map((comment, index) => (
          <div
            key={index}
            className="bg-white p-4 mb-2 rounded flex justify-between items-center"
          >
            <p>{comment}</p>
            <button
              className="text-red-500"
              onClick={() => handleDeleteComment(index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApproverRedirecter;
