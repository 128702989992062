import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DesignCard from '../ownDesigns/DesignCard'
import { API } from '../../../../../backend'
import FilterCategoryChip from '../ownDesigns/FilterCategoryChip'
import SortBy from '../ownDesigns/SortBy'
import SortByMaterial from '../ownDesigns/SortByMaterial'
import { Button } from '@mui/joy'
import { Puff } from 'react-loader-spinner'

export default function Mydesigns() {
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([
  ])

  const getState = useSelector((state) => state)
   

  const getClientCategories = async () => {
    await axios.get(`${API}clientCate/getclientCategory`)
      .then(({ data }) => setCategories(data))
  }

  useEffect(() => {
    getClientCategories()
  }, [])

  const [materials, setMaterials] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const userDetails = useSelector(state => state.user.userDetail)
  const [sortBytime, setSortBytime] = useState(null)
  const [selectedmaterial, setSelectedMaterial] = useState(null)
  const materialsAvailable = [
    "Non-Tearable Paper (125-Micron)",
    "Premium Self-Adhesive Vinyl",
    "Self-Adhesive Vinyl on 3mm Sunboard"
  ];
  // useState(async()=>{
  // await axios.get(`${API}material/getMaterialDiy`)
  // .then(({data})=>setMaterials(data))
  // },[])
  // console.log('allMaterials',materials)
  useEffect(() => {
    const getTemp = async () => {
      setLoading(true)
      if (selectedCategories?.length === 0 && !selectedmaterial) {
        await axios.get(`${API}diy/gettemplates`)
          .then(({ data }) => {
            setLoading(false)
            setTemplates(data)
          })
      }
      else if (selectedCategories?.length !== 0 && !selectedmaterial && !sortBytime) {
        // const filteredData = templates?.filter((d,id)=>d?.catagory === selectedCategories[0])
        // setTemplates(filteredData)
        const filteredList = await Promise.all(
          selectedCategories?.map(async (c) => {
            let res = await axios.get(`${API}diy/gettemp/${c?._id}`)
            return res?.data
          })
        )
        console.log('filtered Data is this', filteredList.flat())
        setTemplates(filteredList?.flat())
        setLoading(false)
      }
      else if (selectedmaterial && !sortBytime) {
        await axios.get(`${API}diy/gettemplateswithMet?material=${selectedmaterial}`)
          .then(({ data }) => {
            setLoading(false)
            setTemplates(data)
          })
      }
      else if (sortBytime && !selectedCategories) {
        await axios.get(`${API}diy/${sortBytime}`)
          .then(({ data }) => {
            setLoading(false)
            setTemplates(data)
          })
      }
    }
    getTemp()

  }, [selectedCategories, selectedmaterial, sortBytime])



  //mytemplates which are approved
  const myFinalTemps = templates?.filter((t, id) => t?.isApproved && t?.adminId === userDetails?._id)

  return (
    <div className='flex gap-2 flex-col px-10 py-3'>
      {/* for data filter */}
      <div className=' flex gap-2 justify-between flex-wrap items-center'>
        <div>
          <SortBy
            setSelectedCategories={setSelectedCategories}
            setSelectedMaterial={setSelectedMaterial}
            sortBytime={sortBytime}
            setSortBytime={setSortBytime}
          />

        </div>
        <div className=' flex gap-2 items-center'>
          <Button
            color='danger'
            variant='soft'
            disabled={(selectedCategories?.length > 0 || selectedmaterial || sortBytime) ? false : true}
            onClick={() => {
              setSelectedMaterial(null)
              setSortBytime(null)
              setSelectedCategories([])
            }}
          >Remove filter</Button>
          <SortByMaterial
            setSelectedCategories={setSelectedCategories}
            setSortBytime={setSortBytime}
            selectedmaterial={selectedmaterial}
            materialsAvailable={materialsAvailable}
            setSelectedMaterial={setSelectedMaterial}
          />

          <FilterCategoryChip
            setSortBytime={setSortBytime}
            setSelectedMaterial={setSelectedMaterial}
            categories={categories}
            setCategories={setCategories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </div>
      </div>
      {
        !loading ?

          <>
            {
              myFinalTemps?.length > 0 ?
                <div className='grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 '>
                  {
                    myFinalTemps?.map((t, id) => (
                      <DesignCard t={t} id={id} />
                    ))
                  }
                </div> :
                <div
                  style={{
                    transform: 'translate(-50%,-50%)'
                  }}
                  className=' font-bold text-2xl absolute top-[50%] left-[50%] flex justify-center items-center'
                >
                  No Data
                </div>
            }
          </>
          :
          <div
            style={{
              transform: 'translate(-50%,-50%)'
            }}
            className=' absolute top-[50%] left-[50%] flex justify-center items-center'>
            <Puff
              visible={true}
              height="90"
              width="90"
              color="#14366e"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
      }
    </div>
  )
}
