import React from "react";
import { useDispatch } from "react-redux";
// import covidpic from "../../../images/COVID.png";
import { deleteProduct } from "../../../redux/actions/cartAction";

const OrderComp = ({
  productData,
  qty,
  price,
  img,
  desc,
  name,
  total,
  dimension,
  material,
}) => {
  const discount = Math.floor(price / 20);
  const dispatch = useDispatch();
  const deleteItem = (id) => {
    // console.log(id);
    dispatch(deleteProduct(id));
  };
  return (
    <>
      <div className="item flex-col md:flex-row relative">
        <img src={img} alt="Ehs poster" className=" max-w-[150px] mx-auto" />
        <div className="product-detail my-auto text-sm">
          <p className="name mb-1 text-base md:text-xl">{name}</p>
          <p className="qty mb-1">
            <span className="font-bold">Material:</span> {material}
          </p>
          <p className="qty mb-1">
            <span className="font-bold">Dimension:</span> {dimension}
          </p>
          <p className="qty mb-1">
            <span className="font-bold">Quantity :</span> <span>{qty}</span>
          </p>
          <p className="price mb-1">
            <span className="font-bold">Price :</span>
            <span> ₹{(price - discount) * qty}</span>
            <span
              style={{
                fontSize: "0.9rem",
                fontWeight: "500",
                color: "#636363",
                textDecoration: "line-through",
              }}
            >
              {"  "} ₹{price * qty}
            </span>
            <span
              style={{
                fontSize: "0.9rem",
                fontWeight: "400",
                color: "#f2994a",
              }}
            >
              {" "}
              5% off
            </span>
          </p>
          <p className="disc mb-1">
            <span className="font-bold">Total :</span>
            <span> ₹{(price - discount) * qty}</span>
          </p>
        </div>
        <div
          className=" mt-1 cursor-pointer px-2"
          onClick={() => {
            deleteItem(productData._id);
          }}
        >
          <i className="fas fa-times absolute top-[4px] right-[8px]"></i>
        </div>
      </div>
    </>
  );
};

export default OrderComp;
