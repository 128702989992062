import "./LeftSideBar.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faUserPlus,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const LeftSideBar = () => {
  const navigate = useNavigate();
  const studios = useSelector((state) => state.user.studio);
  
  return (
    <nav className="left-navbar">
      <ul className="left-navbar-list">
        <li className="side-navbar-item">
          <button onClick={() => navigate("/studio/?studioParams=1")}>
            <FontAwesomeIcon icon={faList} className="mr-4" /> Studio details
          </button>
        </li>
        <li className="side-navbar-item">
          <button onClick={() => navigate("/studio/?studioParams=2")}>
            <FontAwesomeIcon icon={faUserPlus} className="mr-4" /> People
          </button>
        </li>
        <li className="side-navbar-item">
          <button onClick={() => navigate("/studio/?studioParams=3")}>
            <FontAwesomeIcon icon={faBuilding} className="mr-4" /> Studios
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default LeftSideBar;
