import axios from 'axios'
import Pusher from 'pusher-js'
import beep from '../../../images/audio/chat.mp3'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API } from '../../../backend'
import ClearIcon from '@mui/icons-material/Clear';

import { showDotChat } from '../../../redux/actions/chatAction'
import SingleChat from './SingleChat'
import { Tooltip } from '@mui/material'
export default function ChatFeed({ imgUrl, replyData, setReplyData, setImgUrl, projectDetail, chatData, setChatData }) {
  const user = JSON.parse(localStorage.getItem('myuser'))
  const dispatch = useDispatch()
  const [currentImage, setCurrentImage] = useState(null)
  console.log("image is", currentImage)
  const userDetails = useSelector((state) => state.user.userDetail)
  const getState = useSelector((state) => state)

   

  console.log('project detail is', projectDetail)
  useEffect(() => {
    setChatData({
      ...chatData,

      formId: `${projectDetail?._id}`,
      demoImgUrl: imgUrl,
    })
  }, [projectDetail, imgUrl])

  //color Array
  const colArray = [
    '#9c1910',
    '#6c9608',
    '#0f658c',
    '#2d0f8c',
    '#7a1a91',
    '#9e0d69',
    '#944b0c'
  ];

  const randomColor = () => {
    for (let index = 0; index < colArray.length; index++) {
      return Math.floor(Math.random() * colArray?.length)
    }
  }

  // useEffect(()=>{
  //   setImgUrl('')
  //   setChatData({...chatData,demoImgUrl:''})
  // },[projectDetail])
  const [chats, setChats] = useState([])
  const audioRef = useRef()

  useEffect(() => {
    const getAllchats = async () => {
      await axios.get(`${API}chat/allChats`)
        .then(({ data }) => setChats(data))
    }
    getAllchats()
  }, [])

  useEffect(() => {
    var pusher = new Pusher('d963d495861f3e9e720d', {
      cluster: 'ap2'
    });

    const channel = pusher.subscribe('chats');
    channel.bind('inserted', function (data) {
      // alert(JSON.stringify(data));
      dispatch(showDotChat())
      audioRef?.current.play()
      setChats([...chats, data])
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    }
  }, [chats])


  const scrollRef = useRef()
  useEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: "smooth" })
  }, [chats, imgUrl, chatData])
  // selected project chats only
  const projectchats = chats?.filter((c) => c?.formId === projectDetail?._id)
  console.log('my chats', chats)

  return (
    <div className="flex flex-col relative space-y-2 text-sm mx-2 my-2 order-2 ">
      {
        currentImage &&
        <div
          onClick={() => setCurrentImage(null)}
          style={{
            background: 'linear-gradient(129deg, rgba(213,216,234,0.4963235294117647) 0%, rgba(223,204,229,0.5551470588235294) 99%)'
          }}
          className=' transition-all duration-200 fixed w-full z-[70] backdrop-blur-md h-screen left-0 top-0 bg-indigo-100'>
          <img
            style={{
              transform: "translate(-50%,-50%)"
            }}
            onClick={(e) => e.stopPropagation()}
            className=' absolute top-[50%] rounded-lg left-[50%] w-[55vw] h-auto' src={currentImage} alt="" />
        </div>}

      <audio
        ref={audioRef}
        // autoPlay
        src={beep} className=' sr-only w-0 h-0'></audio>
      {
        projectchats?.map((chat) => (
          <SingleChat
            setCurrentImage={setCurrentImage}
            replyData={replyData}
            setReplyData={setReplyData}
            chat={chat}
            projectDetail={projectDetail}
            user={user}
            projectchats={projectchats}
            chatData={chatData}
            setChatData={setChatData}
          />
        ))
      }

      {
        projectchats?.length === 0 &&

        <div>
          No chats yet
        </div>
      }

      <div ref={scrollRef}>
      </div>


      {/* to show the image before Send */}
      {imgUrl && chatData?.demoImgUrl &&
        <div className=' absolute bottom-[-500px] right-0'>
          <div
            ref={scrollRef}
            className=' w-full min-h-[450px]  relative flex flex-row-reverse'>
            <div
              onClick={() => {
                setImgUrl('')
                setChatData({ ...chatData, demoImgUrl: '' })
              }}
              className=' absolute hover:bg-rose-200 cursor-pointer transition-all duration-200 rounded-full right-0 top-0 m-2 p-2 bg-rose-50 text-rose-700'>
              <ClearIcon />
            </div>
            <img
              // ref={scrollRef}
              className=' max-w-[450px] border-[1px] border-indigo-600 h-auto rounded-md'
              src={chatData?.demoImgUrl} alt="" />
          </div>
        </div>
      }

      {/* show the file before send it */}
      {chatData?.demoFileUrl &&
        <div className=' w-full absolute bottom-[-500px] right-0'>
          <div
            ref={scrollRef}
            className=' relative bg-indigo-100 w-full px-6 rounded-md py-3 flex gap-7 items-center'>

            <div
              onClick={() => {

                setChatData({ ...chatData, demoFileUrl: '' })
              }}
              className=' absolute hover:bg-rose-200 cursor-pointer transition-all duration-200 rounded-full right-0 top-0 m-2 p-2 bg-rose-50 text-rose-700'>
              <ClearIcon />
            </div>
            <svg
              style={{
                backgroundColor: `${colArray[randomColor()]}`
              }}
              className=' scale-[2.5] p-1 rounded-md text-white'
              width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 2.5C3 2.22386 3.22386 2 3.5 2H9.08579C9.21839 2 9.34557 2.05268 9.43934 2.14645L11.8536 4.56066C11.9473 4.65443 12 4.78161 12 4.91421V12.5C12 12.7761 11.7761 13 11.5 13H3.5C3.22386 13 3 12.7761 3 12.5V2.5ZM3.5 1C2.67157 1 2 1.67157 2 2.5V12.5C2 13.3284 2.67157 14 3.5 14H11.5C12.3284 14 13 13.3284 13 12.5V4.91421C13 4.51639 12.842 4.13486 12.5607 3.85355L10.1464 1.43934C9.86514 1.15804 9.48361 1 9.08579 1H3.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5H7.5C7.77614 5 8 4.77614 8 4.5C8 4.22386 7.77614 4 7.5 4H4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H4.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H10.5C10.7761 11 11 10.7761 11 10.5C11 10.2239 10.7761 10 10.5 10H4.5Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
            <Tooltip title="Download">
              <a href={chatData?.demoFileUrl} download=''>
                Reference File
              </a>
            </Tooltip>
          </div>
        </div>
      }

      {
        replyData?._id &&
        <div className=' absolute bottom-0 flex gap-2 w-full bg-indigo-100 p-2 rounded-xl'>

          <div
            onClick={() => {
              setReplyData({ ...replyData, _id: '', text: '', demoImgUrl: '', user: '' })
            }}
            className=' absolute hover:bg-rose-200 cursor-pointer transition-all duration-200 rounded-full right-0 top-0 m-2 p-1 bg-rose-50 text-rose-700'>
            <ClearIcon />
          </div>
          {
            replyData?.demoImgUrl &&

            <img
              // ref={scrollRef}
              className=' max-w-[70px] border-[1px] border-indigo-600 h-auto rounded-md'
              src={replyData?.demoImgUrl} alt="" />

          }
          <div className=' ml-2 flex flex-col'>
            <span className=' text-lg font-semibold'>{replyData?.user}</span>
            <span className=' text-normal font-medium'>{replyData?.text}</span>
          </div>


        </div>}
    </div>
  )
}
