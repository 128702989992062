import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API } from '../../../backend'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Tooltip from '@mui/material/Tooltip'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectChat } from '../../../redux/actions/chatAction';
import AddfileMenue from './AddfileMenue';
import { toast } from 'react-toastify';
export default function ChatInput({ fileUrl, setFileUrl, uploadFileSingle, uploadImgSingle, replyData, setReplyData, imgUrl, setImgUrl, chatData, setChatData,projectDetail }) {
  const dispatch = useDispatch()

  const getState = useSelector((state) => state);

   
  const [showFileModel, setShoeFileModel] = useState(false)
  const userDetails = useSelector((state) => state.user?.userDetail);
  
  const postComment = () => {
    const addcomment = async () => {
      await axios.post(`${API}chat/createChat`,
        chatData,
        )
        .then(({ data }) => {
          setChatData({ ...chatData, text: '', demoImgUrl: '', userRef: '', demoFileUrl: '' })
          setReplyData({ ...replyData, _id: '', text: '', demoImgUrl: '', user: '', demoFileUrl: '' })
          // dispatch(selectProjectChat(chatData?.formId))
        })
        .catch(() => alert('something went wrong'))
    }
    addcomment()
  }
  console.log('chat data is', chatData)

 

  //send mail to designer on chat 
  const sendMailtoDesigner = async (mailId) => {
    console.log("ID--->", mailId);
    if (userDetails?.role === 'corporate admin' || 'user'){
      return;
    }
    try {
      await axios.get(`${API}designers/sendmailtoDesigner/${mailId}?Link=https://stencii.com/chats&sender=${userDetails?.emailid}`);
      toast.success("User is notified 🥳");
      // setLoading(false);
      // setConfirmModel(false);
    } catch (err) {
      toast.error('Please enter a valid email id')
      console.log(err, "checking error");
      toast.error("Your Network is not stable ! Please try after some time ", err);
      // setLoading(false);
      // setConfirmModel(false);
      throw err; // Re-throwing the error to be caught by the caller
    }
  }

  //send mail to user by designer on new chat

  const sendMailtoUser = async (userId) => {
    console.log("ID--->", userId);
    if (userDetails?.role === 'designer') {
      return;
    }
    try {
      await axios.get(`${API}designers/sendmailtoUser/${userId}?Link=https://stencii.com/chats&sender=${projectDetail?.designerId?.emailid}`);
      toast.success("User is notified 🥳");
      // setLoading(false);
      // setConfirmModel(false);
    } catch (err) {
      toast.error('Please enter a valid email id')
      console.log(err, "checking error");
      toast.error("Your Network is not stable ! Please try after some time ", err);
      // setLoading(false);
      // setConfirmModel(false);
      throw err; // Re-throwing the error to be caught by the caller
    }
  };



  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      if (chatData?.text || chatData?.demoFileUrl || chatData?.demoImgUrl) {
        postComment()
        sendMailtoDesigner(projectDetail?.designerId?._id)
        sendMailtoUser(projectDetail?.myId?._id)
      }
      else {
        toast.error("Add a message first !")
      }
    }}>
      <div
        className="flex flex-row items-center h-16 bg-white w-full px-4"
      >
        <div className=' relative'>

          {
            // showFileModel &&
            <div
              onMouseEnter={() => setShoeFileModel(true)}
              onMouseLeave={() => setShoeFileModel(false)}
              className={showFileModel ? ' absolute bottom-[-20px] transition-all duration-200 z-[60] bg-zinc-100 left-0 max-w-[140px] flex flex-col  gap-1 p-1.5 border-[1px] rounded-xl border-indigo-300' : 'z-[60] bg-zinc-100 max-w-[125px] overflow-hidden flex flex-row  gap-1 p-0.5 border-[1px] rounded-xl border-indigo-300'}>
              <div className=' hover:bg-indigo-100 hover:text-black text-zinc-600 mx-auto rounded-xl py-1 px-2 cursor-pointer flex gap-2 justify-start items-center'>
                <Tooltip followCursor title="add image">
                  <div className=' flex justify-between gap-2 items-center mx-auto'>
                    <div
                      className="flex"
                    >
                      {/* for image chat */}
                      <input
                        accept="image/png, image/jpeg, image/gif"
                        onClick={() => setShoeFileModel(!showFileModel)}
                        // value={chatData?.demoImgUrl}
                        className=' absolute opacity-0'
                        onChange={(e) => uploadImgSingle(e)}
                        id="file-upload" name="file-upload" type="file" />
                      {!showFileModel ? <AttachFileIcon /> : <AddPhotoAlternateIcon />}
                    </div>
                    <p className=' w-[70px] flex items-center my-auto text-sm'>{showFileModel ? "Image" : "Add Media"}</p>
                  </div>
                </Tooltip>
              </div>

              <div className=' hover:bg-indigo-100 rounded-xl hover:text-black text-zinc-600 py-1 cursor-pointer flex gap-2 justify-start items-center'>
                <Tooltip followCursor title="Add file">
                  <div className=' relative flex justify-between gap-2 items-center'>
                    <div
                      onClick={() => setShoeFileModel(!showFileModel)}
                      className=' opacity-0 absolute'>
                      <input
                        type='file'
                        accept='application/pdf'
                        onChange={(e) => uploadFileSingle(e)}
                        className=' opacity-0'
                      />
                    </div>
                  </div>
                </Tooltip>
                <AttachFileIcon />
                <p className=' flex my-auto'>File</p>
              </div>
            </div>}
        </div>


        <div className="flex-grow ml-4">
          <div className="relative w-full">
            <input
              autoFocus
              placeholder=' type here...'
              // required
              value={chatData?.text}
              onChange={(e) => setChatData({ ...chatData, text: e.target.value })}
              type="text"
              className="flex w-full border-[1px] rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
            />
            <button
              className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="ml-4">
          <button
            type='submit'
            className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
          >
            <span>Send</span>
            <span className="ml-2">
              <svg
                className="w-4 h-4 transform rotate-45 -mt-px"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </form>
  )
}
