const initialState = {
	activeTool: "Dimension-Tools",
	activePage: 0,
	activeElementType: "page", //page,font,shapes,image,logos
	activeElementIndex: 0,
	textEditorEnabled: false,
	targetElement: null
};

const editor = (state = initialState, action) => {
	switch (action.type) {
		case "SET_ACTIVE_TOOL":
			return {
				...state,
				activeTool: action.payload.activeTool,
				activePage: action.payload.activePage,
				activeElementType: action.payload.activeElementType,
				activeElementIndex: action.payload.activeElementIndex,
				textEditorEnabled: action.payload.textEditorEnabled ?? false
			};
		case "SET_TARGET_ELEMENT":
			return {
				...state,
				targetElement: action.payload.targetElement
			};
		default:
			return state;
	}
};

export default editor;
