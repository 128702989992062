import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment/moment';
import ReplyIcon from '@mui/icons-material/Reply';
import Tooltip from '@mui/material/Tooltip'

export default function SingleChat({setCurrentImage,replyData,setReplyData,chat,projectDetail,user,projectchats,chatData,setChatData}) {
     const msgRef = useRef(null)
     const [refMsgId,setRefMsgId] = useState(null)
     console.log('ref msg id is',refMsgId)
    const [showReply,setShowReply] = useState(false)
    //finding the replydata by userRef id
    const refMessage = projectchats?.find((m)=>m?._id === chat?.userRef )
    
    const handleScroll=()=>{
            msgRef?.current?.scrollIntoView({ behavior: "smooth"})   
    }
    // useEffect(()=>{
    //     msgRef?.current?.scrollIntoView({ behavior: "smooth"})   

    //   },[chat,refMessage,refMsgId])


    return (
    <div>
{
user?.id === chat?.myId ?
<div
// ref={refMsgId === chat?._id ? msgRef : null}
onMouseEnter={()=>{
    setShowReply(true)
}}
onMouseLeave={()=>{
    setShowReply(false)
}}
className=' flex relative flex-row-reverse'>
{ refMsgId === chat?._id &&
 <div
    ref={msgRef}
    >
        helloo
        </div>
        }

{
showReply && 
<Tooltip title="Reply">
<div
onClick={()=>{
    setReplyData({...replyData,_id:chat?._id,text:chat?.text,demoImgUrl:chat?.demoImgUrl,user:chat?.sendername})
    setChatData({...chatData,userRef:chat?._id})}}
    className=' absolute p-1 scale-75 rounded-full bg-zinc-800 bottom-0 cursor-pointer mb-[-7px] mr-[-7px] right-0'>
<ReplyIcon className=' text-white'/>

    </div>
    </Tooltip>
    }

  
  <span className={ chat?.demoImgUrl?" border-[1px] border-indigo-400 pt-2 rounded-lg inline-block bg-indigo-600 text-gray-100":"px-4 py-2 rounded-lg inline-block bg-indigo-600 text-gray-100"}>
  {
    chat?.demoImgUrl &&
    <p className=' px-2 mb-[-17px] text-sm font-bold text-yellow-200'>
              {chat?.sendername}
              </p>}

             <span className=' text-sm font-bold text-yellow-200'>{ !chat?.demoImgUrl && chat?.sendername}</span> 
             <br />
             {/* file chat */}
             <div className=' bg-indigo-200 my-2 rounded-xl'>
             {chat?.demoFileUrl &&
<div 
//  ref={scrollRef}
className=' relative bg-indigo-100 w-full px-6 rounded-md py-3 flex gap-7 items-center'>
                      <svg
                      style={{
                        backgroundColor:'InactiveBorder'
                      }}
                      className=' scale-[2.5] p-1 rounded-md text-white'
                      width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 2.5C3 2.22386 3.22386 2 3.5 2H9.08579C9.21839 2 9.34557 2.05268 9.43934 2.14645L11.8536 4.56066C11.9473 4.65443 12 4.78161 12 4.91421V12.5C12 12.7761 11.7761 13 11.5 13H3.5C3.22386 13 3 12.7761 3 12.5V2.5ZM3.5 1C2.67157 1 2 1.67157 2 2.5V12.5C2 13.3284 2.67157 14 3.5 14H11.5C12.3284 14 13 13.3284 13 12.5V4.91421C13 4.51639 12.842 4.13486 12.5607 3.85355L10.1464 1.43934C9.86514 1.15804 9.48361 1 9.08579 1H3.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5H7.5C7.77614 5 8 4.77614 8 4.5C8 4.22386 7.77614 4 7.5 4H4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H4.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H10.5C10.7761 11 11 10.7761 11 10.5C11 10.2239 10.7761 10 10.5 10H4.5Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                      <Tooltip title="Download">
                      <a href={chat?.demoFileUrl} download=''>
                      Reference File
                      </a> 
                      </Tooltip>
                    </div>
      }
             </div>
              {/* for reply message */}
              {chat?.userRef && refMessage &&
              <>
             {
                !refMessage?.demoImgUrl ?
                <span
                className=' text-sm font-normal text-yellow-50 hover:underline cursor-pointer py-2 rounded-2xl'>{refMessage?.text}</span>:
                <Tooltip
                followCursor
                title="Show message">
                                  <img
                                  onClick={()=>{
                                    setCurrentImage(refMessage?.demoImgUrl)
                                    setRefMsgId(refMessage?._id)
                                    handleScroll()}}
                className=' p-2 my-2 cursor-pointer hover:opacity-50 transition-all duration-200 bg-white max-w-[300px] h-auto rounded-md'
                src={refMessage?.demoImgUrl} alt="" />
                </Tooltip>
            }
            </>
              }
{
chat?.demoImgUrl &&
<p className=' px-2 mb-[5px]'>
    {chat?.text}
    </p>}

    <span className=' flex justify-between w-full gap-3'>
    <span>{ !chat?.demoImgUrl && chat?.text}</span>
   <span className={ chat?.demoImgUrl?' text-xs font-medium mr-2 mb-1':'font-medium mr-0 text-xs mt-[3px]'}> {moment(chat?.created_at).fromNow()}</span>
   </span> 
    {
      chat?.demoImgUrl &&
      <img
      onClick={()=>setCurrentImage(chat?.demoImgUrl)}
      className=' p-2 bg-white max-w-[300px] h-auto rounded-md'
      src={chat?.demoImgUrl} alt="" />
    }
    </span></div>:
            <div
            // ref={refMsgId === chat?._id ? msgRef:null}
            className=' relative'
            onMouseEnter={()=>{
                setShowReply(true)
            }}
            onMouseLeave={()=>{
                setShowReply(false)
            }}
            >

{ refMsgId === chat?._id &&
 <div
    ref={msgRef}
    >
        helloo
        </div>
        }

{
showReply && 
<Tooltip title="Reply">
<div
onClick={()=>{
    setReplyData({...replyData,_id:chat?._id,text:chat?.text,demoImgUrl:chat?.demoImgUrl,demoFileUrl:chat?.demoFileUrl,user:chat?.sendername})
    setChatData({...chatData,userRef:chat?._id})}}
    className=' absolute p-1 rotate-180 scale-75 rounded-full bg-zinc-800 bottom-0 cursor-pointer mb-[-7px] ml-[-7px] left-0'>
<ReplyIcon className=' text-white'/>

    </div>
</Tooltip>
}
              <span className={(chat?.demoImgUrl && chat?.myId !== projectDetail?.designerId?._id) ?
                " pt-2 border-[1px] border-zinc-400 rounded-lg inline-block bg-gray-300 text-gray-600":(!chat?.demoImgUrl && chat?.myId === projectDetail?.designerId?._id)?
                "px-4 py-2 rounded-lg border-indigo-300 border-[1px] inline-block bg-indigo-100 text-gray-600":(chat?.demoImgUrl && chat?.designerId === projectDetail?.designerId?._id)?" pt-2 border-indigo-300 border-[1px] rounded-lg inline-block bg-indigo-100 text-gray-600":"px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600"}>
{      
chat?.demoImgUrl &&
<p className=' px-2 mb-[-17px] text-sm font-bold text-yellow-900'>
              {chat?.sendername}
              </p>}

             <span className=' text-sm font-bold gap-2 text-yellow-900'>{ !chat?.demoImgUrl && chat?.sendername}
           {/* <span >
           {(chat?.myId?._id === projectDetail?.designerId?._id) &&
chat?.myId?.Role
}
            </span>  */}
             </span> 
             
              <br />    
              {/* file chat */}
              <div className=' bg-indigo-200 my-2 rounded-xl'>
             {chat?.demoFileUrl &&
<div 
//  ref={scrollRef}
className=' relative bg-indigo-100 w-full px-6 rounded-md py-3 flex gap-7 items-center'>
                      <svg
                      style={{
                        backgroundColor:'InactiveBorder'
                      }}
                      className=' scale-[2.5] p-1 rounded-md text-white'
                      width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 2.5C3 2.22386 3.22386 2 3.5 2H9.08579C9.21839 2 9.34557 2.05268 9.43934 2.14645L11.8536 4.56066C11.9473 4.65443 12 4.78161 12 4.91421V12.5C12 12.7761 11.7761 13 11.5 13H3.5C3.22386 13 3 12.7761 3 12.5V2.5ZM3.5 1C2.67157 1 2 1.67157 2 2.5V12.5C2 13.3284 2.67157 14 3.5 14H11.5C12.3284 14 13 13.3284 13 12.5V4.91421C13 4.51639 12.842 4.13486 12.5607 3.85355L10.1464 1.43934C9.86514 1.15804 9.48361 1 9.08579 1H3.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5H7.5C7.77614 5 8 4.77614 8 4.5C8 4.22386 7.77614 4 7.5 4H4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H4.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H10.5C10.7761 11 11 10.7761 11 10.5C11 10.2239 10.7761 10 10.5 10H4.5Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                      <Tooltip title="Download">
                      <a href={chat?.demoFileUrl} download=''>
                      Reference File
                      </a> 
                      </Tooltip>
                    </div>
      }
             </div>

                            {/* for reply message */}
              {chat?.userRef && refMessage &&
              <>
             {
                !refMessage?.demoImgUrl ?
                <span
                className=' text-sm font-normal text-indigo-900 hover:underline cursor-pointer py-2 rounded-2xl'>{refMessage?.text}</span>:
                <Tooltip
                followCursor
                title="Show message">
                                  <img
                                  onClick={()=>{
                                    setCurrentImage(refMessage?.demoImgUrl)
                                    setRefMsgId(refMessage?._id)
                                    handleScroll()}}
                className=' p-2 my-2 cursor-pointer hover:opacity-50 transition-all duration-200 bg-white max-w-[300px] h-auto rounded-md'
                src={refMessage?.demoImgUrl} alt="" />
                </Tooltip>

            }
            </>
              } 
{
chat?.demoImgUrl &&
<p className=' px-2 mb-[5px]'>
    {chat?.text}
    </p>}
   <span className=' flex justify-between w-full gap-3'>
    <span>{ !chat?.demoImgUrl && chat?.text}</span>
   <span className={ chat?.demoImgUrl?' text-xs font-medium mr-2 mb-1':'font-medium mr-0 text-xs mt-[3px]'}> {moment(chat?.created_at).fromNow()}</span>
   </span> 
    {
      chat?.demoImgUrl &&
      <img
      onClick={()=>setCurrentImage(chat?.demoImgUrl)}
      className=' p-2 bg-white max-w-[300px] h-auto rounded-md'
      src={chat?.demoImgUrl} alt="" />
    }
    </span></div>
}
    </div>
  )
}
