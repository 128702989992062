import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

export default function MembersModel({projectDetail}) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
{
projectDetail?.team?.length > 2 &&
<div
onClick={()=>setOpen(true)}
className=' cursor-pointer bg-blue-100 text-xs text-black px-2 py-1 rounded-3xl'>
                      See all members
                      </div>}
      <Modal
        aria-labelledby="close-modal-title"
        open={open}
        onClose={(_event, reason) => {
        //   alert(`Reason: ${reason}`);
          setOpen(false);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            borderRadius: 'md',
            p: 3,
          }}
        >
          <ModalClose variant="outlined" />
          <Typography
            component="h2"
            id="close-modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
          >
            All project Members
          </Typography>
          <div className=' flex flex-col gap-2 mt-2'>
            {projectDetail?.team?.map((t)=>(
                <div className=' p-2 bg-indigo-100 rounded-lg'>
                    {t}
                </div>
            ))}
          </div>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}