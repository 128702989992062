import React, { useState, useEffect } from "react";
import "./signin.css";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../../backend";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useDispatch, useSelector } from "react-redux";
// import { addProduct } from "../../redux/actions/cartAction";
import { CommonPopup } from "../commonComponents/popUp/PopUp";
// import jwt_decode from "jwt-decode";
import loginPopupImg from "../../images/popups/loginPopup.svg";
// components
// import { userInfo, getUser } from "../../redux/actions/userAction";
// images
// import { Link } from "react-router-dom";
import logo from "./../../images/EhsLogo2.png";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import CircularProgress from '@mui/joy/CircularProgress';



const ForgetPassword = () => {
  const topView = () => {
    window.scrollTo({ top: 0 });
  };
  topView();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailLoading,setEmailLoading]=useState(false);
  // let newUser;

  const [popup, setPopup] = useState(false);
  const getState = useSelector((state) => state);

   

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("myuser")) {
      navigate("/");
    }
  }, []);
  
  const handleReset = async (data) => {
    setEmailLoading(true)
    let emailid = data.email;
  
    console.log(email);
    const res = await fetch(`${API}auth/resetPassword`, {
      method: "POST",
      body: JSON.stringify({ emailid}),
      headers: {
        "Content-Type": "application/json",
       },
    });

    
    const resmaildata=await res.json();
  
   if(resmaildata.success){
      toast.info(resmaildata.message);
      setEmailLoading(false);
    
      setTimeout(() => {
          navigate("/signin")
      }, 30000);

   }

    
  };

  return (
    <>
      <div className="signinContainer">
        {/* <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
        <div className="formContainer2 signInform-box flex items-center justify-center flex-col mt-4 ">
          <div className="logoContainer mb-5">
            <div className="logo  mt-7 ">
              <img src={logo} alt="Ehs-logo" className="w-[125px]" />
            </div>
            <p className="mb-1 text-2xl text-[#003459] font-['Roboto] mt-3">
              Change your password
            </p>
          </div>
          <form className="form mb-4   flex gap-3 justify-center items-center " onSubmit={handleSubmit(handleReset)}>
            <input
              type="email"
              placeholder="Please Enter Your Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              {...register("email", { required: "Email is required" })}
              className="border rounded-[4px] flex items-center "
            />
            {errors.email && (
              <p className="text-red-400 text-xs">{errors.email?.message}</p>
            )}
        
           {
              emailLoading? <CircularProgress />:<input type="submit" value="Continue" className="submitBtn mt-3" />
            }
           <div id="errorBox"></div>
          </form>
        </div>
      </div>

      <CommonPopup
        heading="Great!"
        image={loginPopupImg}
        desc="You have successfully changed your password."
        secondBtn={true}
        setMyPopup={setPopup}
        popup={popup}
      />
    </>
  );
};

export default ForgetPassword;
