import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatInput from './ChatInput';
import ChatFeed from './ChatFeed';
import { useSelector } from 'react-redux';
import { API } from '../../../backend';
import axios from 'axios';
import MembersModel from '../MembersModel';
import { IconButton } from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip'
export default function ChatContainer({chatData,setChatData,setProjectId,projectDetail,userDetails}) {
    const {id} = useParams()
    useEffect(()=>{
    setProjectId(id)
    },[id])
    const navigate = useNavigate()
    const [replyData,setReplyData] = useState({
      _id:'',
      text:'',
      demoImgUrl:''
   })

   const getState = useSelector((state) => state);
     

    const [imgUrl,setImgUrl] = useState(null)
    const [fileUrl,setFileUrl] = useState(null)
    //uploading single image
    const uploadImgSingle = async (e) => {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("imgUrl", file);
  
      await axios
        .post(`${API}posters/uploadFile`, 
          formData)
        .then((response) => {
          console.log(response);
          setImgUrl(response.data.data.fileSavedUrl)
        });
      console.log(imgUrl);
    };

        //uploading single file
        const uploadFileSingle = async (e) => {
          let file = e.target.files[0];
          let formData = new FormData();
          formData.append("pdfUrl", file);
      
          await axios
            .post(`${API}posters/uploadFilePdf`, 
              formData)
            .then((response) => {
              console.log(response);
              setFileUrl(response.data.data.fileSavedUrl)
            });
          console.log(fileUrl);
        };



    const userDetail = useSelector((state) => state.user.userDetail);

    // const [chatData,setChatData] = useState({
    //   text:'',
    //   demoImgUrl:'',
    //   myId:`${userDetail?._id}`,
    //   formId:'',
    //   sendername:`${userDetail?.emailid}`,
    //   designerId:'',
    //   designername:''
    // })

    // useEffect(()=>{
    //   setChatData({...chatData,
    //     myId:`${userDetail?._id}`,
    //     sendername:`${userDetail?.name}`,
    //     formId:`${projectDetail?._id}`,
    //     designerId:`${projectDetail?.designerId?._id}`,
    //     designername:`${projectDetail?.designerId?.name}`,
    //   })
    // },[userDetail,projectDetail])

    useEffect(()=>{
    setChatData({...chatData,demoImgUrl:imgUrl})
    },[imgUrl])

    useEffect(()=>{
      setChatData({...chatData,demoFileUrl:fileUrl})
      },[fileUrl])

    console.log('Project id is ======>>',id)
  return (
    <div className=' flex flex-col w-full'>
        {/* header */}
        <div className=' flex border relative border-zinc-400 rounded-lg items-center justify-between text-black p-2 bg-white'>
        <div className=' absolute right-0 top-0 mt-3 mr-2'>
        <Tooltip title="Back to Job Cards">
        <IconButton
        onClick={()=>navigate('/myforms')}
        >
          <ArrowBackIcon/>
        </IconButton>  
        </Tooltip>
        </div>
      <div className=' w-full flex-col gap-1 text-xl font-semibold  flex my-auto'>
      {projectDetail?.title || projectDetail?.projectDesc?.slice(0,20) +'..'}

       <div className=' flex items-center w-[450px] gap-2 md:w-full overflow-x-auto'>
       <div className=' bg-indigo-100 text-black px-2 py-1 rounded-3xl text-xs'>
       {userDetail?.emailid ===projectDetail?.myId?.emailid?"You":projectDetail?.myId?.emailid} <span className=' text-xs font-normal cursor-pointer hover:font-medium transition-all duration-200 ml-1 hover:text-indigo-900'>owner</span>
            </div>
        {
            projectDetail?.team?.slice(0,2)?.map((t,id)=>(
<div className=' bg-indigo-100 text-black px-2 py-1 rounded-3xl text-xs'>
    {userDetail?.emailid ===t?"You":t?.length>10?t?.slice(0,8)+'..':t}
</div>
            ))
        }
        {
          (projectDetail?.team?.length === 0 || !projectDetail?.team)
          &&
          
          <div className=' bg-indigo-100 text-black px-2 py-1 rounded-3xl text-xs'>
            No Members yet
            </div>

                      
        }

<div className=' bg-blue-200 text-xs text-black px-2 py-1 rounded-3xl'>
                      {projectDetail?.designerId?.name}
                      </div>

                      <MembersModel projectDetail={projectDetail}/>
                      

       </div>
       </div>
       {/* <div className=' mr-2'>
        <MoreVertIcon/>
       </div> */}
      </div>

      {/* chats */}
    <div className='h-[calc(100vh-250px)] overflow-y-auto overflow-x-hidden'>
<ChatFeed replyData={replyData} setReplyData={setReplyData} imgUrl={imgUrl} setImgUrl={setImgUrl}  chatData={chatData} setChatData={setChatData} projectDetail={projectDetail}/>

    </div>


      {/* send Chat */}
      <ChatInput
      uploadFileSingle={uploadFileSingle}
      fileUrl={fileUrl} setFileUrl={setFileUrl}
      replyData={replyData} setReplyData={setReplyData} uploadImgSingle={uploadImgSingle} imgUrl={imgUrl} setImgUrl={setImgUrl} chatData={chatData} setChatData={setChatData} projectDetail={projectDetail}   />


    </div>
  )
}
