import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { hideDotChat, showDotChat } from '../../../redux/actions/chatAction';
import { getAllforms } from '../../../redux/actions/designerFormAction';
import { API } from '../../../backend';

export default function AdminTeamChat({ f, idx, projectId, chatData, setChatData, setProjectId, setShowProjects, setProjectDetail, projectDetail }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [temp, setTemp] = React.useState(null)
  const [allTemps, setAlltemps] = React.useState([])
  const forms = useSelector((state) => state.form.form)
  const userDetail = useSelector((state) => state.user.userDetail)
  const getState = useSelector((state) => state);
  // const currentProjectId = useSelector((state)=>state.chat.currentProjectId)
  // const showDot = useSelector((state)=>state.chat.showDot)

   

  console.log('forms are', forms)

  React.useEffect(() => {
    dispatch(getAllforms())
  }, [])
  React.useEffect(() => {
    if (f?.templateId) {
      const getTemp = async () => {
        await axios.get(`${API}diy/gettemplate/${f?.templateId}`)
          .then(({ data }) => setTemp(data))
          .catch((error) => console.log(error))
      }
      getTemp()
    }

  }, [f])

  //getting all unpublished templates
  React.useEffect(() => {
    const getTemp = async () => {
      await axios.get(`${API}diy/gettemplates`)
        .then(({ data }) => setAlltemps(data))
        .catch((error) => console.log(error))
    }
    getTemp()
  }, [])

  console.log('alltemps are', allTemps)

  // checking the template is created or not
  const currentTemplate = allTemps?.find((t) => t?.instruction?._id === f?._id)
  console.log('template for the job card', currentTemplate)

  return (
    <>
      {
        (f?.team.find((mail) => mail === userDetail?.emailid) || userDetail?.team.find((mail) => mail === f?.myId?.emailid)) &&
        <Card
          className=' transition-all duration-200'
          onClick={() => {
            setChatData({
              ...chatData,
              myId: `${userDetail?._id}`,
              sendername: `${userDetail?.name}`,
              formId: `${projectDetail?._id}`,
              designerId: `${projectDetail?.designerId?._id}`,
              designername: `${projectDetail?.designerId?.name}`,
            })
            dispatch(hideDotChat())
            setProjectDetail(f)
            setShowProjects(false)
            setProjectId(f?._id)
            navigate(`chat/${f?._id}`)
          }}
          variant="outlined"
          orientation="horizontal"
          sx={{
            position: 'relative',
            width: 330,
            mr: 2,
            cursor: 'pointer',
            borderLeft: projectDetail?._id === f?._id ? 'blue solid 5px' : '',
            '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
          }}
        >
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {f?.title}
            </Typography>
            <Typography level="body-sm" aria-describedby="card-description" mb={1}>

              {f?.projectDesc?.slice(0, 20) + '..'}

            </Typography>

            {
              (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
              <Chip
                size='md' variant='soft'

                onClick={(e) => {
                  e.stopPropagation()
                  if (currentTemplate) {
                    navigate(`/diy/${currentTemplate?._id}`)
                  }
                  if (f?.templateId) {
                    navigate(`/diy/${f?.templateId}`)
                  }

                }}
              >
                See design
              </Chip>}
          </CardContent>
        </Card>}

    </>
  );
}
