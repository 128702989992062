import React, { useEffect, useState } from 'react'
import Masonry from '@mui/lab/Masonry';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MoreVert from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import { Box, Avatar, Button, Card, CardActions, CardContent, Chip, Typography } from '@mui/joy';
import axios from 'axios';
import PreviewIcon from '@mui/icons-material/Preview';
import { API } from '../../backend';
import { useNavigate } from 'react-router-dom';
import SpokeIcon from '@mui/icons-material/Spoke';
import ClearIcon from '@mui/icons-material/Clear';
import { delForm, getAllforms } from '../../redux/actions/designerFormAction';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip'
import ChatIcon from '@mui/icons-material/Chat';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { selectProjectChat } from '../../redux/actions/chatAction';

export default function Form({ workState, isShowTeamProjects, changeShowProject, setChangeShowProject, showArchived, getMultiimgData, myForms, formData, idx, setFormData, setInstructionPopup, f, userDetails }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [temp, setTemp] = useState(null)
  const [allTemps, setAlltemps] = useState([])
  const [showMsgicon, setShowMsgicon] = useState(false)
  const [open, setOpen] = useState(false)
  const businessId = JSON.parse(localStorage.getItem('businessId'))
  const selectedProject = useSelector((state) => state.chat.currentProjectId)
  const forms = useSelector((state) => state.form.form)
  const getState = useSelector((state) => state);

   

  console.log('forms are', forms)
  React.useEffect(() => {
    dispatch(getAllforms())
  }, [])
  useEffect(() => {
    if (f?.templateId) {
      const getTemp = async () => {
        await axios.get(`${API}diy/gettemplate/${f?.templateId}`)
          .then(({ data }) => setTemp(data))
          .catch((error) => console.log(error))
      }
      getTemp()
    }
  }, [f])

  //currectUserisexist In team

  const userExists = f?.team?.find((mail) => userDetails?.emailid === mail)


  //getting all unpublished templates
  useEffect(() => {
    const getTemp = async () => {
      await axios.get(`${API}diy/gettemplates`)
        .then(({ data }) => setAlltemps(data))
        .catch((error) => console.log(error))
    }
    getTemp()
  }, [])

  console.log('alltemps are', allTemps)
  console.log(' formData is here..', formData)

  useEffect(() => {
    setFormData({ ...formData, designerId: `${f?.designerId?._id}` })
  }, [f])


  //del the instruction
  const delInstruction = () => {
    dispatch(delForm(f?._id))
    toast.success('Instruction is deleted successfully')
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }


  // checking the template is created or not
  const currentTemplate = allTemps?.find((t) => t?.instruction?._id === f?._id)
  //checking that multiple temp are present with the same instruction
  const currentTemplates = allTemps?.filter((t) => t?.instruction?._id === f?._id)

  console.log('template for the job card', currentTemplate)
  //checking already Published or not
  const alreadyPublished = myForms?.find((f) => f?.templateId === temp?._id)
  return (
    <>


      {/* for client  */}
      {
        !temp?.isApproved && !isShowTeamProjects &&
        // currentTemplates?.length === 0 &&
        // (temp?.instruction?.multiPage===false || !temp?.instruction?.multiPage) && !temp?.instruction?.diyIds  
        !currentTemplate?.isApproved &&
        !showArchived && workState === 'All' &&
        !businessId && userDetails?.Role === 'corporate_admin' &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => {
                  navigate(`/chats/chat/${f?._id}`)
                }}
                className=' z-[50] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      team: f?.team,
                      multiPage: f?.multiPage,
                      Material: f?.Material,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}


        </Card>}

      {/* for normal users only */}
      {
        !temp?.isApproved && !currentTemplate?.isApproved && !showArchived && workState === 'All' &&
        !businessId && userDetails?.Role === 'user' &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className=' cursor-pointer z-[99] absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      team: f?.team,
                      Material: f?.Material,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate) {
                        navigate(`/diy/${currentTemplate?._id}`)
                      }
                      if (f?.templateId) {
                        navigate(`/diy/${f?.templateId}`)
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}
        </Card>}

      {/* for member to see own projects all projects */}
      {
        !showArchived && workState === 'All' &&
        businessId && changeShowProject === "My Projects" &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}` })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className=' z-[99] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      team: f?.team,
                      Material: f?.Material,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}
        </Card>}

      {/* for member to see team projects */}
      {
        // !temp?.isApproved  
        // && !currentTemplate?.isApproved &&

        !showArchived && workState === 'All' &&
        businessId && userExists && changeShowProject === "Team Projects" &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className=' cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 z-[999] p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      team: f?.team,
                      Material: f?.Material,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}
            {/* 
      { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}
        </Card>}


      {/* for client only Await my actions */}
      {
        ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
        !temp?.isApproved && !currentTemplate?.isApproved && !showArchived && workState === 'Await my actions' &&
        !businessId && !isShowTeamProjects &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip
              className=' cursor-pointer absolute z-[99]  right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'
              title="start group chat" >
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
              >
                <ChatIcon className=' z-[99]' />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      team: f?.team,
                      isMulti: f?.isMulti,
                      Material: f?.Material,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}
        </Card>}

      {/* for member only when he see the corporate projects Await my actions */}
      {
        ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
        !temp?.isApproved && !currentTemplate?.isApproved && !showArchived && workState === 'Await my actions' &&
        businessId && userExists && changeShowProject === "Team Projects" &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className='z-[50] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      Material: f?.Material,
                      team: f?.team,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}
        </Card>}

      {/* for member only when he see own projects Await my actions */}
      {
        ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
        !temp?.isApproved && !currentTemplate?.isApproved && !showArchived && workState === 'Await my actions' &&
        businessId && changeShowProject === "My Projects" &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className=' z-[50] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      Material: f?.Material,
                      team: f?.team,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}

          <CardActions>
            <Button
              onClick={() => {
                setInstructionPopup(true)
                setFormData({
                  ...formData,
                  title: f?.title,
                  id: f?._id,
                  Material: f?.Material,
                  isMulti: f?.isMulti,
                  templateId: `${f?.templateId}`,
                  myId: `${userDetails?._id}`,
                  designerId: `${f?.designerId?._id}`,
                  purpose: f?.purpose,
                  files: f?.files,
                  demoImgUrl: f?.demoImgUrl,
                  projectDesc: f?.projectDesc,
                  placestoDecorate: {
                    where: f?.placestoDecorate?.where,
                    color: f?.placestoDecorate?.color
                  },
                  canvasSize: {
                    width: f?.canvasSize?.width,
                    height: f?.canvasSize?.height
                  }

                })
                getMultiimgData(idx)
              }}
              variant="solid"
              sx={{
                cursor: 'not-allowed',
              }}
              disabled={userDetails?.Role === 'corporate_admin' ? false : true}
            >Update instruction</Button>
          </CardActions>
        </Card>}

      {/* for client only in progress */}
      {
        (temp?._id || currentTemplate?._id) &&
        ((currentTemplate?._id && !currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && !temp?.reqApprove)) &&
        !temp?.isApproved && !currentTemplate?.isApproved && !showArchived && workState === 'In progress' &&
        !businessId && !isShowTeamProjects &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className=' z-[50] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      team: f?.team,
                      Material: f?.Material,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}
        </Card>}

      {/* for members to see own projects in progress */}
      {
        (temp?._id || currentTemplate?._id) &&
        !showArchived && workState === 'In progress' &&
        businessId && changeShowProject === "My Projects" &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className=' z-[50] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      Material: f?.Material,
                      team: f?.team,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}
        </Card>}

      {/* for members to see client projects in progress */}
      {
        (temp?._id || currentTemplate?._id) &&
        // (!temp?.isApproved && !currentTemplate?.isApproved) &&
        !showArchived && workState === 'In progress' &&
        businessId && userExists && changeShowProject === "Team Projects" &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className=' z-[50] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      Material: f?.Material,
                      templateId: `${f?.templateId}`,
                      team: f?.team,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      files: f?.files,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate && currentTemplates?.length === 0) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (currentTemplates?.length > 0) {
                        navigate(`/diy/${currentTemplates[currentTemplates?.length - 1]?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId && currentTemplates?.length === 0) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={handleOpen}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {/* {  (!f?.title || f?.Material) && <Chip sx={{bgcolor:'#d4d8fa',color:'black'}} size="md" color='neutral' variant='soft'>
        Designer will take care of it
      </Chip>} */}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}
            {/* <Chip size="lg" variant="soft">
        {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
      </Chip> */}
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>


          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}

        </Card>}



      {
        (temp?.isApproved === true || currentTemplate?.isApproved === true) && showArchived &&
        !businessId && !isShowTeamProjects &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color='success'
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => navigate(`/chats/chat/${f?._id}`)}
                className=' z-[50] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>

            <Chip size='lg' color='danger' variant='solid'>
              Approved
            </Chip>
            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}
            {f?.Material &&
              <Chip
                size='lg' variant='solid' color='danger'>
                {f?.Material.slice(0, 10)}...
              </Chip>}
            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {f?.purpose && <Chip size="lg" variant="soft">
              {f?.purpose}
            </Chip>}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || '#fffff'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md'></div>
                  </div>
                </div>
              </Chip>}
            <Chip size="lg" variant="soft">
              {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
            </Chip>
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>
          {
            f?.demoImgUrl &&
            <>
              <p className=' mb-[-5px]'>Demo Design Image</p>
              <img
                style={{
                  backgroundSize: 'cover',
                  width: '100%',

                }}
                className=' h-auto'
                src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
                srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
                loading="lazy"
                alt=""
              />
            </>}

          {/* if multiple image is present */}
          {
            f?.multiImgUrl?.length > 0 &&
            <Masonry columns={{ xs: 1, md: 2, lg: 2 }}

              spacing={1}>
              {f?.multiImgUrl?.map((image) => (
                <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
              ))}

            </Masonry>}

          {/* <CardActions>
      <Button
      onClick={()=>{
       setInstructionPopup(true)
       setFormData({...formData,
        title:f?.title,
        id:f?._id,
        isMulti:f?.isMulti,
        templateId:`${f?.templateId}`,
        myId:`${userDetails?._id}`,
        designerId:`${f?.designerId?._id}`,
        purpose:f?.purpose,
        files:f?.files,
        demoImgUrl:f?.demoImgUrl,
        projectDesc:f?.projectDesc,
        placestoDecorate:{
            where:f?.placestoDecorate?.where,
            color:f?.placestoDecorate?.color
          },
          canvasSize:{
            width:f?.canvasSize?.width,
            height:f?.canvasSize?.height
          }

    })
    getMultiimgData(idx)
      }}
      variant="solid">Update instruction</Button>
    </CardActions> */}
        </Card>}
      {
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
        >
          <DialogTitle id='alert-dialog-title'>
            Are you sure that you want to delete this ticket?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Deny</Button>
            <Button onClick={delInstruction}>Confirm</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}
