import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import axios from 'axios';
import { API } from '../../../../../backend';
import SingleCardDesign from './editCampaign/SingleCardDesign';
import dayjs from 'dayjs';
import { Modal, ModalClose, Sheet } from '@mui/joy';
import { Masonry } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip'
import { useSelector } from 'react-redux';

export default function CampCard({c,idx,setOpen,campaignData,setCampaignData,setDesignArray}) {
  const [openModel, setOpenModel] = React.useState(false);
  const userDetails = useSelector(state=>state.user.userDetail)  
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        position:"relative"
      }}
    >
       {/* see more button */}
       <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openModel}
        onClose={() => setOpenModel(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: {md:700,xs:"100%",lg:800,xl:900},
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            maxHeight:"100vh",
            msOverflowY:"auto",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
          Your Campaign Designs:
          </Typography>
          <div className=' h-[calc(100vh-110px)] pt-3 overflow-x-hidden overflow-y-auto'>
          <Masonry
          columns={{xs:1,md:2,lg:3,xl:4}} spacing={2}>
            {
            c?.designs?.map((d,id)=>(
                <SingleCardDesign d={d} idx={id}/>
            ))
            }
        </Masonry>
        </div>
        </Sheet>
      </Modal>

        <div className='flex font-medium text-zinc-600 absolute right-0 top-0 m-2.5 bg-green-100 rounded-3xl px-3 py-1.5'>
        {c?.user?.name?.length > 16 ?c?.user?.name.slice(0,16) +"...":c?.user?.name}
        </div>
      <CardContent>
        <p className=' font-bold text-xl'>{c?.title?.length > 10 ? c?.title.slice(0,10) +"...":c?.title}</p>
        <Typography sx={{mt:"-10px"}} level='body-lg'>
        {c?.desc}
        </Typography>
        <div className=' text-lg font-semibold max-w-[240px] bg-indigo-100 rounded-3xl px-3 py-1.5'>
            {dayjs(c?.startDate).format("DD MMM,YY")}  <span className=' mx-2 font-medium text-base'>to</span> {dayjs(c?.endDate).format("DD MMM,YY")}
        </div>
      </CardContent>
      <CardActions buttonFlex="0 1 120px">
        <IconButton variant="outlined" color="neutral" sx={{ mr: 'auto' }}>
          <FavoriteBorder />
        </IconButton>
        <Button
        variant='outlined'
        onClick={()=>setOpenModel(true)}
        >
        See more
        </Button>
        <Tooltip title={c?.user?._id === userDetails?._id?"":"You are not campaign creator"}>
        <Button
        disabled={c?.user?._id === userDetails?._id ? false : true}
        onClick={()=>{
            setDesignArray(c?.designs)  
            setCampaignData({...campaignData,
    id:`${c?._id}`,
    title:`${c?.title}`,
    desc:`${c?.desc}`,
    startDate:`${c?.startDate}`,
    endDate:`${c?.endDate}`,
    // designs:c?.designs
            })
            setOpen(true)}}
        variant="solid" color="primary">
          Edit
        </Button>
        </Tooltip>
      </CardActions>
    </Card>
  );
}