const initialState = {
title:'',
id:'',

//brfore creating new project this values are populated to the designer
templateDetail:{
//instruction id
instruction:'',
//client id
adminId:'',
//the title which is given by the client
title:''
}
};

const handlePageName = (state = initialState, action) => {

	switch (action.type) {

		case "ADD_TEMPTITLE":
		return {
         ...state,
		 title:state.title = action.payload
		}
        case "GET_ID":
            return {
             ...state,
             id:state.id = action.payload
            }
        default:
			return state;
	}
};

export default handlePageName;