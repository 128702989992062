import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { motion } from "framer-motion"
import CardCover from '@mui/joy/CardCover';
export default function MockUp({open,formData,setFormData,setOpen,urlArray,currentColor,imgUrl,imgArray}) {
  
  const ref = React.useRef(null);

  
  const [currentSize,setCurrentSize] = React.useState({
    width:'',
    height:''
  })
  console.log('current size is',currentSize)
  React.useEffect(()=>{
   setCurrentSize({...currentSize,width:formData?.canvasSize?.width,height:formData?.canvasSize?.height})
  },[formData])



  return (
    <React.Fragment>
{
(formData?.demoImgUrl || urlArray?.length > 0)  && currentColor  &&
<Button variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        See Mockup
      </Button>}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: {xs:'100%',md:500,lg:900,xl:'100vw'},
            p: 0,
            boxShadow: 'lg',
            height:{md:'100vh',xs:'100vh'}
          }}
        >
          <ModalClose variant="plain" sx={{ m: 0 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            p={1}
            
          >
            Your Design will looks like
          </Typography>
          <div
          ref={ref}
          style={{
            zIndex:99,
            backgroundColor:currentColor
          }}
          className=' w-full flex gap-3 overflow-auto h-[calc(100%-45px)]'>
{  !formData?.isMulti && <motion.img
            style={{
                width:`${Number(currentSize?.width)*18.6+'px'}`,
                height:`${Number(currentSize?.height)*18.6+'px'}`,
                backgroundSize:'cover'
            }}
            drag
            dragConstraints={ref}
            className='rounded-md object-cover'
            src={formData?.demoImgUrl} alt="" />}


            {formData?.isMulti && urlArray?.map((image,idx)=>(
              
              <motion.img
              style={{
                  resize:'both',
                  overflow:'auto',
                  width:`${imgArray[idx]?.width*18.6+'px'}`,
                  height:`${imgArray[idx]?.height*18.6+'px'}`,
                  backgroundSize:'cover'
              }}
              drag
              dragConstraints={ref}
              className='rounded-md object-cover'
              src={image} alt="" /> 

            //   <div className="container">
            //   <div ref={ref} className="resizeable">
            //     <div ref={refLeft} className="resizer resizer-l"></div>
            //     <div ref={refTop} className="resizer resizer-t"></div>
            //     <div ref={refRight} className="resizer resizer-r"></div>
            //     <div ref={refBottom} className="resizer resizer-b"></div>
            //   </div>
            // </div>

            //   <motion.Card
            //   drag
            //   dragConstraints={ref}
            //   sx={{ minHeight: '280px', width: 320,
            //   overflow: 'auto',
            //   resize: 'horizontal',
            //   }}>
            //   <CardCover>
            //     <img
            //       src={image}
            //       srcSet={image}
            //       loading="lazy"
            //       alt={idx}
            //     />
            //   </CardCover>
            //   <CardCover
            //     sx={{
            //       background:
            //         'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
            //     }}
            //   />
            // </motion.Card>
            ))}
         
          </div>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}