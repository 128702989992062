import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteStudio,
  getStudioData,
  inviteUserToStudio,
  removeUserfromStudio,
  updateRoles,
} from "../../../../redux/actions/userAction";
import { useEffect } from "react";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const People = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetail);
  const studios = useSelector((state) => state.user.studio);
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [rolesUpdated, setRolesUpdated] = useState(false);
  const [numInputFields, setNumInputFields] = useState(1);
  const [removed, setRemoved] = useState(false);
  const emailInputs = useRef(Array(numInputFields).fill(""));
  const [userInvited, setUserInvited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStudio, setSelectedStudio] = useState(null);
  const [selectedStudioId, setSelectedStudioId] = useState(null);

  console.log(studios);
  const handleRoleChange = (e, person, studio) => {
    const newRole = e.target.value;
    const uiid = studio.uiid;
    const email = person.email;

    // Check if the role already exists in the studio
    const roleExists = studio.people.some((person) => person.role === newRole);

    // Check if the new role is 'approver' or 'designer' and if it already exists
    if ((newRole === "approver" || newRole === "designer") && roleExists) {
      // Role already exists, prevent the assignment and inform the user
      alert(
        `A ${newRole} already exists in this studio. There can only be one ${newRole} per studio.`
      );
      return;
    }

    // Role doesn't exist or it's not 'approver' or 'designer', proceed with the assignment
    dispatch(
      updateRoles({
        uiid: uiid,
        role: newRole,
        email: email,
      })
    ).then(() => {
      dispatch(getStudioData())
        .then(() => {
          setRolesUpdated(true);
        });
    });    
  };

  const handleInviteClick = () => {
    if (selectedStudio) {
      const numMembers = selectedStudio.people.filter(
        (person) => person.status === "pending" && person.email === ""
      ).length;
      setShowInviteForm((prevState) => !prevState);
      console.log("numMembers ---------------->", numMembers);
      setNumInputFields(numMembers);
    }
  };

  const handleRemovePerson = (person, studio) => {
    dispatch(
      removeUserfromStudio({ uiid: studio.uiid, email: person.email })
    ).then(() => {
      setRemoved(true);
      dispatch(getStudioData());
    });
  };  

 

  const handleSendInvitation = () => {
    const emails = {};
    for (let i = 1; i <= numInputFields; i++) {
      const email = emailInputs.current[i - 1];
      emails[`mail${i}`] = email ? email : "";
    }

    if (selectedStudio) {
      const payload = {
        studioName: selectedStudio.studioName,
        uiid: selectedStudio.uiid,
        emails: emails,
      };
      console.log("Payload inside people =>", payload);
      dispatch(inviteUserToStudio(payload));
      alert("Invitation Sent!");
      setShowInviteForm(false);
      setNumInputFields(1);
      emailInputs.current = Array(1).fill("");
    }

    setUserInvited(true);
  };

  const handleStudioClick = (studio) => {
    console.log("clicked on studio =>", studio);
    setSelectedStudio(studio);
    setSelectedStudioId(studio._id);
  };

  const handleDeleteStudio = (studio) => {
    console.log("Clicked on the delete Button", studio.uiid);
    // Here You have to disptach the action to delete the studio
    dispatch(deleteStudio(studio.uiid));
  };
      //These studio are no longer in used /////////////////////////////////////////////////////
  // useEffect(() => {
  //   setIsLoading(true);
  //   dispatch(getStudioData()).then(() => {
  //     setIsLoading(false);
  //   });

  //   // Reset the form when a different studio is selected
  //   if (selectedStudioId) {
  //     setShowInviteForm(false);
  //     setNumInputFields(1);
  //     emailInputs.current = Array(1).fill("");
  //   }
  // }, [selectedStudioId, dispatch]);

  // useEffect(() => {
  //   if (rolesUpdated) {
  //     dispatch(getStudioData())
  //     setRolesUpdated(false);
  //   }
  // }, [rolesUpdated]);
  
  // useEffect(() => {
  //   if (removed) {
  //     dispatch(getStudioData())
  //     setRemoved(false);
  //   }
  // }, [removed]);
  
  // useEffect(() => {
  //   if (userInvited) {
  //     dispatch(getStudioData())
  //     setUserInvited(false);
  //   }
  // }, [userInvited]);

  // useEffect(() => {
  //   // Find the selected studio in the updated studios list
  //   const updatedSelectedStudio = studios.find(studio => studio._id === selectedStudioId);
    
  //   // Update selectedStudio
  //   setSelectedStudio(updatedSelectedStudio);
  //  }, [studios]);   

  

  return (
    <div className="mx-auto my-8 max-w-screen-lg bg-[#f1f5f9] p-4">
      <div className="flex items-center justify-between bg-[#e2e8f0] p-4 rounded-xl mb-4">
        <div className="flex-1">
          <span
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              display: "block",
              marginBottom: "0.5rem",
            }}
          >
            Great ideas are meant to be shared
          </span>
          <span style={{ fontSize: "1rem", display: "block" }}>
            Get collaboration and creativity flowing so jobs get done, fast.
          </span>
        </div>
        <div className="flex-shrink-0">
          {/* This is url for the image which is taken from canva.*/}
          <img
            src="https://static.canva.com/web/images/4868b83441bc565ff9cdfdb25218637d.svg"
            alt="Invite Image"
          />
        </div>
      </div>

      <div className="flex flex-wrap mb-4">
        {studios?.map((studio) => (
          <div
            key={studio._id}
            className="flex bg-[#e2e8f0] p-4 m-2 rounded-xl"
          >
            <button
              onClick={() => handleStudioClick(studio)}
              className={`flex-1 bg-blue-500 text-white p-2 rounded-md w-32 h-10 transition duration-300 ease-in-out 
        hover:bg-[#8b3dff] hover:text-white ${
          studio._id === selectedStudioId ? "bg-[#8b3dff]" : ""
        }`}
            >
              {studio.studioName}
            </button>
            <button
              className="flex-shrink-0 bg-[#e2e8f0] ml-4"
              onClick={() => handleDeleteStudio(studio)}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className="text-black-500 hover:text-red-700"
              />
            </button>
          </div>
        ))}
      </div>

      {userDetails && (
        <div className="flex bg-gray-200 p-2 mb-2 rounded-t-lg">
          <div className="flex-1 font-bold text-teal-700">Name</div>
          <div className="flex-1 font-bold text-teal-700">Email</div>
          <div className="flex-1 font-bold text-teal-700">Roles</div>
        </div>
      )}

      {userDetails && (
        <div className="flex border p-2">
          <div className="flex-1">{userDetails.name}</div>
          <div className="flex-1">{userDetails.emailid}</div>
          <div className="flex-1">Owner</div>
        </div>
      )}

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        selectedStudio &&
        selectedStudio.people.map(
          (person, index) =>
            person.email &&
            person.status === "complete" && (
              <div key={index} className="flex border p-2 items-center">
                <div className="flex-1">{person.email.split("@")[0]}</div>
                <div className="flex-1">{person.email}</div>
                <div className="flex-1">
                  <select
                    className="border rounded py-2 px-3 w-1/2"
                    value={person.role}
                    onChange={(e) =>
                      handleRoleChange(e, person, selectedStudio)
                    }
                  >
                    <option value="member">Member</option>
                    <option value="designer">Designer</option>
                    <option value="approver">Approver</option>
                  </select>
                </div>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 ml-4 rounded"
                  onClick={() => handleRemovePerson(person, selectedStudio)}
                >
                  Remove
                </button>
              </div>
            )
        )
      )}

      {selectedStudio && (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          onClick={handleInviteClick}
        >
          Invite Members
        </button>
      )}

      {showInviteForm && (
        <div className="bg-gray-100 border p-4 mt-4 rounded">
          {Array(numInputFields)
            .fill()
            .map((_, index) => (
              <div key={index} className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email {index + 1}:
                </label>
                <input
                  key={emailInputs.current[index]} // Add this line
                  type="email"
                  className="border rounded w-full py-2 px-3"
                  defaultValue={emailInputs.current[index]}
                  onChange={(e) => {
                    emailInputs.current[index] = e.target.value;
                  }}
                />
              </div>
            ))}

          <div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={(e) => handleSendInvitation(e)}
            >
              Send Invitation
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default People;
