import React from "react";
import LeftSideBar from "./SideNavBar/LeftSideBar";
import { useSearchParams } from "react-router-dom";
import "./Main.css";
import People from "./HeroPages/People";
// This is the code for the /studio route.
import Studios from "./HeroPages/Studios";
import StudioDetails from "./HeroPages/StudioDetails";

const Main = () => {
  const [searchParams] = useSearchParams();
  const studioParams = searchParams.get("studioParams");
  console.log(studioParams);
  return (
    <div className="grid grid-cols-4 h-screen">
      <div className="leftPanel col-span-1 bg-slate-100">
        <LeftSideBar />
      </div>
      <div className="rightPanel col-span-3 bg-slate-200">
        {studioParams === "1" ? (
          <StudioDetails />
        ) : studioParams === "2" ? (
          <People />
        ) : studioParams === "3" ? (
          <Studios />
        ) : <div className="text-4xl text-center relative top-20">Welcome</div>}
      </div>
    </div>
  );
};

export default Main;
