const initialState = {
	element: {},
	activeElement: "",
};

const handleClipboard = (state = initialState, action) => {
	switch (action.type) {
		case "COPY":
			return {
				...state,
				element: action.payload.element,
				activeElement: action.payload.activeElement,
			};

		default:
			return state;
	}
};

export default handleClipboard;
