let initialState = false;

const ScaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SCALE_ON":
      return action.payload.scale;
    default:
      return state;
  }
};

export default ScaleReducer;
