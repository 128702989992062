import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { API } from "../../../../backend";
import { useSelector } from "react-redux";

const SingleInvitation = () => {
  const token = JSON.parse(localStorage.getItem("myuser")).token;
  const [isLoading, setIsLoading] = useState(true);
  const [isDataSaved, setIsDataSaved] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getState = useSelector((state) => state);

  //   making the search params in an obj and store in a vairable
  const searchParamsObj = Object.fromEntries([...searchParams]);
  const payload = Object.entries(searchParamsObj)
    .filter(([key, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  console.log("payload inside singleInvitation", payload)

  console.log(searchParamsObj, "searchparamsobj");

  useEffect(() => {
    try {
      (async () => {
         
        const res = await axios.put(
          `${API}auth/single_invitation_to_studio`,
          { searchParamsObj: payload },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": `${token}`,
              
            },
            
          }
        );
        console.log(res, "response data");

        // if data successfully saved
        // setIsLoading(false);
        setIsDataSaved(true);
      })();
    } catch (error) {
      console.log(error, "from error");
      setIsLoading(false);
    }
  }, [searchParamsObj, token]);

  useEffect(() => {
    if (isDataSaved) {
      setIsDataSaved(false);
      navigate("/studio");
    }
  }, [isDataSaved, navigate]);

  if (isLoading) {
    return (
      <div className=" flex flex-col gap-2 justify-center items-center w-full h-[60dvh]">
        <FadeLoader color="#000" />
        <p className=" text-sm">Saving data please wait!</p>
      </div>
    );
  }

  return (
    <div>
      <p>Single invitation</p>
    </div>
  );
};

export default SingleInvitation;
