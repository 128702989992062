import * as React from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import Masonry from "@mui/lab/Masonry";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/joy/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import FileBase from "react-file-base64";
import Stack from "@mui/joy/Stack";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { API, API1 } from "../../../../../../../../../backend";
import { Textarea, Avatar, Link, Chip } from "@mui/joy";
import { SketchPicker, ChromePicker, GithubPicker } from "react-color";
import MockUp from "./MockUp";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewForm,
  getAllforms,
  selectForm,
  updateForm,
} from "../../../../../../../../../redux/actions/designerFormAction";
import { ToastContainer, toast } from "react-toastify";
import DesignerDropdown from "../dropdowns/DesignerDropdown";
import { event } from "jquery";
import ImageSingle from "./multiImage/ImageSingle";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Tooltip from "@mui/material/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSingle_User,
  sendMail,
} from "../../../../../../../../../redux/actions/userAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { showDotChat } from "../../../../../../../../../redux/actions/chatAction";
import music from "../../../../../../../../../images/audio/chat.mp3";
import SvgIcon from "@mui/joy/SvgIcon";
import { styled } from "@mui/joy";
import { useDropzone } from "react-dropzone";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
export default function InstructionsPopUp({
  setPopupDesignerId,
  open,
  setOpen,
  inputSize,
  setInputSize,
  popUpDesignerId,
  size,
  setSize,
  urlArray,
  setUrlArray,
  imgArray,
  setImgArray,
  formData,
  setFormData,
  allUsers
  // teamArray,setTeamArray
}) {
  // const [files, setFiles] = React.useState([]);
  // console.log('my files are',files)
  // const onDrop = (acceptedFiles) => {
  //   setFiles(acceptedFiles);
  // };

  // const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const businessId = JSON.parse(localStorage.getItem("businessId"));
  const fieldRef = React.useRef();
  const inputRef = React.useRef(null);
  const [imgUrl, setImgUrl] = React.useState();
  const [file, setFile] = React.useState();
  const [fileArray, setFileArray] = React.useState([]);
  const [admin, setAdmin] = React.useState(null);
  console.log("my admin is here", admin);
  const [alreadySelectedDesigner, setAlreadySelectedDesigner] =
    React.useState(false);
  const dispatch = useDispatch();
  const instructions = useSelector((state) => state.form);
  const singleUser = useSelector((state) => state.user.singleUser);
  const userDetails = useSelector((state) => state.user.userDetail);
  const formId = useSelector((state) => state.form.formId);
  const getState = useSelector((state) => state);
  const [pathName, setPathName] = React.useState(null);
  const navigate = useNavigate();
  let { pathname } = useLocation();
  console.log("formData is", formData);
  const [showMembers, setShowMembers] = React.useState(false);
  const [teamArray, setTeamArray] = React.useState([]);

   

  const getAdminData = async () => {
    if (businessId) {
      await axios
        .get(`${API}auth/getsingleUser/${businessId}`)
        .then(({ data }) => setAdmin(data));
    }
  };

  React.useEffect(() => {
    getAdminData();
  }, []);
  React.useEffect(() => {
    if (userDetails?._id) {
      dispatch(getSingle_User(userDetails?._id));
    }
  }, [userDetails?._id]);

  React.useEffect(() => {
    if (formData?.team) {
      setTeamArray(formData?.team);
    }
  }, [formData]);

  React.useEffect(() => {
    setFormData({ ...formData, team: teamArray });
  }, [teamArray]);

  //addign member to project
  const addMember = (email, id) => {
    const alreadyAdded = teamArray?.find((mail) => mail === email);
    if (!alreadyAdded) {
      setTeamArray([...teamArray, email]);
    }
  };

  //del member from teamArray
  const delMember = (id) => {
    const updatedMebmer = teamArray?.filter((t, idx) => idx !== id);
    setTeamArray(updatedMebmer);
  };

  React.useEffect(() => {
    // console.log("pathname :",pathname)
    setPathName(pathname);
  }, [pathname]);
  //   let data = useSelector((state) => state.users);
  //   console.log('Userdata========>>>>',data)
  // React.useEffect(()=>{
  // setFormData({...formData,designerId:`${popUpDesignerId}`})
  // },[popUpDesignerId])
  React.useEffect(() => {
    if (
      formData?.designerId &&
      pathName !== "/myForms" &&
      formData?.templateId
    ) {
      setPopupDesignerId(formData?.designerId);
    }
    if (formData?.id) {
      setAlreadySelectedDesigner(true);
    }
  }, [formData]);

  React.useEffect(() => {
    if (formData?.id) {
      setFileArray(formData?.files);
    }
  }, [formData]);

  const [showSize, setShowSize] = React.useState(false);

  //uploading multiple images
  const uploadImg = async (e, id) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("imgUrl", file);

    await axios.post(`${API}posters/uploadFile`,
      formData,
      )
      .then((response) => {
        console.log(response);
        // setImgUrl(response.data.data.fileSavedUrl)
        setUrlArray([...urlArray, response.data.data.fileSavedUrl]);
      });
    console.log(imgUrl);
  };

  console.log("my img files are", fileArray);

  React.useEffect(() => {
    setFormData({ ...formData, files: fileArray });
  }, [fileArray]);
  console.log("file array is", fileArray);

  //uploading single image
  const uploadImgSingle = async (e, id) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("imgUrl", file);

    await axios.post(`${API}posters/uploadFile`,
      formData,
      )
      .then((response) => {
        console.log(response);
        setImgUrl(response.data.data.fileSavedUrl);
      });
    console.log(imgUrl);
  };

  //uploading single file
  const uploadFileSingle = async (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("pdfUrl", file);

    await axios
      .post(`${API}posters/uploadFilePdf`,
        formData,
        )
      .then((response) => {
        console.log(response);
        setFileArray([...fileArray, response.data.data.fileSavedUrl]);
      });
    // console.log(fileUrl);
  };

  React.useEffect(() => {
    // setFormData({...formData,multiImgUrl:urlArray})
    setFormData({ ...formData, multiimgSize: imgArray });
  }, [imgArray]);

  console.log("updated form data ========>", formData);

  const addNewField = () => {
    setImgArray([
      ...imgArray,
      {
        width: null,
        height: null,
      },
    ]);
    console.log("updated image array", imgArray);
  };

  const delImg = (id) => {
    const updatedArray = imgArray?.filter((item, idx) => idx !== id);
    const updatedUrlArray = urlArray?.filter((item, idx) => idx !== id);
    setUrlArray(updatedUrlArray);
    setImgArray(updatedArray);
  };

  const goTop = () => {
    fieldRef.current.scrollIntoView({ behavior: "smooth" });
  };
  React.useEffect(() => {
    dispatch(getAllforms());
  }, []);
  console.log("instructions are", instructions?.form);

  const [users, setUsers] = React.useState([]);
  //getting all users
  const getUsers = async () => {
    axios
      .get(`https://dev.ehsprints.com:8000/auth/getUsersAll`,
        
      )
      .then(({ data }) => {
        setUsers(data);
      });
  };

  // React.useEffect(() => {
  //   getUsers();
  // }, []);
  //notydesigner
  // pushing the admin id to selected designerid array and pushing admin id to adminid array of user model
  const notifyDesigner = (designerId) => {
    const alreadyNotified = singleUser?.designerIds?.find(
      (d) => d?._id === designerId
    );
    const updateUser = async () => {
      await axios
        .put(`${API}auth/pushId/${designerId}?adminId=${userDetails?._id}`,
         )
        .then(() => {
          getUsers();
          dispatch(getSingle_User(userDetails?._id));
          dispatch(sendMail(designerId));
          if (!alreadyNotified) {
            toast.success(`Mail has been sent to the Designer`);
          }
        });
    };
    updateUser();
  };

  React.useEffect(() => {
    setFormData({ ...formData, demoImgUrl: imgUrl });
  }, [imgUrl]);
  console.log(formData);

  React.useEffect(() => {
    if (
      inputSize?.wight &&
      inputSize?.height &&
      !size?.wight &&
      !size?.height
    ) {
      setFormData({
        ...formData,
        canvasSize: {
          width: inputSize?.wight,
          height: inputSize?.height,
        },
      });
    } else if (
      size?.wight &&
      size?.height &&
      !inputSize?.wight &&
      !inputSize?.height
    ) {
      setFormData({
        ...formData,
        canvasSize: {
          width: size?.wight,
          height: size?.height,
        },
      });
    }
  }, [size, inputSize]);

  const canvasSizes = [
    {
      width: "16",
      height: "24",
    },
    {
      width: "20",
      height: "30",
    },
    {
      width: "24",
      height: "36",
    },
    {
      width: "36",
      height: "48",
    },
  ];
  const placestoDecorate = [
    {
      where: "Wall",
      color: "",
    },

    {
      where: "Floor",
    },
    {
      where: "Ceiling",
    },
  ];
  const position = [
    "Top",
    "Bottom",
    "Middle",
    "Top-Left Corner",
    "Left Corner",
    "Bottom-Left Corner",
    "Top-Right Corner",
    "Bottom-Right Corner",
    "Right Corner",
  ];
  const [selectedPosition, setSelectedPosition] = React.useState("");
  const [currentColor, setCurrentColor] = React.useState("#ffffff");
  const [colorChoosed, setColorChoosed] = React.useState(true);
  const [openMockUp, setOpenMockUp] = React.useState(false);
  const appStyle = {
    maxHeight: "800px",
    color: "white",
    backgroundColor: currentColor,
    transition: "ease all 300ms",
  };

  React.useEffect(() => {
    setFormData({
      ...formData,
      placestoDecorate: {
        ...formData.placestoDecorate,
        color: currentColor,
      },
    });
  }, [currentColor]);

  // const handleChangeComplete = (color) => {
  //   // console.log(color,"checkingcolor")
  //   setCurrentColor(color.hex);
  //   console.log(color);
  //   //  setColorChoosed(true)
  // };

  //form submition
  const handleSubmit = () => {
    if (formData?.id && formData?.designerId) {
      const updateForm = async () => {
        await axios
          .put(`${API}form/updateForm/${formData?.id}`, { formData, urlArray },
            )
          .then(({ data }) => {
            toast.success("Form is updated");
            dispatch(selectForm(""));
            dispatch(getAllforms());
            setOpen(false);
            setFormData({
              ...formData,
              designerId: "",
              templateId: "",
              id: "",
            });
          })
          .catch(() => {
            toast.error("Select your designer first");
            goTop();
          });
      };
      updateForm();
    } else if (!formData?.id && formData?.designerId) {
      const createForm = async () => {
        // console.log("TOKEN is: ", getState.xsrfToken?.csrf_token);
        await axios
          .post(`${API}form/createForm`,
            { formData, urlArray },
            )
          .then(({ data }) => {
            toast.success("Form is submited");
            dispatch(selectForm(""));
            dispatch(getAllforms());
            dispatch(showDotChat(true));
            const audio = new Audio(music);
            audio.play();

            if (pathName === "/diy/user" && !formData?.id) {
              if (formData?.templateId) {
                navigate(`/diy/${formData?.templateId}`);
                window.location.reload();
              } else {
                toast.success("Form is submited");
                navigate("/myforms");
                window.location.reload();
              }
            }

            setOpen(false);
            setFormData({
              ...formData,
              designerId: "",
              templateId: "",
              id: "",
            });
          })
          .catch(() => {
            setOpen(true);
            toast.error("Select your designer first", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
      };
      createForm();
      // dispatch(createNewForm(formData))
    } else if (!formData?.designerId && !formData?.id) {
      toast.error("Select your designer first");
      setOpen(true);
    }
  };

  //color Array
  const colArray = [
    "#9c1910",
    "#6c9608",
    "#0f658c",
    "#2d0f8c",
    "#7a1a91",
    "#9e0d69",
    "#944b0c",
  ];

  const randomColor = () => {
    for (let index = 0; index < colArray.length; index++) {
      return Math.floor(Math.random() * colArray?.length);
    }
  };
  const page = ["Single Page", "Multiple Page"];

  const [pageVarient, setPageVarient] = React.useState("Single Page");

  //getting the page varient to update it
  React.useEffect(() => {
    if (formData?.id) {
      if (formData?.multiPage === true) {
        setPageVarient("Multiple Page");
      } else setPageVarient("Single Page");
    }
  }, [formData]);

  const memberFormTeam = admin?.team?.filter((t) => t !== singleUser?.emailid);
  const onlyTeammembers = singleUser?.team?.filter(
    (t) => t !== singleUser?.emailid
  );

  return (
    <React.Fragment>
      <Modal open={open}>
        <ModalDialog
          onClick={() => setShowMembers(false)}
          sx={{
            width: { lg: "950px", md: "90%", xs: "100%" },
            position: "relative",
            my: 0,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {/* close icon */}
          <div className=" absolute right-0 top-0 m-2">
            <IconButton variant="outlined">
              <ClearIcon onClick={() => setOpen(false)} />
            </IconButton>
          </div>

          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <DialogTitle>Add Project Instructions</DialogTitle>
          <p className=" text-black">
            Fields with <span className=" text-red-600">*</span> are mandetory
            to fill.
          </p>
          {/* {instructions?.form[0]?.map((item)=>(
            <div>
              {item?._id}
            </div>
          ))} */}

          <MockUp
            formData={formData}
            setFormData={setFormData}
            imgArray={imgArray}
            urlArray={urlArray}
            imgUrl={imgUrl}
            currentColor={currentColor}
            open={openMockUp}
            setOpen={setOpenMockUp}
          />
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <div className=" flex gap-2 items-center">
              {page?.map((p, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setPageVarient(p);
                    if (p === "Multiple Page") {
                      setFormData({ ...formData, multiPage: true });
                    } else setFormData({ ...formData, multiPage: false });
                  }}
                  className={
                    pageVarient === p
                      ? " transition-all duration-200 cursor-pointer px-3 py-1.5 rounded-3xl bg-indigo-600 border-[1px] text-white border-indigo-300"
                      : " transition-all duration-200 cursor-pointer px-3 py-1.5 rounded-3xl bg-indigo-100 border-[1px] border-indigo-600"
                  }
                >
                  {p}
                </div>
              ))}
            </div>
            <br />
            <Stack spacing={2}>
              {
                // userDetails?.Role === 'corporate_admin' && !businessId &&
                <FormControl>
                  <FormLabel>Team</FormLabel>
                  <div className=" relative flex flex-wrap border-[1px] border-zinc-300 rounded-lg py-1 px-2  gap-2 my-auto">
                    {teamArray?.map((t, id) => (
                      <Chip variant="soft" key={id}>
                        {t}
                        <HighlightOffIcon
                          className=" ml-2 cursor-pointer"
                          onClick={() => {
                            delMember(id);
                            setShowMembers(false);
                          }}
                        />
                      </Chip>
                    ))}
                    <div className=" relative">
                      <input
                        placeholder="Add team members"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowMembers(true);
                        }}
                        className=" outline-none p-2"
                        type="text"
                      />
                      {showMembers &&
                        userDetails?.Role === "corporate_admin" &&
                        !businessId ? (
                        <div className=" absolute w-[300px] p-2 mt-2 z-[50] left-0 bg-zinc-500 rounded-md text-white">
                          <div className=" flex flex-col gap-2 ">
                            {onlyTeammembers?.map((mail, idx) => (
                              <div
                                key={idx}
                                className={
                                  teamArray?.find((t) => t === mail)
                                    ? " p-3 cursor-pointer rounded-md bg-zinc-950"
                                    : " p-3 cursor-pointer rounded-md hover:bg-zinc-950"
                                }
                                onClick={() => {
                                  addMember(mail, idx);
                                  setShowMembers(false);
                                }}
                              >
                                {mail}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <>
                          {showMembers && businessId && (
                            <div className=" absolute w-[300px] p-2 mt-2 z-[50] left-0 bg-zinc-500 rounded-md text-white">
                              <div className=" flex flex-col gap-2 ">
                                {memberFormTeam?.map((mail, idx) => (
                                  <div
                                    key={idx}
                                    className={
                                      teamArray?.find((t) => t === mail)
                                        ? " p-3 cursor-pointer rounded-md bg-zinc-950"
                                        : " p-3 cursor-pointer rounded-md hover:bg-zinc-950"
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      addMember(mail, idx);
                                      setShowMembers(false);
                                    }}
                                  >
                                    {mail}
                                  </div>
                                ))}

                                <div
                                  className={
                                    teamArray?.find((t) => t === admin?.emailid)
                                      ? " p-3 cursor-pointer rounded-md bg-zinc-950"
                                      : " p-3 cursor-pointer rounded-md hover:bg-zinc-950"
                                  }
                                  onClick={() => {
                                    addMember(
                                      admin?.emailid,
                                      teamArray?.length - 1
                                    );
                                    setShowMembers(false);
                                  }}
                                >
                                  {admin?.emailid}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </FormControl>
              }

              <FormControl>
                <div ref={fieldRef} className=" flex justify-between"
                  onClick={getUsers}
                >
                  {(!alreadySelectedDesigner || !formData?.id) && (
                    <DesignerDropdown
                      users={users}
                      notifyDesigner={notifyDesigner}
                      popUpDesignerId={popUpDesignerId}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  )}
                  {!formData?.id && (
                    <div className=" flex gap-2 my-auto mt-[-4px]">
                      {/* <Button>
                  Start from scratch
                </Button> */}

                      <Link
                        href="https://ehsprints.com/"
                        // href='http://localhost:3000/'
                        target="_blank"
                        rel="noreferrer"
                        sx={{
                          bgcolor: "#013659",
                          borderRadius: "7px",
                          color: "whitesmoke",
                          textDecoration: "none",
                          py: 0.7,
                        }}
                        className=" hover:bg-[#013659] px-3"
                        endDecorator={
                          <>
                            <img
                              className=" rounded-md w-[50px] h-auto"
                              src="https://ehsprints.com/static/media/EhsLogo.fbcb09e992b82d4f8e9b7c09c382f4f0.svg"
                              alt=""
                            />
                          </>
                        }
                      >
                        <p className=" text-white my-auto">Edit from </p>
                      </Link>
                    </div>
                  )}
                </div>
              </FormControl>

              <FormControl>
                <FormLabel>
                  Project Title <span className=" text-red-600">*</span>
                </FormLabel>
                <Input
                  name="title"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  required
                  placeholder="Title here…"
                />
              </FormControl>

              <FormControl>
                <FormLabel>
                  Description <span className=" text-red-600">*</span>
                </FormLabel>
                <Textarea
                  value={formData?.projectDesc}
                  onChange={(e) =>
                    setFormData({ ...formData, projectDesc: e.target.value })
                  }
                  placeholder="Type here…"
                  //   defaultValue="Try to put text longer than 4 lines."
                  minRows={2}
                  maxRows={4}
                  required
                // className="outline-none"
                />
              </FormControl>

              {/* <FormControl>
                <FormLabel>Project Purpose</FormLabel>
                <Input
                  placeholder="Type here…"
                  value={formData?.purpose}
                  onChange={(e) =>
                    setFormData({ ...formData, purpose: e.target.value })
                  }
                />
              </FormControl> */}
              { !formData?.Material && (
                <>
                  {/* <FormControl>
                    <FormLabel>Select Place for your design</FormLabel>
                    <div className=" flex justify-between gap-2 flex-wrap my-auto">
                      <Select
                        placeholder="Select Place"
                        indicator={<KeyboardArrowDown />}
                        sx={{
                          width: { xs: "100%", md: 240 },
                          [`& .${selectClasses.indicator}`]: {
                            transition: "0.2s",
                            [`&.${selectClasses.expanded}`]: {
                              transform: "rotate(-180deg)",
                            },
                          },
                        }}
                      >
                        {placestoDecorate?.map((item, idx) => (
                          <Option
                            key={idx}
                            value={item?.where}
                            // onClick={()=>{
                            //     setInputSize({...inputSize,wight:'',height:''})
                            //     setSize({...size,
                            //         wight:item?.width,
                            //         height:item?.height})
                            // }}
                            onClick={() => {
                              setSelectedPosition(item?.where);
                              setFormData({
                                ...formData,
                                placestoDecorate: {
                                  ...formData.placestoDecorate,
                                  where: item?.where,
                                },
                              });
                            }}
                          >
                            {item?.where}
                          </Option>
                        ))}
                      </Select>
                      {selectedPosition === "Wall" && (
                        <div className=" flex gap-2 my-auto cursor-pointer">
                          <p className=" my-auto">Choose wall color:</p>
                          <div
                            onClick={() => setColorChoosed(!colorChoosed)}
                            className=" flex gap-2 my-auto relative"
                          >
                            <div
                              style={{
                                backgroundColor: `${currentColor}`,
                              }}
                              className=" w-[30px] border-[1px] border-zinc-600 rounded-lg h-[30px]"
                            ></div>
                            <p className=" my-auto">{currentColor}</p>
                          </div>
                          {!colorChoosed && (
                            <div
                              style={{
                                zIndex: 99,
                              }}
                              className=" absolute right-0 mt-[-10px] top-0"
                            >
                              <div className=" flex flex-row-reverse"> 
                                <button
                                  onClick={() => setColorChoosed(true)}
                                  className=" bg-zinc-100 py-1 px-1 mb-1 rounded-md hover:bg-zinc-800 hover:text-white transition-all duration-200"
                                >
                                  <ArrowBackIcon />
                                </button>
                              </div>
                              <SketchPicker
                                color={currentColor}
                                onChangeComplete={handleChangeComplete}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </FormControl> */}
{/* 
                  <FormControl>
                    <FormLabel>Select Position for your design</FormLabel>

                    <Select
                      placeholder="Select Position"
                      indicator={<KeyboardArrowDown />}
                      sx={{
                        width: { xs: "100%", md: 240 },
                        [`& .${selectClasses.indicator}`]: {
                          transition: "0.2s",
                          [`&.${selectClasses.expanded}`]: {
                            transform: "rotate(-180deg)",
                          },
                        },
                      }}
                    >
                      {position?.map((item, idx) => (
                        <Option
                          key={idx}
                          value={item}
                          // onClick={()=>{
                          //     setInputSize({...inputSize,wight:'',height:''})
                          //     setSize({...size,
                          //         wight:item?.width,
                          //         height:item?.height})
                          // }}
                          onClick={() => {
                            setFormData({ ...formData, position: item });
                            // setSelectedPosition(item?.where)
                          }}
                        >
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </FormControl> */}

                  <FormControl>
                    {!formData?.isMulti && (
                      <>
                        <FormLabel>Select canvas size</FormLabel>
                        <div className=" flex justify-between gap-2 flex-wrap my-auto">
                          <Select
                            placeholder="Select Size"
                            indicator={<KeyboardArrowDown />}
                            sx={{
                              width: { xs: "100%", md: 240 },
                              [`& .${selectClasses.indicator}`]: {
                                transition: "0.2s",
                                [`&.${selectClasses.expanded}`]: {
                                  transform: "rotate(-180deg)",
                                },
                              },
                            }}
                          >
                            {canvasSizes?.map((item, idx) => (
                              <Option
                                key={idx}
                                value={
                                  !inputSize?.wight || !inputSize?.height
                                    ? `${item?.width}*${item?.height}`
                                    : ""
                                }
                                onClick={() => {
                                  setInputSize({
                                    ...inputSize,
                                    wight: "",
                                    height: "",
                                  });
                                  setSize({
                                    ...size,
                                    wight: item?.width,
                                    height: item?.height,
                                  });
                                }}
                              >
                                {item?.width} * {item?.height} inch
                              </Option>
                            ))}
                          </Select>
                          <div className=" flex gap-2 my-auto">
                            <p className=" my-auto">Custom Size:</p>
                            <Input
                              className=" w-[140px]"
                              type="number"
                              placeholder="width (inch)"
                              value={inputSize?.wight}
                              onChange={(e) => {
                                setSize({ ...size, wight: "", height: "" });
                                setInputSize({
                                  ...inputSize,
                                  wight: e.target.value,
                                });
                              }}
                              slotProps={{
                                input: {
                                  ref: inputRef,
                                  min: 2,
                                  max: 48,
                                  step: 0.1,
                                },
                              }}
                            />
                            <Input
                              className=" w-[140px]"
                              type="number"
                              placeholder="height (inch)"
                              value={inputSize?.height}
                              onChange={(e) => {
                                setSize({ ...size, wight: "", height: "" });
                                setInputSize({
                                  ...inputSize,
                                  height: e.target.value,
                                });
                              }}
                              slotProps={{
                                input: {
                                  ref: inputRef,
                                  min: 2,
                                  max: 48,
                                  step: 0.1,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {/* radio for giving multiple design */}

                    {/* { !formData?.templateId &&
    <RadioGroup
      aria-label="platform"
      defaultValue={ formData?.isMulti?"Multiple design":"single design"}
      overlay
      name="platform"
      sx={{
        flexDirection: 'row',
        mt:4,
        gap: 2,
        [`& .${radioClasses.checked}`]: {
          [`& .${radioClasses.action}`]: {
            inset: -1,
            border: '3px solid',
            borderColor: 'primary.500',
          },
        },
        [`& .${radioClasses.radio}`]: {
          display: 'contents',
          '& > svg': {
            zIndex: 2,
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            bgcolor: 'background.surface',
            borderRadius: '50%',
          },
        },
      }}
    >
      {["single design","Multiple design"].map((value) => (
        <Sheet
          key={value}
          variant="outlined"
          sx={{
            borderRadius: 'md',
            boxShadow: 'sm',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1.5,
            p: 2,
            minWidth: 120,
          }}
        >
          <Radio
          
          onChange={()=>{
            if (value ==="Multiple design") {
             setFormData({...formData,isMulti:true}) 
            }else
            setFormData({...formData,isMulti:false})
          }}
          id={value} value={value} checkedIcon={<CheckCircleRoundedIcon />} />
          <Avatar variant="soft" size="sm" />
          <FormLabel htmlFor={value}>{value}</FormLabel>
        </Sheet>
      ))}
    </RadioGroup>} */}
                  </FormControl>
                </>
              )}

              {/* if form is created from ehsprints */}

              {formData?.Material && (
                <div className=" mt-4">
                  Selected dimension:
                  <span className=" bg-indigo-600 cursor-pointer ml-2 px-3 py-1.5 rounded-3xl text-white">
                    {formData?.canvasSize?.width} inch {"    "} X{"    "}{" "}
                    {formData?.canvasSize?.height} inch
                  </span>
                  <br />
                  <br />
                  <div className=" flex my-auto gap-1">
                    <p className=" my-auto">Selected material:</p>
                    <p className=" truncate w-[500px] my-auto bg-indigo-600 cursor-pointer ml-2 px-3 py-1.5 rounded-3xl text-white">
                      {formData?.Material}
                    </p>
                  </div>
                </div>
              )}

              {/* multiple design only whene no template id is present */}
              {formData?.isMulti && !formData?.templateId && (
                <FormControl>
                  {/* multiple file upload */}
                  <Masonry
                    columns={{ xs: 1, md: 2, lg: 3 }}
                    sx={{
                      my: 3,
                    }}
                    spacing={2}
                  >
                    {imgArray?.map((item, id) => (
                      <>
                        {!urlArray[id] ? (
                          <div className=" w-full flex flex-col justify-center items-center px-2 py-6 rounded-xl border-[1px] border-zinc-500 relative text-center">
                            {id !== 0 && (
                              <div
                                onClick={() => delImg(id)}
                                className="  bg-zinc-800 rounded-full cursor-pointer p-2 
                     transition-all duration-200
                    hover:bg-black absolute right-0 top-0  mt-2 mr-2 border-[1px] border-indigo-100"
                              >
                                <DeleteForeverIcon className=" text-rose-400" />
                              </div>
                            )}
                            <svg
                              className="mx-auto h-12 w-12 text-black"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                for="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                              >
                                <span className="">
                                  Upload file No {id + 1}
                                </span>
                                <input
                                  onChange={(e) => {
                                    uploadImg(e, id);
                                  }}
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  accept="image/png, image/jpeg image/svg "
                                />
                              </label>
                              <p className="pl-1 text-black">
                                or drag and drop
                              </p>
                            </div>
                            <p className="text-xs text-black">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        ) : (
                          <ImageSingle
                            formData={formData}
                            setFormData={setFormData}
                            delImg={delImg}
                            urlArray={urlArray}
                            DeleteForeverIcon={DeleteForeverIcon}
                            id={id}
                            imgArray={imgArray}
                            setImgArray={setImgArray}
                          />
                        )}
                      </>
                    ))}
                  </Masonry>
                </FormControl>
              )}
              {/* add field */}
              {!formData?.templateId &&
                formData?.isMulti &&
                !formData?.templateId && (
                  <div className=" flex flex-row-reverse">
                    <Tooltip title="Add more designs">
                      <IconButton
                        className=" w-[50px]"
                        onClick={addNewField}
                        variant="outlined"
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}

              {!formData?.isMulti && (
                <FormControl>
                  <div className=" grid gap-2 grid-cols-2">
                    <div className=" relative">
                      <FormLabel>Mock Image</FormLabel>
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2  border-gray-300 border-dashed rounded-md">
                        {!formData?.demoImgUrl ? (
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-black"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                for="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                              >
                                <span className="">Upload a file</span>
                                <input
                                  accept="image/png, image/jpeg, image/gif"
                                  onChange={(e) => uploadImgSingle(e)}
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                />
                              </label>
                              <p className="pl-1 text-black">
                                or drag and drop
                              </p>
                            </div>
                            <p className="text-xs text-black">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        ) : (
                          <div className="">
                            {!formData?.Material && (
                              <Tooltip title="Change image">
                                <div
                                  onClick={() => setImgUrl("")}
                                  className="rounded-full cursor-pointer p-1
                     transition-all duration-200
                    absolute right-0 top-0 mr-2.5 mt-[40px] border-[1px] border-indigo-100"
                                >
                                  <HighlightOffIcon className=" text-blue-500 scale-[2] hover:text-blue-700 transition-all duration-200 " />
                                </div>
                              </Tooltip>
                            )}
                            <img
                              style={{
                                backgroundSize: "cover",
                              }}
                              className=" rounded-lg mt-[-10px] max-w-[300px] bg-no-repeat object-cover"
                              src={formData?.demoImgUrl}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className=" w-full">
                      <FormLabel>Reference Files</FormLabel>

                      <div className=" flex gap-2 flex-col">
                        {fileArray?.map((file, id) => (
                          <div
                            className=" relative bg-indigo-100 w-full px-6 rounded-md py-3 flex gap-7 items-center"
                            key={id}
                          >
                            <svg
                              style={{
                                backgroundColor: `${colArray[randomColor()]}`,
                              }}
                              className=" scale-[2.5] p-1 rounded-md text-white"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 2.5C3 2.22386 3.22386 2 3.5 2H9.08579C9.21839 2 9.34557 2.05268 9.43934 2.14645L11.8536 4.56066C11.9473 4.65443 12 4.78161 12 4.91421V12.5C12 12.7761 11.7761 13 11.5 13H3.5C3.22386 13 3 12.7761 3 12.5V2.5ZM3.5 1C2.67157 1 2 1.67157 2 2.5V12.5C2 13.3284 2.67157 14 3.5 14H11.5C12.3284 14 13 13.3284 13 12.5V4.91421C13 4.51639 12.842 4.13486 12.5607 3.85355L10.1464 1.43934C9.86514 1.15804 9.48361 1 9.08579 1H3.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5H7.5C7.77614 5 8 4.77614 8 4.5C8 4.22386 7.77614 4 7.5 4H4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H4.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H10.5C10.7761 11 11 10.7761 11 10.5C11 10.2239 10.7761 10 10.5 10H4.5Z"
                                fill="currentColor"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                            <Tooltip title="Download">
                              <a
                                href={file}
                                download={`${formData?.title || "EHSFile"} ${id + 1
                                  }`}
                              >
                                {formData?.title || "EHSFile"} {id + 1}
                              </a>
                            </Tooltip>

                            <div
                              onClick={() => {
                                const updatedFileArray = fileArray?.filter(
                                  (f, i) => i !== id
                                );
                                setFileArray(updatedFileArray);
                              }}
                              className="rounded-full cursor-pointer p-1
                     transition-all duration-200
                    absolute right-0 top-0 m-2 border-[1px] border-indigo-100"
                            >
                              <HighlightOffIcon className=" text-blue-500 scale-125 hover:text-blue-700 transition-all duration-200 " />
                            </div>
                          </div>
                        ))}

                        {fileArray?.length < 5 && (
                          <input
                            type="file"
                            accept="application/pdf"
                            onChange={(e) => uploadFileSingle(e)}
                          // className=' opacity-0'
                          />
                        )}

                        {/* <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <button onClick={handleUpload}>Upload</button> */}
                      </div>
                    </div>
                  </div>
                </FormControl>
              )}
              <Button type="submit">{formId ? "Update" : "Submit"}</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
