import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API, API1 } from '../../backend'
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import SpokeIcon from '@mui/icons-material/Spoke';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MoreVert from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip'
import ChatIcon from '@mui/icons-material/Chat';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import { selectProjectChat } from '../../redux/actions/chatAction';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import { Box, Avatar, Button, Card, CardActions, CardContent, Chip, Typography } from '@mui/joy';
import PreviewIcon from '@mui/icons-material/Preview';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
export default function TeamForm({ f, singleUser, id, formData, setFormData, setInstructionPopup }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [allTemps, setAlltemps] = useState([])
  const [showMsgicon, setShowMsgicon] = useState(false)
  const isTeamProject = singleUser?.team?.find((t) => t === f?.myId?.emailid)

  const getState = useSelector((state) => state)

   

  //getting all unpublished templates
  const getTemp = async () => {
    await axios.get(`${API}diy/gettemplates`)
      .then(({ data }) => setAlltemps(data))
      .catch((error) => console.log(error))
  }
  useEffect(() => {
    getTemp()
  }, [])

  // checking the template is created or not
  const currentTemplate = allTemps?.find((t) => t?.instruction?._id === f?._id)
  return (
    <>
      {
        isTeamProject &&
        <Card
          onMouseLeave={() => setShowMsgicon(false)}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            dispatch(selectProjectChat(f))
            setShowMsgicon(true)
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          {
            // showMsgicon &&
            <Tooltip title="start group chat">
              <div
                onClick={() => {
                  navigate(`/chats/chat/${f?._id}`)
                }}
                className=' z-[50] cursor-pointer absolute right-0 scale-125 bottom-0 mr-3 mb-3 p-2 bg-zinc-700 rounded-full'>
                <ChatIcon />
              </div>
            </Tooltip>}
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                {/* <MenuItem
        disabled={singleUser?.Role ==='corporate_admin'?false:true}
              onClick={()=>{
                setInstructionPopup(true)
                setFormData({...formData,
                 title:f?.title,
                 id:f?._id,
                 isMulti:f?.isMulti,
                 team:f?.team,
                 multiPage:f?.multiPage,
                 Material:f?.Material,
                 templateId:`${f?.templateId}`,
                 myId:`${singleUser?._id}`,
                 designerId:`${f?.designerId?._id}`,
                 purpose:f?.purpose,
                 files:f?.files,
                 demoImgUrl:f?.demoImgUrl,
                 projectDesc:f?.projectDesc,
                 placestoDecorate:{
                     where:f?.placestoDecorate?.where,
                     color:f?.placestoDecorate?.color
                   },
                   canvasSize:{
                     width:f?.canvasSize?.width,
                     height:f?.canvasSize?.height
                   }
         
             })
               }}
        >
          <ListItemDecorator>
            <Edit />
          </ListItemDecorator>{' '}
          Edit instruction
        </MenuItem> */}
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate) {
                        navigate(`/diy/${currentTemplate?._id}`)
                        window.location.reload()
                      }
                      if (f?.templateId) {
                        navigate(`/diy/${f?.templateId}`)
                        window.location.reload()
                      }
                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>
                }
                <ListDivider />
              </Menu>
            </Dropdown>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>

            <Chip
              size='md' variant='soft' color='warning' sx={{ bgcolor: '#e7defa', color: 'black', px: 1.5 }}>
              {f?.myId?.name}
            </Chip>
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && currentTemplate?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {currentTemplate?.title &&
              <Chip
                onClick={() => navigate(`/diy/${currentTemplate?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {currentTemplate?.title}
              </Chip>}

            {/* { f?.Material &&
<Chip
size='lg' variant='solid' color='danger'>
{f?.Material.slice(0,37)}...
      </Chip>} */}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {/* {  f?.purpose && <Chip size="lg" variant="soft">
        {f?.purpose}
      </Chip>} */}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || 'red'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md bg-red-400'></div>
                  </div>
                </div>
              </Chip>}

          </Box>
          <div>
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>
        </Card>

      }
    </>
  )
}
