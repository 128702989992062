import axios from "axios";

import { API } from "../../backend";
export const getAllforms = () => async (dispatch, getState) => {

  const getforms = async () => {
    return await axios
      .get(`${API}form/getforms`)
      .then((res) => res)
      .catch((err) => {
        console.log(err);
        return null;
      });
  };
  const res = await getforms();
  // console.log(res,"-----")
  dispatch({
    type: "FETCH_ALL_FORM",
    payload: res?.data,
  });
};

export const createNewForm = (data) => async (dispatch, getState) => {
  const createForm = async () => {
    return await axios
      .post(`${API}form/createForm`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };
  const res = await createForm();
  dispatch({
    type: "CREATE_FORM",
    payload: res.data
  });
  dispatch(getAllforms())
};

export const updateForm = (id, data) => async (dispatch, getState) => {
  const update = async () => {
    return await axios
      .put(`${API}form/updateForm/${id}`,
        data,
        )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };
  const res = await update();
  dispatch({
    type: "UPDATE_FORM",
    payload: res.data
  });
};

export const delForm = (data) => async (dispatch, getState) => {
  const del = async () => {
    return await axios
      .delete(`${API}form/delete/${data}`) //here data means _id of the form
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };
  await del();
  dispatch({
    type: "DELETE_FORM",
    payload: data
  });
};

export const selectForm = (data) => async (dispatch) => {
  dispatch({
    type: "SELECTED_FORM",
    payload: data
  });
}

// export const postersubcategory = (data, skip, limit) => async (dispatch) => {
//   const getDataBySubCat = async (subCat) => {
//     if (data === "all categories") {
//       return await axios
//         .get(`${API}posters/getPoster`)
//         .then((res) => res)
//         .catch((err) => {
//           console.log(err);
//           return null;
//         });
//     } else {
//       // console.log(data,"***********************")
//       return await axios
//         .get(`${API}posters/getPosterByCatSubCat`, {
//           params: { subCategorySlug: data, skip, limit },
//         })
//         .then((res) => res)
//         .catch((err) => {
//           console.log(err);
//           return null;
//         });
//     }
//   };
//   // console.log("hello")
//   const res = await getDataBySubCat(data);
//   console.log("Poster", res.data);
//   dispatch({
//     type: "GET_POSTER_SUBCATEGORY",
//     payload: res.data.data.postersExists,
//   });
//   dispatch({
//     type: "GET_POSTER_COUNT",
//     payload: res.data.data.count,
//   });
// };
// export const addingReview = (review, posterId) => async (dispatch) => {
//   try {
//     let payload = {
//       ...review,
//       poster_obj_id: posterId,
//     };
//     // console.log("rev-",p)
//     const myuser = JSON.parse(localStorage.getItem("myuser"));
//     const response = await fetch(`${API}posters/insertUpdateRating`, {
//       method: "POST",
//       body: JSON.stringify(payload),
//       headers: {
//         "Content-Type": "application/json",
//         "x-access-token": `${myuser.token}`,
//       },
//     });
//     const data = await response.json();
//     // console.log("review",data)
//   } catch (error) {
//     console.log(error);
//   }
// };
