import { combineReducers } from "redux";

import { handleUser } from "./userReducer";
import handleCart from "./cartReducer";
import handlePoster from "./posterReducer";
import handleProduct from "./productReducer";
import handleItem from "./categoryReducer";
import handleDIY from "./rootDIY";
import handleForm from "./formReducer";
import handleChat from "./chatReducer";
import handleLanguage from "./languageReducer";
import handleInstruction from "./handleInstructionDetail";
import { LoadingReducer } from "./handleLoadigsReducer";



const rootReducer = combineReducers({
	instructionDetail:handleInstruction,
	lang:handleLanguage,
	chat:handleChat,
	cart: handleCart,
	poster: handlePoster,
	user: handleUser,
	product: handleProduct,
	category: handleItem,
	projects: handleDIY,
	form:handleForm,
	loadingTool:LoadingReducer,
});

export default rootReducer;
