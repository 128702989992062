import React, { useEffect } from "react";
import DOMPurify from "dompurify";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const myuser = JSON.parse(localStorage.getItem("myuser"));
  let token = myuser?.token;
  const location = useLocation();

  const webUrl = window.location.href;

  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get("from");
  const returnUrl = urlParams.get("returnUrl");

  // if (token) {
  //   JSON.stringify(sessionStorage.setItem("fromUrl", source));
  //   JSON.stringify(sessionStorage.setItem("returnUrl", returnUrl));
  //   JSON.stringify(sessionStorage.setItem("fullUrl", webUrl));
  //   return children;
  // } else if (token === null || token === undefined) {
  //   JSON.stringify(sessionStorage.setItem("fromUrl", source));
  //   JSON.stringify(sessionStorage.setItem("returnUrl", returnUrl));
  //   JSON.stringify(sessionStorage.setItem("fullUrl", webUrl));

  //   console.log(source, returnUrl);

  function sanitizeInput(input) {
    return DOMPurify.sanitize(input);
  }

  if (token) {
    JSON.stringify(sessionStorage.setItem("fromUrl", sanitizeInput(source)));
    JSON.stringify(sessionStorage.setItem("returnUrl", sanitizeInput(returnUrl)));
    JSON.stringify(sessionStorage.setItem("fullUrl", sanitizeInput(webUrl)));
    return children;
  } else if (token === null || token === undefined) {
    JSON.stringify(sessionStorage.setItem("fromUrl", sanitizeInput(source)));
    JSON.stringify(sessionStorage.setItem("returnUrl", sanitizeInput(returnUrl)));
    JSON.stringify(sessionStorage.setItem("fullUrl", sanitizeInput(webUrl)));

    // console.log(sanitizeInput(source), sanitizeInput(returnUrl));
    return (
      <Navigate to="/signin" state={{ from: location }} replace></Navigate>
    );
  }
};

export default PrivateRoute;
