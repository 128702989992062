import axios from "axios";

import { API } from "../../backend";

export const selectProjectChat = (data) => async (dispatch)=>{
  dispatch({
    type: "SELECTED_PROJECT",
    payload: data
  });
}

export const showDotChat = (data) => async (dispatch)=>{

    dispatch({
      type: "SHOW_DOT",
      payload: true
    });
  }

  export const hideDotChat = (data) => async (dispatch)=>{

    dispatch({
      type: "HIDE_DOT",
      payload: false
    });
  }


