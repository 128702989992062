import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API } from '../../../../../../backend'
import { Button } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function SingleCardDesign({ d, idx }) {
    const [currentTemp, setCurrentTemp] = React.useState(null)
    const [onHoverShow, setOnHoverShow] = useState(false)
    const navigate = useNavigate()

    const getState = useSelector((state) => state)

     

    const getCurrentTemp = async () => {
        await axios.get(`${API}diy/gettemplate/${d}`)
            .then(({ data }) => {
                setCurrentTemp(data)
            })
    }
    useEffect(() => {
        getCurrentTemp()
    }, [d])
    console.log("Currenttempis=========>>>", currentTemp)
    return (
        <div
            onMouseEnter={() => setOnHoverShow(true)}
            onMouseLeave={() => setOnHoverShow(false)}
            className='hover:translate-y-[-3px] duration-200 transition-all  relative'>
            {onHoverShow && <div
                style={{
                    background: "linear-gradient(90deg, rgba(114,130,206,0.46551120448179273) 0%, rgba(129,114,215,0.4431022408963585) 99%)"
                }}
                className=' backdrop-blur-sm z-[20] transition-all duration-200 absolute w-full h-full top-0 left-0 rounded-lg'>
                <div
                    style={{
                        transform: "translate(-50%,-50%)"
                    }}
                    className=' absolute top-[50%] left-[50%]'>
                    <Button
                        onClick={() => {
                            navigate(`/diy/${d}`)
                            // window.location.reload()
                        }}
                        sx={{
                            fontSize: { xl: "11px", md: "16px", xs: "20px" },
                            py: 1,
                            border: "1px solid white"
                        }}
                        color='neutral'
                    >
                        See Design
                    </Button>
                </div>
            </div>}
            <img
                style={{
                    border: "1px solid #cac3f7"
                }}
                className=' cursor-pointer w-full transition-all duration-300 h-auto rounded-lg'
                src={currentTemp?.imgUrl} alt="" />
        </div>
    )
}
