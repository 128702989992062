let initialState = true;

const CreateNew = (state = initialState, action) => {
	switch (action.type) {
		case "CREATNEW_ON":
			return true;

		case "CREATNEW_OFF":
			return false;

		default:
			return state;
	}
};

export default CreateNew;
