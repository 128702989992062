import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip'

export default function ShowImgmodel({imgSrc,open,setOpen}) {

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            // width: {xs:'100%',md:700,lg:'75vw'},
            borderRadius: 'md',
            boxShadow: 'lg',
            maxWidth:'800px',
            maxHeight:'100vh'
          }}
        >
          <ModalClose color='neutral' variant='solid' sx={{ p: 1 }} />
          <Tooltip title="Download image">
          <div className=' bg-zinc-900 border-[1px] border-zinc-400 m-2.5 hover:bg-zinc-200 hover:text-blue-800 rounded-md absolute right-12 top-0 p-1.5'>
          <a
          className=' '
          href={imgSrc} download={imgSrc}>
            <DownloadIcon/>
          </a>

          </div> 
          </Tooltip>



          <img className=' w-full rounded-lg h-full' src={imgSrc} alt="" />
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}