import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DesignCard from '../ownDesigns/DesignCard'
import { API, API1 } from '../../../../../backend'
import SortBy from '../ownDesigns/SortBy'
import { Puff } from 'react-loader-spinner'
import CampaignModel from '../ownDesigns/createCampaign/CampaignModel'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export default function PrintStore() {
    const [templates, setTemplates] = useState([])
    const [designArray, setDesignArray] = useState([])
    const businessId = JSON.parse(localStorage.getItem('businessId'))
    const navigate = useNavigate()
    const currentUser = JSON.parse(localStorage.getItem("myuser"))
    const userDetails = useSelector(state => state.user.userDetail)
    const getState = useSelector(state => state)
    const varient = [
        "My Projects",
        "Team Projects"
    ]


    const myAdminId=JSON.parse(localStorage.getItem("myAdminId"))
      console.log(myAdminId,"checingmyAdminId");


    // const [materials, setMaterials] = useState([])
    // const [selectedCategories, setSelectedCategories] = useState([])
  
  
    // const [sortBytime, setSortBytime] = useState(null)
    // const [selectedmaterial, setSelectedMaterial] = useState(null)
    // const materialsAvailable = [
    //   "Non-Tearable Paper (125-Micron)",
    //   "Premium Self-Adhesive Vinyl",
    //   "Self-Adhesive Vinyl on 3mm Sunboard"
    // ];

    const [campaignData, setCampaignData] = useState({
        user: `${currentUser.id}`,
        title: "",
        desc: "",
        startDate: null,
        endDate: null,
        designs: []
    })

     
    console.log("current Campaign date is", campaignData)

    //create campaign
    const createNewCampaign = () => {
        const create = async (getState) => {
            await axios.post(`${API}campaign/createCamp`, campaignData)
                .then(({ data }) => {
                    toast.success("New Campaign is created")
                    navigate('/campaigns')
                })
                .catch((err) => {
                    alert("something went wrong")
                })
        }
        create()
    }

    const [loading, setLoding] = useState(false)
    const [currentVarient, setCurrentVarient] = useState('My Projects')
    // useState(async()=>{
    // await axios.get(`${API}material/getMaterialDiy`)
    // .then(({data})=>setMaterials(data))
    // },[])
    // console.log('allMaterials',materials)
    useEffect(() => {
        const getTemp = async () => {
            setLoding(true)
            if (currentVarient === "My Projects") {
                await axios.get(`${API}diy/gettemplates`)
                    .then(({ data }) => {
                        const res = data?.filter((t, id) => t?.adminId === userDetails?._id && t?.isApproved)
                        setTemplates(res)
                        setLoding(false)
                    })
            }
            else if (currentVarient === "Team Projects") {


        
                 
                    // await axios.get(`${API}diy/gettemplates`)
                    //     .then(({ data }) => {
                    //         const res = data?.filter((f) => (f?.adminId === businessId ||
                    //             f?.instruction?.team?.find((t) => t === userDetails?.emailid) && f?.isApproved)
                    //         )
                    //         console.log("RESPONSE--->", data, res)
                    //         setTemplates(res)
                    //         setLoding(false)
                    //    })
                 
                    const {data}=await axios.get(`${API}diy/gettemplates`)
                    console.log(data,"all datatemps")
                  const membertemps= await data.filter((f)=>(f?.adminId===myAdminId && f?.isApproved))
                  console.log(membertemps,"checingmembertemp")
                  setTemplates(membertemps)
             
                  setLoding(false)
            


              

            }
        }
        getTemp()

    }, [currentVarient])



    //mytemplates which are approved
    // const myFinalTemps = templates?.filter((t,id)=>t?.isApproved && t?.adminId === userDetails?._id) 

    return (
        <div className='flex gap-2 flex-col px-10 py-3'>
            {/* for data filter */}
            <div className=' flex gap-2 justify-between flex-wrap items-center'>
            {/* // for current i am disabling this and will implement later */}
                {/* <div>
                    <SortBy />  
                </div> */}
                <div></div>
                <CampaignModel
                    designArray={designArray}
                    setDesignArray={setDesignArray}
                    createNewCampaign={createNewCampaign}
                    campaignData={campaignData}
                    setCampaignData={setCampaignData}
                />
                <div className=' flex gap-2 items-center'>
                    {
                        varient?.map((item) => (
                            <div className={currentVarient === item ? ' cursor-pointer duration-200 transition-all bg-blue-950 text-white px-3 py-1.5 rounded-md border-[1px] border-indigo-200' : 'cursor-pointer duration-200 transition-all bg-white border-[1px] border-indigo-200 text-black px-3 py-1.5 rounded-md'}
                                onClick={() => setCurrentVarient(item)}
                            >{item}</div>
                        ))
                    }
                </div>
            </div>

            {
                !loading ?
                    <div className='grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 '>
                        {
                            templates?.map((t, id) => (
                                <DesignCard
                                    currentVarient={currentVarient}
                                    t={t} id={id} />
                            ))
                        }
                    </div> :
                    <div
                        style={{
                            transform: 'translate(-50%,-50%)'
                        }}
                        className=' absolute top-[50%] left-[50%] flex justify-center items-center'>
                        <Puff
                            visible={true}
                            height="90"
                            width="90"
                            color="#14366e"
                            ariaLabel="puff-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
            }
        </div>
    )
}
