

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const Quiz=()=> {
  const [openElbowModal, setOpenElbowModal] = useState(false);
  const [openKeyboardModal, setopenKeyboardModal] = useState(false);
  const[keyboardStand,setKeyboardStand]=useState(false); 
  const[WristModel,setWristModel]=useState(false);
  const [FeetModel,setFeetModel]=useState(false);
  const[backModel,setbackModel]=useState(false);
  const[kneesModel,setkneesModel]=useState(false);
 const[lowerbackModel,setlowerBackModel]=useState(false);
 const [shoulderModel,setShoulderModel]=useState(false);
const[lineofSightModel,setlineofSightModel]=useState(false);


 const[totalQuize,setTotalQuize]=useState(10);


  const [isTrue, setIsTrue] = useState(false);            
  const [isFalse, setIsFalse] = useState(false);

    useEffect(() => {
    let timer;
    if (isFalse) {
      timer = setTimeout(() => {
        setIsFalse(false);
      }, 1500);
    }
    if (isTrue) {
      timer = setTimeout(() => {
        setIsTrue(false);
      }, 1500);
    }

    return () => clearTimeout(timer);
  }, [isFalse, isTrue]);


  return (
    <div className="container mx-auto mt-8 px-4 sm:px-8 lg:px-16">
      <div className="upper-part mb-3">
        <h1 className="font-semibold text-2xl sm:text-3xl lg:text-4xl text-[#1F3662] leading-snug mb-2">
          Find the Ergonomic Errors
        </h1>
        <h2 className="text-xl sm:text-2xl text-[#1F3662] font-normal leading-tight mb-2">
          Use your knowledge of comfortable computing to help Employee/Student feel better.
        </h2>
        <p className="text-gray-600">
          Employee/Student’s desk needs ergonomic correction for a safer and healthier work environment.
        </p>

        <div className="flex flex-col sm:flex-row justify-between mt-4">
          <div className="left-part">
            <ol className="list-decimal ml-5">
              <li className="flex items-center gap-1 mb-2">
                <span className="text-3xl text-[#9BD]">❶</span>
                Study the image of Employee/Student at his desk below.
              </li>
              <li className="flex items-center gap-1 mb-2">
                <span className="text-3xl text-[#9BD]">❷</span>
                <span>Mouse over the image to find & click hidden hotspots.</span>
              </li>
              <li className="flex items-center gap-1 mb-2">
                <span className="text-3xl text-[#9BD]">❸</span>
                Answer the pop-up questions correctly to score!
              </li>
            </ol>
          </div>

          <div className="flex flex-col justify-center items-center z-15 relative mt-4 sm:mt-0 sm:mr-5 sm:hidden md:flex md:pr-4 md:pl-4    ">
            <div
              className="absolute border border-gray-300 bg-[#f9fff0] right-0 mr-4 p-4 sm:p-8 md:pl-2 md:pr-2 md:ml-4 md:mr-4 w-full sm:w-96   h-40 sm:h-48 md:w-full box-border overflow-hidden"
              style={{ zIndex: 0 }}
            ></div>
            <div style={{ zIndex: 1 }} className="relative p-4 sm:p-8">
              <p><b>What’s your Ergonomic skill level?</b></p>
              <p>
                Try to answer at least half of all questions correctly.
                <br />
                Answer all 10 correctly to play the bonus round!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="lower-part">
        <div className="remaining-point flex flex-col sm:flex-row items-center gap-4 sm:gap-32 mb-8">
          <div className="allcheckpoints text-lg text-[#b8b6b6] text-2xl">
            <span>Find</span>
            <span className="hidden md:inline">&nbsp;the</span>
            <span
              id="remaining"
              className="bg-gray-200 border border-gray-300 rounded-md font-bold text-center px-2 py-1 mx-2 min-w-[85px]"
            >
              {totalQuize}
            </span>
            <span>remaining hotspots.</span>
          </div>
          <div className="your-score text-lg text-[#b8b6b6] font-medium">
            <span>Score :</span>
            <span
              className="bg-gray-200 border border-gray-300 rounded-md font-bold text-center px-2 py-1 mx-2 min-w-[85px]"
            >
              {totalQuize}
            </span>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-[500px] h-auto bg-red-200 mt-16 shadow-2xl shadow-blue-700 relative">
                 
                 {/*  elbow div  */}
            <div

              id="elbowww"
              className="w-[60px] h-[50px] z-50 absolute left-[43%] top-[48%] transform -translate-x-1/2 -translate-y-1/2"
              onMouseOverCapture={() => setOpenElbowModal(true)}
              onMouseOutCapture={() => setOpenElbowModal(false)}
            >
              {console.log("Elbow Modal is: ", openElbowModal)}
              <img
                src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
                hidden={!openElbowModal}
                className="cursor-pointer bg-inherit w-[50px]"
              />

              {openElbowModal && (
                <div
                  onMouseOverCapture={() => setOpenElbowModal(true)}
                  onMouseOutCapture={() => setOpenElbowModal(false)}
                  hidden={!openElbowModal}
                  className="relative"
                >
                  <div id="elbowarrow" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[98%] left-[50%]"></div>
                  <div id="elbow" className="absolute bg-black text-white h-[9.8rem] w-[20rem] text-center pt-2 pr-2 pl-2 rounded-md top-[100%] left-[50%] transform -translate-x-1/2 mt-2">
                    <p>Elbows should be between a 90° and 120° angle.</p>
                    <div className="flex flex-col gap-3">
                      <button
                        className="bg-emerald-300 rounded-md mr-3 ml-3"
                        onClick={() => setIsTrue(true)}
                      >
                        True
                      </button>
                      <button
                        className="bg-red-400 rounded-md mr-3 ml-3"
                        onClick={() => setIsFalse(true)}
                      >
                        False
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
                 
                 {/* keboard tray  */}
            <div
              id="keyboard"
              className="w-[60px] h-[50px] z-50 absolute left-[55%] top-[38%] transform -translate-x-1/2 -translate-y-1/2"
              onMouseOverCapture={() => setopenKeyboardModal(true)}
              onMouseOutCapture={() => setopenKeyboardModal(false)}
            >
              {console.log("Shoulder Modal is: ", openKeyboardModal)}
              <img
                src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
                hidden={!openKeyboardModal}
                className="cursor-pointer bg-inherit w-[50px]"
              />

              {openKeyboardModal && (
                <div
                  onMouseOverCapture={() => setopenKeyboardModal(true)}
                  onMouseOutCapture={() => setopenKeyboardModal(false)}
                  hidden={!openKeyboardModal}
                  className="relative"
                >
                  <div id="keyboard" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[50%] left-[90%] transform -translate-y-1/2 ml-2"></div>
                  <div id="keyboardhand" className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center  pb-4 pt-2 pr-2 pl-2 rounded-md top-[48%] left-[98%] transform -translate-y-1/2 ml-2">
                    <p>A keyboard tray limits movement. Place the keyboard directly on the desk for greater flexibility.</p>
                    <div className="flex flex-col gap-3">
                      <button
                        className="bg-emerald-300 rounded-md mr-3 ml-3"
                        onClick={() => setIsTrue(true)}
                      >
                        True
                      </button>
                      <button
                        className="bg-red-400 rounded-md mr-3 ml-3"
                        onClick={() => setIsFalse(true)}
                      >
                        False
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
   
      {/*  keboard stand   */}
            <div
              id="keyboard"
              className="w-[60px] h-[50px] z-50 absolute left-[72%] top-[38%] transform -translate-x-1/2 -translate-y-1/2"
              onMouseOverCapture={() => setKeyboardStand(true)}
              onMouseOutCapture={() => setKeyboardStand(false)}
            >
              {/* {console.log("Shoulder Modal is: ", openKeyboardModal)} */}
              <img
                src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
                hidden={!keyboardStand}
                className="cursor-pointer bg-inherit w-[50px]"
              />

              {keyboardStand && (
                <div
                  onMouseOverCapture={() => setKeyboardStand(true)}
                  onMouseOutCapture={() => setKeyboardStand(false)}
                  hidden={!keyboardStand}
                  className="relative"
                >
                  <div id="keyboardstand" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[50%] left-[90%] transform -translate-y-1/2 ml-2"></div>
                  <div id="keyboardhand" className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center  pb-4 pt-2 pr-2 pl-2 rounded-md top-[48%] left-[98%] transform -translate-y-1/2 ml-2">
                    <p>The display stand as shown provides enough tilt and height adjustment to provide a comfortable height andviewing angle</p>
                    <div className="flex flex-col gap-3">
                      <button
                        className="bg-emerald-300 rounded-md mr-3 ml-3"
                        onClick={() => setIsTrue(true)}
                      >
                        True
                      </button>
                      <button
                        className="bg-red-400 rounded-md mr-3 ml-3"
                        onClick={() => setIsFalse(true)}
                      >
                        False
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Wrist model */}

            <div
              id="wrist"
              className="w-[60px] h-[50px] z-50 absolute left-[50%] top-[40%] transform -translate-x-1/2 -translate-y-1/2"
              onMouseOverCapture={() => setWristModel(true)}
              onMouseOutCapture={() => setWristModel(false)}
            >
              
              <img
                src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
                hidden={!WristModel}
                className="cursor-pointer bg-inherit w-[50px]"
              />

              {WristModel && (
                <div
                  onMouseOverCapture={() => setWristModel(true)}
                  onMouseOutCapture={() => setWristModel(false)}
                  hidden={!WristModel}
                  className="relative"
                >
                  <div id="wrist" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[5%] left-[48%] transform -translate-y-1 ml-2"></div>
                  <div id="wrist" className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center  pb-4 pt-2 pr-2 pl-2 rounded-md top-[10%] left-[40%] transform -translate-y-1 ml-2">
                    <p>Wrists should be flexed at a downward angle between 30°  and 45° </p>
                    <div className="flex flex-col gap-3">
                      <button
                        className="bg-emerald-300 rounded-md mr-3 ml-3"
                        onClick={() => setIsTrue(true)}
                      >
                        True
                      </button>
                      <button
                        className="bg-red-400 rounded-md mr-3 ml-3"
                        onClick={() => setIsFalse(true)}
                      >
                        False
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>


            {/* feet */}

            <div
              id="feet"
              className="w-[60px] h-[50px] z-50 absolute left-[51%] top-[80%] transform -translate-x-1/2 -translate-y-1/2"
              onMouseOverCapture={() => setFeetModel(true)}
              onMouseOutCapture={() => setFeetModel(false)}
            >
             
              <img
                src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
                hidden={!FeetModel}
                className="cursor-pointer bg-inherit w-[50px]"
              />

              {FeetModel && (
                <div
                  onMouseOverCapture={() => setFeetModel(true)}
                  onMouseOutCapture={() => setFeetModel(false)}
                  hidden={!FeetModel}
                  className="relative"
                >
                  <div id="feetlow" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[50%] left-[90%] transform -translate-y-1/2 ml-2"></div>
                  <div id="feetpopup" className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center  pb-4 pt-2 pr-2 pl-2 rounded-md top-[48%] left-[98%] transform -translate-y-1/2 ml-2">
                    <p>Feet should be predominantaly flat on the floor.</p>
                    <div className="flex flex-col gap-3">
                      <button
                        className="bg-emerald-300 rounded-md mr-3 ml-3"
                        onClick={() => setIsTrue(true)}
                      >
                        True
                      </button>
                      <button
                        className="bg-red-400 rounded-md mr-3 ml-3"
                        onClick={() => setIsFalse(true)}
                      >
                        False
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* back */}
            <div
              id="back"
              className="w-[60px] h-[50px] z-50 absolute left-[20%] top-[30%] transform -translate-x-1/2 -translate-y-1/2"
              onMouseOverCapture={() => setbackModel(true)}
              onMouseOutCapture={() => setbackModel(false)}
            >
             
              <img
                src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
                hidden={!backModel}
                className="cursor-pointer bg-inherit w-[50px]"
              />

              {backModel && (
                <div
                  onMouseOverCapture={() => setbackModel(true)}
                  onMouseOutCapture={() => setbackModel(false)}
                  hidden={!backModel}
                  className="relative"
                >
                  <div id="backcube" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[50%] left-[90%] transform -translate-y-1/2 ml-2 z-10"></div>
                  <div id="backpopup" className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center  pb-4 pt-2 pr-2 pl-2 rounded-md top-[48%] left-[98%] transform -translate-y-1/2 ml-2">
                    <p>There should be acomfortable amount of space between your back and the back of the chair, to ensure freedom of momvement.</p>
                    <div className="flex flex-col gap-3">
                      <button
                        className="bg-emerald-300 rounded-md mr-3 ml-3"
                        onClick={() => setIsTrue(true)}
                      >
                        True
                      </button>
                      <button
                        className="bg-red-400 rounded-md mr-3 ml-3"
                        onClick={() => setIsFalse(true)}
                      >
                        False
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Knees  */} 

     <div
  id="knew"
  className="w-[60px] h-[50px] z-50 absolute left-[48%] top-[58%] transform -translate-x-1/2 -translate-y-1/2"
  onMouseOverCapture={() => setkneesModel(true)}
  onMouseOutCapture={() => setkneesModel(false)}
>
  <img
    src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
    hidden={!kneesModel}
    className="cursor-pointer bg-inherit w-[50px]"
  />

  {kneesModel && (
    <div
      onMouseOverCapture={() => setkneesModel(true)}
      onMouseOutCapture={() => setkneesModel(false)}
      hidden={!kneesModel}
      className="relative"
    >
      <div className="absolute left-[-15px] top-[-35%] transform -translate-y-1/2">
        <div id="kneesarrow" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[50%] left-[-10px]"></div>
        <div id="knees" className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center pb-4 pt-2 pr-2 pl-2 rounded-md top-[50%] left-[10%] transform -translate-y-1/2 -translate-x-full mt-2">
          <p>Knees should be slightly lower than the hips with a 2-3" space between the back of the leg and the chair.</p>
          <div className="flex flex-col gap-3">
            <button
              className="bg-emerald-300 rounded-md mr-3 ml-3"
              onClick={() => setIsTrue(true)}
            >
              True
            </button>
            <button
              className="bg-red-400 rounded-md mr-3 ml-3"
              onClick={() => setIsFalse(true)}
            >
              False
            </button>
          </div>
        </div>
      </div>
    </div>
  )}
     </div>


     {/* lower back  hips  */}
     <div
              id="back"
              className="w-[60px] h-[50px] z-50 absolute left-[20%] top-[50%] transform -translate-x-1/2 -translate-y-1/2"
              onMouseOverCapture={() => setlowerBackModel(true)}
              onMouseOutCapture={() => setlowerBackModel(false)}
            >
             
              <img
                src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
                hidden={!lowerbackModel}
                className="cursor-pointer bg-inherit w-[50px]"
              />

              {lowerbackModel && (
                <div
                  onMouseOverCapture={() => lowerbackModel(true)}
                  onMouseOutCapture={() => lowerbackModel(false)}
                  hidden={!lowerbackModel}
                  className="relative"
                >
                  <div id="backcube" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[50%] left-[90%] transform -translate-y-1/2 ml-2"></div>
                  <div id="backpopup" className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center  pb-4 pt-2 pr-2 pl-2 rounded-md top-[48%] left-[98%] transform -translate-y-1/2 ml-2">
                    <p>A 90° to 120° angle at the hips is recommended, keeping your torso upright or reclined slightly.</p>
                    <div className="flex flex-col gap-3">
                      <button
                        className="bg-emerald-300 rounded-md mr-3 ml-3"
                        onClick={() => setIsTrue(true)}
                      >
                        True
                      </button>
                      <button
                        className="bg-red-400 rounded-md mr-3 ml-3"
                        onClick={() => setIsFalse(true)}
                      >
                        False
                      </button>
                    </div>
                  </div>
                </div>
              )}
     </div>


     {/* Shoulders */}

     <div
              id="back"
              className="w-[60px] h-[50px] z-50 absolute left-[30%] top-[30%] transform -translate-x-1/2 -translate-y-1/2"
              onMouseOverCapture={() => setShoulderModel(true)}
              onMouseOutCapture={() => setShoulderModel(false)}
            >
             
              <img
                src="https://media.istockphoto.com/id/894875516/vector/exclamation-point-sign-in-red-triangle-vector-icon.jpg?s=612x612&w=0&k=20&c=AYMHwOnNCaWz8j3ubjC24cBrlk_ei_oCB3kS-UwvZtU="
                hidden={!shoulderModel}
                className="cursor-pointer bg-inherit w-[50px]"
              />

              {shoulderModel && (
                <div
                  onMouseOverCapture={() =>shoulderModel(true)}
                  onMouseOutCapture={() => shoulderModel(false)}
                  hidden={!shoulderModel}
                  className="relative"
                >
                  <div id="backcube" className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[50%] left-[90%] transform -translate-y-1/2 ml-2"></div>
                  <div id="backpopup" className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center  pb-4 pt-2 pr-2 pl-2 rounded-md top-[48%] left-[98%] transform -translate-y-1/2 ml-2">
                    <p>Shoulders should be down with your chest open and wide .</p>
                    <div className="flex flex-col gap-3">
                      <button
                        className="bg-emerald-300 rounded-md mr-3 ml-3"
                        onClick={() => setIsTrue(true)}
                      >
                        True
                      </button>
                      <button
                        className="bg-red-400 rounded-md mr-3 ml-3"
                        onClick={() => setIsFalse(true)}
                      >
                        False
                      </button>
                    </div>
                  </div>
                </div>
              )}
     </div>

     {/* eye head lineofsight  */}
    
      <div
            id="eye"
            className="w-[60px] h-[50px] z-50 absolute left-[55%] top-[24%] transform -translate-x-1/2 -translate-y-1/2"
            onMouseOverCapture={() => setlineofSightModel(true)}
            onMouseOutCapture={() => setlineofSightModel(false)}
        >
            <div className="w-[110px] h-[8px] bg-slate-500 hover:bg-red-400 opacity-10 hover:opacity-100 "></div>

            {lineofSightModel && (
                <div
                    onMouseOverCapture={() => setlineofSightModel(true)}
                    onMouseOutCapture={() => setlineofSightModel(false)}
                    className="relative"
                >
                    <div
                        id="eyearrow"
                        className="w-[20px] bg-black h-[20px] absolute rotate-45 top-[110%] left-1/2 transform -translate-x-1/2"
                    ></div>
                    <div
                        id="eyedown"
                        className="absolute bg-black text-white min-h-[9.8rem] w-[20rem] text-center pb-4 pt-2 pr-2 pl-2 rounded-md top-[120%] left-1/2 transform -translate-x-1/2"
                    >
                        <p>
                            Properly positioning your display relative to your
                            line-of-sight is important to achieve a healthy,
                            ergonomic posture. In the illustration shown here,
                            which of these is the better corrective action?
                        </p>
                        <div className="flex flex-col gap-3">
                            <button
                                className="bg-blue-500 rounded-md mr-3 ml-3"
                                onClick={() => setIsTrue(true)}
                            >
                                Lower the Chair
                            </button>
                            <button
                                className="bg-blue-500 rounded-md mr-3 ml-3"
                                onClick={() => setIsFalse(true)}
                            >
                                Mount the Display
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>

    


  

    <img src="./quiz.webp" alt="employeeimage" className="w-full h-full object-cover" />
          </div>

          {isTrue && (
            <div className="absolute bg-black text-white z-[1000] w-[20rem] h-[5rem] text-center rounded-md mt-4 transition-transform">
              <Modal.Dialog>
                <Modal.Body className="top-[20px] text-lg font-bold">
                  🎊🎊 Hurray! 🎊🎊
                </Modal.Body>
                <Modal.Body className="top-[20px] text-lg font-bold">
                  Your answer is CORRECT..🥳
                </Modal.Body>
              </Modal.Dialog>
            </div>
          )}

          {isFalse && (
            <div className="absolute bg-black text-white z-[1000] w-[20rem] h-[7rem] text-center rounded-md mt-4 transition-transform">
              <Modal.Dialog>
                <Modal.Body className="top-[3rem] text-lg font-bold ">
                  Sorry, FALSE is not correct
                </Modal.Body>
              </Modal.Dialog>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Quiz;

