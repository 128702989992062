// Mockup obj is an array of objects
const initialState = [];

const mockupPage = (state = initialState, action) => {
	switch (action.type) {
		case "RESTORE":
			return [...action.payload.mockup];

		case "ADD_MOCKUP_PAGE":
			return [action.payload.mockup];

		case "EMPTY_MOCKUP_PAGES":
			return [];

		default:
			return state;
	}
};

export default mockupPage;
