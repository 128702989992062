import React, { useEffect, useState } from 'react'
import Masonry from '@mui/lab/Masonry';
import { Box, Avatar, Button, Card, CardActions, CardContent, Chip, Typography } from '@mui/joy';
import axios from 'axios';
import { API } from '../../backend';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllforms } from '../../redux/actions/designerFormAction';
import { Tooltip } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { selectProjectChat } from '../../redux/actions/chatAction';
export default function DesignerFilterForm({ showArchived, setImgSrc, setOpen, formData, idx, setFormData, setInstructionPopup, f, userDetails }) {
  const navigate = useNavigate()
  const selectedInstruction = useSelector((state) => state.instructionDetail.instructionDetail)
  const [temp, setTemp] = useState(null)
  const dispatch = useDispatch()
  const [allTemps, setAlltemps] = useState([])

  const forms = useSelector((state) => state.form.form)
  const getState = useSelector((state) => state)

   

  console.log('forms are', forms)
  useEffect(() => {
    dispatch(getAllforms())
  }, [])
  useEffect(() => {
    const getTemp = async () => {
      await axios.get(`${API}diy/gettemplate/${f?.templateId}`)
        .then(({ data }) => setTemp(data))
        .catch((error) => console.log(error))
    }
    getTemp()
  }, [f])
  useEffect(() => {
    setFormData({ ...formData, designerId: `${f?.designerId?._id}` })
  }, [f])

  //getting all unpublished templates
  useEffect(() => {
    const getTemp = async () => {
      await axios.get(`${API}diy/gettemplates`)
        .then(({ data }) => setAlltemps(data))
        .catch((error) => console.log(error))
    }
    getTemp()
  }, [])

  // checking the template is created or not
  const currentTemplate = allTemps?.find((t) => t?.instruction?._id === f?._id)
  console.log('template for the job card', currentTemplate)

  return (
    <>
      {
        !temp?.isApproved && !currentTemplate?.isApproved &&
        <Card
          className=' hover:translate-y-[-3px] transition-all duration-200'
          variant="solid"
          color='primary'
          invertedColors
          sx={{
            boxShadow: 'lg',
            width: '100%',
            overflowY: 'auto',
            position: 'relative'
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          <Tooltip title="start group chat">
            <div
              onMouseEnter={() => dispatch(selectProjectChat(f))}
              onClick={() => {
                navigate(`/chats/chat/${f?._id}`)
              }}
              className=' cursor-pointer absolute right-0 top-0 m-2 p-2 bg-black rounded-full'>
              <ChatIcon />
            </div>
          </Tooltip>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}
            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.myId?.name} alt={f?.myId?.name} />
                <p className=' my-auto'>{f?.myId?.name}</p>
              </div>
            </Chip>

            {f?.purpose && <Chip size="lg" variant="soft">
              {f?.purpose}
            </Chip>}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || '#fffff'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md'></div>
                  </div>
                </div>
              </Chip>}
            {
              f?.canvasSize?.width && f?.canvasSize?.height &&
              <Chip size="lg" variant="soft">
                {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
              </Chip>}
          </Box>
          <div>
            {/* <Typography level="h2">
    $58{' '}
    <Typography fontSize="sm" textColor="text.tertiary">
      /month
    </Typography>
  </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>
          {
            f?.demoImgUrl &&
            <>
              <p className=' mb-[-5px]'>Demo Design Image</p>
              <img
                style={{
                  backgroundSize: 'cover',

                }}
                onClick={() => {
                  setImgSrc(f?.demoImgUrl)
                  setOpen(true)
                }}
                className=' h-auto w-full rounded-md cursor-pointer'
                src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
                loading="lazy"
                alt=""
              />
            </>}

          {/* if multiple image is present */}
          {
            f?.multiImgUrl?.length > 0 &&
            <Masonry columns={{ xs: 1, md: 2, lg: 2 }}

              spacing={1}>
              {f?.multiImgUrl?.map((image) => (
                <img
                  onClick={() => {
                    setImgSrc(image)
                    setOpen(true)
                  }}
                  className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
              ))}

            </Masonry>}

          <CardActions>
            {
              (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) ?
                <Button
                  onClick={() => {
                    if (currentTemplate) {
                      navigate(`/diy/${currentTemplate?._id}`)
                    }
                    if (f?.templateId !== "undefined" && f?.templateId) {
                      navigate(`/diy/${f?.templateId}`)
                    }

                  }}
                >Continue Work</Button> :
                <Button>Start Work</Button>
            }
          </CardActions>
        </Card>

      }

      {
        (temp?.isApproved || currentTemplate?.isApproved) &&
        <Card

          onClick={() => {
            if (currentTemplate) {
              navigate(`/diy/${currentTemplate?._id}`)
            }
            if (f?.templateId !== "undefined" && f?.templateId) {
              navigate(`/diy/${f?.templateId}`)
            }

          }}
          className=' hover:translate-y-[-3px] transition-all duration-200'
          variant="solid"
          color='success'
          invertedColors
          sx={{
            boxShadow: 'lg',
            width: '100%',
            overflowY: 'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>

            <Chip size='lg' variant='solid'>
              Approved
            </Chip>
            {!f?.title && <Chip sx={{ bgcolor: '#d4d8fa', color: 'black' }} size="md" color='neutral' variant='soft'>
              Manage All
            </Chip>}
            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }
            {(currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
              <Chip
                onClick={() => {
                  if (currentTemplate) {
                    navigate(`/diy/${currentTemplate?._id}`)
                  }
                  if (f?.templateId) {
                    navigate(`/diy/${f?.templateId}`)
                  }

                }}
                size='lg' variant='solid' color='neutral'>
                {temp?.title || currentTemplate?.title || currentTemplate?.projectDesc?.slice(0, 20)}
              </Chip>}

            {f?.Material &&
              <Chip
                size='lg' variant='solid' color='danger'>
                {f?.Material.slice(0, 10)}...
              </Chip>}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.myId?.name} alt={f?.myId?.name} />
                <p className=' my-auto'>{f?.myId?.name}</p>
              </div>
            </Chip>

            {f?.purpose && <Chip size="lg" variant="soft">
              {f?.purpose}
            </Chip>}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || '#fffff'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md'></div>
                  </div>
                </div>
              </Chip>}
            {
              f?.canvasSize?.width && f?.canvasSize?.height &&
              <Chip size="lg" variant="soft">
                {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
              </Chip>}
          </Box>
          <div>
            {/* <Typography level="h2">
    $58{' '}
    <Typography fontSize="sm" textColor="text.tertiary">
      /month
    </Typography>
  </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>

          {
            f?.files?.length > 0 &&
            <div className=' flex flex-col'>
              <p className=' mb-[-5px]'>Ref files</p>
              {
                f?.files?.map((item, id) => (
                  <div className=' relative bg-indigo-100 w-full px-6 rounded-md py-2.5 flex gap-3.5 items-center'>
                    <svg
                      style={{
                        backgroundColor: '#9c1910'
                      }}
                      className=' scale-[2.2] p-1 rounded-[10px] text-white'
                      width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 2.5C3 2.22386 3.22386 2 3.5 2H9.08579C9.21839 2 9.34557 2.05268 9.43934 2.14645L11.8536 4.56066C11.9473 4.65443 12 4.78161 12 4.91421V12.5C12 12.7761 11.7761 13 11.5 13H3.5C3.22386 13 3 12.7761 3 12.5V2.5ZM3.5 1C2.67157 1 2 1.67157 2 2.5V12.5C2 13.3284 2.67157 14 3.5 14H11.5C12.3284 14 13 13.3284 13 12.5V4.91421C13 4.51639 12.842 4.13486 12.5607 3.85355L10.1464 1.43934C9.86514 1.15804 9.48361 1 9.08579 1H3.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5H7.5C7.77614 5 8 4.77614 8 4.5C8 4.22386 7.77614 4 7.5 4H4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H4.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H10.5C10.7761 11 11 10.7761 11 10.5C11 10.2239 10.7761 10 10.5 10H4.5Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                    <Tooltip title="Download">
                      <a className=' text-black no-underline' href={item} download={`${formData?.title || 'EHSFile'} ${id + 1}`}>
                        {f?.title || 'EHSFile'} {id + 1}
                      </a>
                    </Tooltip>
                  </div>
                ))
              }
            </div>
          }
          {
            f?.demoImgUrl &&
            <>
              <p className=' mb-[-5px]'>Demo Design Image</p>
              <img
                style={{
                  backgroundSize: 'cover',

                }}
                onClick={(e) => {
                  e.stopPropagation()
                  setImgSrc(f?.demoImgUrl)
                  setOpen(true)
                }}
                className=' h-auto w-full rounded-md cursor-pointer'
                src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
                loading="lazy"
                alt=""
              />
            </>}

          {/* if multiple image is present */}
          {
            f?.multiImgUrl?.length > 0 &&
            <Masonry columns={{ xs: 1, md: 2, lg: 2 }}

              spacing={1}>
              {f?.multiImgUrl?.map((image) => (
                <img
                  onClick={() => {
                    setImgSrc(image)
                    setOpen(true)
                  }}
                  className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
              ))}

            </Masonry>}
        </Card>

      }
    </>
  )
}
