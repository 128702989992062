import React, { useEffect, useState } from 'react'
import Masonry from '@mui/lab/Masonry';
import Tooltip from '@mui/material/Tooltip'
import { Box, Avatar, Button, Card, CardActions, CardContent, Chip, Typography } from '@mui/joy';
import axios from 'axios';
import { API } from '../../backend';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MoreVert from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { delForm, getAllforms } from '../../redux/actions/designerFormAction';
import { toast } from 'react-toastify';
import SpokeIcon from '@mui/icons-material/Spoke';
import PreviewIcon from '@mui/icons-material/Preview';

export default function FilterForm({ getFilterMultiimgData, formData, idx, setFormData, setInstructionPopup, f, userDetails }) {
  const navigate = useNavigate()
  const [temp, setTemp] = useState(null)
  const dispatch = useDispatch()
  const [allTemps, setAlltemps] = useState([])
  const forms = useSelector((state) => state.form.form)
  const getState = useSelector((state) => state)

   

  console.log('forms are', forms)
  React.useEffect(() => {
    dispatch(getAllforms())
  }, [])


  //getting all unpublished templates
  useEffect(() => {
    const getTemp = async () => {
      await axios.get(`${API}diy/gettemplates`)
        .then(({ data }) => setAlltemps(data))
        .catch((error) => console.log(error))
    }
    getTemp()
  }, [])


  useEffect(() => {
    const getTemp = async () => {
      await axios.get(`${API}diy/gettemplate/${f?.templateId}`)
        .then(({ data }) => setTemp(data))
        .catch((error) => console.log(error))
    }
    getTemp()
  }, [f])
  useEffect(() => {
    setFormData({ ...formData, designerId: `${f?.designerId?._id}` })
  }, [f])

  //del the instruction
  const delInstruction = () => {
    dispatch(delForm(f?._id))
    toast.success('Instruction is deleted successfully')
  }

  // checking the template is created or not
  const currentTemplate = allTemps?.find((t) => t?.instruction?._id === f?._id)
  console.log('template for the job card', currentTemplate)

  return (

    <>
      {
        !temp?.isApproved &&
        <Card
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color="primary"
          invertedColors
          sx={{
            boxShadow: 'lg',
            position: 'relative'
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          <Tooltip

            title="See more">
            <Dropdown
            >
              <MenuButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  m: 1
                }}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu placement="bottom-end">
                {currentTemplate || (f?.templateId && f?.templateId !== "undefined") ?

                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    In Progress
                  </MenuItem> :
                  <MenuItem>
                    <ListItemDecorator>
                      <SpokeIcon />
                    </ListItemDecorator>{' '}
                    Not Started
                  </MenuItem>
                }
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={() => {
                    setInstructionPopup(true)
                    setFormData({
                      ...formData,
                      title: f?.title,
                      id: f?._id,
                      isMulti: f?.isMulti,
                      templateId: `${f?.templateId}`,
                      myId: `${userDetails?._id}`,
                      designerId: `${f?.designerId?._id}`,
                      purpose: f?.purpose,
                      Material: f?.Material,
                      demoImgUrl: f?.demoImgUrl,
                      projectDesc: f?.projectDesc,
                      placestoDecorate: {
                        where: f?.placestoDecorate?.where,
                        color: f?.placestoDecorate?.color
                      },
                      canvasSize: {
                        width: f?.canvasSize?.width,
                        height: f?.canvasSize?.height
                      }

                    })
                    getFilterMultiimgData(idx)
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Edit instruction
                </MenuItem>
                {
                  (currentTemplate || (f?.templateId && f?.templateId !== "undefined")) &&
                  <MenuItem
                    onClick={() => {
                      if (currentTemplate) {
                        navigate(`/diy/${currentTemplate?._id}`)
                      }
                      if (f?.templateId) {
                        navigate(`/diy/${f?.templateId}`)
                      }

                    }}
                  >

                    <ListItemDecorator>
                      <PreviewIcon />
                    </ListItemDecorator>{' '}

                    See Design
                  </MenuItem>}
                <ListDivider />
                <MenuItem
                  disabled={userDetails?.Role === 'corporate_admin' ? false : true}
                  onClick={delInstruction}
                  variant="soft" color="danger">
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Tooltip>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mr: 2.5 }}>
            {!f?.title && <Chip sx={{ bgcolor: '#d4d8fa', color: 'black' }} size="md" color='neutral' variant='soft'>
              Designer will take care of it
            </Chip>}

            {(currentTemplate?._id || (f?.templateId && f?.templateId !== "undefined")) ? <Chip
              size='md' variant='plain' color='warning' sx={{ bgcolor: '#d2fa96', color: 'black', px: 1.5 }}>
              In Progress
            </Chip> :
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#faac96', color: 'black', px: 1.5 }}>
                Not Started
              </Chip>
            }

            {
              ((currentTemplate?._id && currentTemplate?.reqApprove) || (f?.templateId && f?.templateId !== "undefined" && temp?.reqApprove)) &&
              <Chip
                size='md' variant='soft' color='warning' sx={{ bgcolor: '#e0d9fa', color: 'black', px: 1.5, position: 'relative' }}>

                <div className=' absolute right-0 top-0 mr-[-2px] mt-[-2px] w-[10px] h-[10px] bg-rose-600 animate-pulse rounded-full'></div>
                Pending for Approval
              </Chip>

            }

            {temp?.title && <Chip
              // onClick={()=>navigate(`/diy/${temp?._id}`)}
              size='lg' variant='solid' color='neutral'>
              {temp?.title}
            </Chip>}

            {f?.Material &&
              <Chip
                size='lg' variant='solid' color='danger'>
                {f?.Material.slice(0, 10)}...
              </Chip>}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {f?.purpose && <Chip size="lg" variant="soft">
              {f?.purpose}
            </Chip>}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || '#fffff'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md '></div>
                  </div>
                </div>
              </Chip>}
            <Chip size="lg" variant="soft">
              {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
            </Chip>
          </Box>
          <div>
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>
          {/* {      
   f?.demoImgUrl &&
<>
    <p className=' mb-[-5px]'>Demo Design Image</p>
      <img
      style={{
          backgroundSize:'cover',
          width:'100%',
          
      }}
      className=' h-auto'
        src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
        loading="lazy"
        alt=""
      />
      </>} */}

          {/* if multiple image is present */}
          {/* {
f?.multiImgUrl?.length > 0 &&
<Masonry columns={{ xs:1,md:2,lg:2 }}

            spacing={1}>
            {f?.multiImgUrl?.map((image)=>(
              <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
            ))}

            </Masonry>} */}

          <CardActions>
            <Button
              disabled={userDetails?.Role === 'corporate_admin' ? false : true}
              onClick={() => {
                setInstructionPopup(true)
                setFormData({
                  ...formData,
                  title: f?.title,
                  id: f?._id,
                  isMulti: f?.isMulti,
                  templateId: `${f?.templateId}`,
                  myId: `${userDetails?._id}`,
                  designerId: `${f?.designerId?._id}`,
                  purpose: f?.purpose,
                  demoImgUrl: f?.demoImgUrl,
                  projectDesc: f?.projectDesc,
                  placestoDecorate: {
                    where: f?.placestoDecorate?.where,
                    color: f?.placestoDecorate?.color
                  },
                  canvasSize: {
                    width: f?.canvasSize?.width,
                    height: f?.canvasSize?.height
                  }

                })
                getFilterMultiimgData(idx)
              }}
              variant="solid">Update instruction</Button>
          </CardActions>
        </Card>
      }

      {
        (temp?.isApproved || currentTemplate?.isApproved) &&
        <Card
          className=' hover:translate-y-[-3px] transition-all duration-200'
          onMouseEnter={() => {
            setFormData({ ...formData, designerId: `${f?.designerId?._id}`, })
          }}
          variant="solid"
          color='success'
          invertedColors
          sx={{
            boxShadow: 'lg',
            // width: '100%',
            // overflowY:'auto',
            //   height:'calc(90vh - 48px)'
            // to make the demo resizeable
            // overflow: 'auto',
            // resize: 'horizontal',
          }}
        >
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Chip size='lg' variant='solid'>
              Approved
            </Chip>
            {temp?.title &&
              <Chip
                onClick={() => navigate(`/diy/${temp?._id}`)}
                size='lg' variant='solid' color='neutral'>
                {temp?.title}
              </Chip>}

            <Chip size='lg' variant="soft">
              <div className=' flex my-auto gap-2 justify-between'>
                <Avatar sx={{ my: 'auto' }} src={f?.designerId?.name} alt={f?.designerId?.name} />
                <p className=' my-auto'>{f?.designerId?.name}</p>
              </div>
            </Chip>

            {f?.purpose && <Chip size="lg" variant="soft">
              {f?.purpose}
            </Chip>}
            {
              f?.placestoDecorate?.where &&
              <Chip size='lg' variant="soft">
                <div className=' flex my-auto gap-2 justify-between'>
                  {f?.placestoDecorate?.where || 'Wall'} <span className=' font-light'>({f?.position || "Middle"})</span>
                  <div className=' flex gap-1 text-base  font-light my-auto'>
                    {f?.placestoDecorate?.color || '#fffff'}
                    <div
                      style={{
                        backgroundColor: f?.placestoDecorate?.color
                      }}
                      className=' border-[1px] my-auto w-[20px] h-[20px] rounded-md '></div>
                  </div>
                </div>
              </Chip>}
            <Chip size="lg" variant="soft">
              {f?.canvasSize?.width || '20'} inch <span className=' font-light'>x</span> {f?.canvasSize?.height || '10'} inch
            </Chip>
          </Box>
          <div>
            {/* <Typography level="h2">
        $58{' '}
        <Typography fontSize="sm" textColor="text.tertiary">
          /month
        </Typography>
      </Typography> */}
          </div>
          <CardContent>
            <Typography level="title-lg">{f?.title}</Typography>
            <Typography level="body-md">
              {f?.projectDesc || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime temporibus doloribus culpa consequatur nulla sunt? Harum voluptate, voluptas similique temporibus eligendi ipsam sed, pariatur dolore corporis inventore iste magni officiis!'}

            </Typography>
          </CardContent>
          {
            f?.demoImgUrl &&
            <>
              <p className=' mb-[-5px]'>Demo Design Image</p>
              <img
                style={{
                  backgroundSize: 'cover',
                  width: '100%',

                }}
                className=' h-auto'
                src={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
                srcSet={f?.demoImgUrl || 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'}
                loading="lazy"
                alt=""
              />
            </>}

          {/* if multiple image is present */}
          {
            f?.multiImgUrl?.length > 0 &&
            <Masonry columns={{ xs: 1, md: 2, lg: 2 }}

              spacing={1}>
              {f?.multiImgUrl?.map((image) => (
                <img className=' transition-all cursor-pointer duration-200 hover:scale-105 rounded-md border-[1px] border-zinc-800 hover:border-zinc-200 w-full' src={image} alt="" />
              ))}

            </Masonry>}
        </Card>
      }
    </>
  )
}
