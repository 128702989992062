import * as React from 'react';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export default function SortByMaterial({setSortBytime,setSelectedCategories,materialsAvailable,selectedmaterial,setSelectedMaterial}) {
  return (
    <Select
    value={selectedmaterial}
    onChange={(e)=>{
      setSelectedCategories([])
      setSortBytime()
      setSelectedMaterial(e.target.value)}}
      placeholder="Sort By Material"
      indicator={<KeyboardArrowDown />}
      sx={{
        width: 180,
        [`& .${selectClasses.indicator}`]: {
          transition: '0.2s',
          [`&.${selectClasses.expanded}`]: {
            transform: 'rotate(-180deg)',
          },
        },
      }}
    >
        {
            materialsAvailable?.map((item,id)=>(
        <Option
        onClick={(e)=>setSelectedMaterial(item)}
        value={item} key={id}>{item}</Option>
            ))
        }
    </Select>
  );
}