const initialData = {

    currentProjectId:null,
    showDot:false
}

const handleChat=(state=initialData, action)=>{
    switch(action.type){
                case "SELECTED_PROJECT":
                return{
                   ...state,
                   currentProjectId:action.payload
                }
                case "SHOW_DOT":
                    return{
                       ...state,
                       showDot:action.payload
                    }
                    case "HIDE_DOT":
                        return{
                           ...state,
                           showDot:action.payload
                        }

            default:return state
    }
}
export default handleChat